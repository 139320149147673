import { CSSProperties, ComponentType, useMemo } from 'react';
import styled from 'styled-components';
import { Table } from '@src-v2/components/table/table';
import { TrimmedCollectionCell } from '@src-v2/components/table/table-common-cells/trimmed-collection-cell';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { TrimmedCollectionDisplay } from '@src-v2/components/trimmed-collection-display';
import { EllipsisText } from '@src-v2/components/typography';
import { ImageVersionTooltipContent } from '@src-v2/containers/risks/image-version-tooltip-content';
import {
  ArtifactDependency,
  ArtifactImageIdentification,
  RelatedFinding,
} from '@src-v2/types/artifacts/artifacts-types';
import { Finding } from '@src-v2/types/inventory-elements/lightweight-finding-response';
import { RiskTriggerSummaryResponse } from '@src-v2/types/risks/risk-trigger-summary-response';
import { StubAny } from '@src-v2/types/stub-any';
import { Cell } from '@src-v2/types/table';

type CellType = RelatedFinding | RiskTriggerSummaryResponse | ArtifactDependency;

type SupportedDataType = {
  imageIdentifications?: ArtifactImageIdentification[];
  artifactImageIdentifications?: ArtifactImageIdentification[];
};
type PresentationCategory = 'repoTags' | 'repoDigests' | 'fullImageIdSignature' | '';

interface CellProps<T> {
  data: T;
  className?: string;
  style?: CSSProperties;
  as?: string | ComponentType<StubAny>;
}

interface ConsolidatedIdentifications {
  repoTags: string[];
  repoDigests: string[];
  fullImageIdSignature: string[];
}

const formatRepoDigests = (value: string): string => {
  const sha256Match = value.match(/^(.*?@sha256:[a-fA-F0-9]{12})/);
  return sha256Match ? sha256Match[1] : '';
};
const formatSha256 = (value: string): string => {
  const sha = value.split('sha256:')?.[1]?.slice(0, 12);
  return sha ? `@sha256:${sha}` : '';
};

const getVersionFromTag = (repoTagsValue: string | string[]): string => {
  const repoTag = Array.isArray(repoTagsValue) ? repoTagsValue[0] : repoTagsValue;
  return repoTag?.split(':').pop()?.trim() || repoTag || '';
};

const consolidateIdentifications = (
  identifications: ArtifactImageIdentification[] = []
): ConsolidatedIdentifications => {
  return identifications.reduce(
    (acc: ConsolidatedIdentifications, item) => ({
      repoTags: [...acc.repoTags, ...(item.repoTags || [])],
      repoDigests: [...acc.repoDigests, ...(item.repoDigests || [])],
      fullImageIdSignature: [
        ...acc.fullImageIdSignature,
        ...(item.fullImageIdSignature ? [item.fullImageIdSignature] : []),
      ],
    }),
    { repoTags: [], repoDigests: [], fullImageIdSignature: [] }
  );
};

const getPresentationCategory = (
  identifications: ConsolidatedIdentifications
): 'repoTags' | 'repoDigests' | 'fullImageIdSignature' | '' => {
  if (identifications.repoTags.length > 0) {
    return 'repoTags';
  }
  if (identifications.repoDigests.length > 0) {
    return 'repoDigests';
  }
  if (identifications.fullImageIdSignature.length > 0) {
    return 'fullImageIdSignature';
  }
  return '';
};

const formatVersion = (
  identification: ArtifactImageIdentification,
  category: ReturnType<typeof getPresentationCategory>
): string => {
  switch (category) {
    case 'repoTags':
      return getVersionFromTag(identification.repoTags || []);
    case 'repoDigests':
      return identification.repoDigests?.[0]
        ? formatRepoDigests(identification.repoDigests[0])
        : '';
    case 'fullImageIdSignature':
      return identification.fullImageIdSignature
        ? formatSha256(identification.fullImageIdSignature)
        : '';
    default:
      return '';
  }
};

const getItemValueByCategory = (
  item: ArtifactImageIdentification,
  category: PresentationCategory
): string => {
  switch (category) {
    case 'repoTags':
      return item.repoTags?.[0] || '';
    case 'repoDigests':
      return item.repoDigests?.[0] || '';
    case 'fullImageIdSignature':
      return item.fullImageIdSignature || '';
    default:
      return '';
  }
};

const getImageVersionsListByCategory = (
  artifactImageIdentifications: ArtifactImageIdentification[],
  category: PresentationCategory
): ArtifactImageIdentification[] => {
  switch (category) {
    case 'repoTags':
      return artifactImageIdentifications.filter(
        (artifactImageIdentification: ArtifactImageIdentification) =>
          Boolean(artifactImageIdentification.repoTags.length > 0)
      );
    case 'repoDigests':
      return artifactImageIdentifications.filter(
        (artifactImageIdentification: ArtifactImageIdentification) =>
          Boolean(artifactImageIdentification.repoDigests.length > 0)
      );
    case 'fullImageIdSignature':
      return artifactImageIdentifications.filter(
        (artifactImageIdentification: ArtifactImageIdentification) =>
          Boolean(artifactImageIdentification.fullImageIdSignature)
      );
    default:
      return [];
  }
};

const hasArtifactImages = (
  data: SupportedDataType
): data is (Partial<Finding> | SupportedDataType) & {
  artifactImageIdentifications: ArtifactImageIdentification[];
} => {
  return Boolean(data.artifactImageIdentifications?.length);
};

interface ImageVersionItemProps {
  value: ArtifactImageIdentification;
  presentationCategory: ReturnType<typeof getPresentationCategory>;
}

const ImageVersionItem = ({
  value: identification,
  presentationCategory,
}: ImageVersionItemProps) => {
  return (
    <Tooltip content={<ImageVersionTooltipContent identification={identification} />}>
      <ImageVersionItemText>
        {formatVersion(identification, presentationCategory)}
      </ImageVersionItemText>
    </Tooltip>
  );
};

const ImageVersionItemText = styled(EllipsisText)`
  width: fit-content;
`;

export const ImageVersionComponent = ({
  data,
  limit = 4,
}: {
  data: SupportedDataType;
  limit?: number;
}) => {
  const identifications = consolidateIdentifications(data.artifactImageIdentifications);
  const presentationCategory = getPresentationCategory(identifications);

  const { mainGroup, remainingGroup } = useMemo(() => {
    if (!hasArtifactImages(data)) {
      return {
        mainGroup: [] as ArtifactImageIdentification[],
        remainingGroup: [] as ArtifactImageIdentification[],
      };
    }

    const preparedImageVersions = getImageVersionsListByCategory(
      data.artifactImageIdentifications,
      presentationCategory
    );

    return {
      mainGroup: preparedImageVersions.slice(0, limit),
      remainingGroup: preparedImageVersions.slice(limit),
    };
  }, [data, limit]);

  return (
    <ImageVersionComponentContainer>
      {mainGroup.slice(0, limit).map((artifactImageIdentification, index) => (
        <ImageVersionItemWrapper key={`${artifactImageIdentification}-${index}`}>
          <ImageVersionItem
            value={artifactImageIdentification}
            presentationCategory={presentationCategory}
          />
        </ImageVersionItemWrapper>
      ))}

      <ImageVersionComponentTrimmedContainer>
        <TrimmedCollectionDisplay<ArtifactImageIdentification>
          searchMethod={({ item, searchTerm }) => {
            const value = getItemValueByCategory(item, presentationCategory);
            return value.toLowerCase().includes(searchTerm.toLowerCase());
          }}
          limit={1}
          item={({ value }) => (
            <ImageVersionItem value={value} presentationCategory={presentationCategory} />
          )}>
          {remainingGroup.map((identification: ArtifactImageIdentification) => identification)}
        </TrimmedCollectionDisplay>
      </ImageVersionComponentTrimmedContainer>
    </ImageVersionComponentContainer>
  );
};

const ImageVersionComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageVersionItemWrapper = styled.div``;

const ImageVersionComponentTrimmedContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const ImageVersionCellInner = <T extends CellType>(props: CellProps<T>) => {
  const cellData = props.data as unknown as SupportedDataType;
  const isRelatedFinding = 'imageIdentifications' in cellData;

  const identifications = (
    isRelatedFinding ? cellData.imageIdentifications : cellData.artifactImageIdentifications
  ) as ArtifactImageIdentification[];

  if (!identifications?.length) {
    return <Table.Cell {...props} />;
  }

  const consolidatedIds = consolidateIdentifications(identifications);
  const presentationCategory = getPresentationCategory(consolidatedIds);

  return (
    <TrimmedCollectionCell<ArtifactImageIdentification>
      {...props}
      searchMethod={({ item, searchTerm }) => {
        const value = getItemValueByCategory(item, presentationCategory);
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }}
      item={({ value }) => (
        <ImageVersionItem value={value} presentationCategory={presentationCategory} />
      )}>
      {identifications.map((identification: ArtifactImageIdentification) => identification)}
    </TrimmedCollectionCell>
  );
};

export const ImageVersionCell = ImageVersionCellInner as {
  (props: CellProps<RelatedFinding>): ReturnType<Cell<RelatedFinding>>;
  (props: CellProps<RiskTriggerSummaryResponse>): ReturnType<Cell<RiskTriggerSummaryResponse>>;
  (props: CellProps<ArtifactDependency>): ReturnType<Cell<ArtifactDependency>>;
};
