import _ from 'lodash';
import { OrgTeamFormValues } from '@src-v2/containers/organization-teams/org-team-creation-form';
import {
  AssetConfigurationCategory,
  AssetsConfigurationSection,
} from '@src-v2/containers/profiles/assets-selection/assets-configuration-section';
import { useInject, useSuspense } from '@src-v2/hooks';

const assetsCategories: AssetConfigurationCategory[] = [
  'applications',
  'projects',
  'repositories',
  'repositoryGroups',
  'applicationTags',
  'repositoryTags',
  'findingTags',
];

export function TeamAssetsMappingSection({ disabled }: { disabled: boolean }) {
  const { orgTeamProfiles } = useInject();
  const readonlySections = useSuspense(orgTeamProfiles.getReadonlySections);

  return (
    <AssetsConfigurationSection<OrgTeamFormValues>
      title="Assets mapping"
      subtitle="Collect asset into a team, by selecting them manually or automatically by tags"
      supportedCategories={assetsCategories}
      readOnlySections={readonlySections}
      disabled={disabled}
    />
  );
}
