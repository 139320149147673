import { getSymbolRenderingSettings } from '@src-v2/containers/sw-architecture-graph/symbol-rendering-settings';
import { GraphEdge } from '@src-v2/containers/sw-architecture-graph/types/graph-edge-types';
import { ChartLinkVisual } from '@src/cluster-map-work/components/charts/chart-visuals';
import { strokeArrow } from '@src/cluster-map-work/components/charts/utils/graphics';

const ARROW_LABEL_SCALE_THRESHOLD = 3;

export class GraphLinkVisuals extends ChartLinkVisual {
  private readonly _role: string;
  private readonly _strokeColor: string;

  constructor(edge: GraphEdge) {
    super();

    this._role = edge.role;
    const settings = getSymbolRenderingSettings(edge.sourceSymbol);
    this._strokeColor = settings.outlineColor || settings.backgroundColor;
  }

  public paintLink(
    ctx: CanvasRenderingContext2D,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    scale: number,
    hilight: 'hilight' | 'normal' | 'dim'
  ): void {
    if (!isFinite(x1 + y1 + x2 + y2)) {
      return;
    }

    ctx.lineWidth = 0.6;
    ctx.globalAlpha = 1;
    ctx.strokeStyle = 'gray';
    ctx.fillStyle = 'gray';

    if (hilight === 'dim') {
      ctx.globalAlpha = 0.1;
      strokeArrow(ctx, x1, y1, x2, y2, 'gray');
      ctx.globalAlpha = 1;
    } else {
      strokeArrow(ctx, x1, y1, x2, y2, this._strokeColor);

      if (scale > ARROW_LABEL_SCALE_THRESHOLD && this._role) {
        ctx.save();

        ctx.font = '3px Mulish';
        ctx.translate((x1 + x2) / 2, (y1 + y2) / 2);
        const angle = Math.atan((y2 - y1) / (x2 - x1));
        ctx.rotate(angle);

        const textSize = ctx.measureText(this._role);

        ctx.fillStyle = 'white';
        ctx.fillRect(
          (-textSize.width - 2) / 2,
          -ctx.lineWidth / 2,
          textSize.width + 2,
          ctx.lineWidth
        );

        ctx.fillStyle = this._strokeColor;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(this._role, 0, 0, 10);
        ctx.restore();
      }
    }
  }
}
