import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RemoteGroupedFilterSelect } from '@src-v2/components/filters/inline-control/components/remote-grouped-filter-select';
import { transformProfileEndpointToProfileType } from '@src-v2/data/transformers';
import { useInject } from '@src-v2/hooks';
import { StubAny } from '@src-v2/types/stub-any';

export function CodeModuleFilterSelect(props: StubAny) {
  const routeMatch = useRouteMatch<{
    profileType: 'applications' | 'repositories';
    profileKey: string;
  }>('/profiles/:profileType/:profileKey');
  const { risks } = useInject();

  const searchParams = useMemo(
    () =>
      routeMatch?.params.profileKey
        ? {
            profileType: transformProfileEndpointToProfileType(routeMatch.params.profileType),
            profileKey: routeMatch?.params.profileKey,
          }
        : undefined,
    [routeMatch?.params]
  );

  return (
    <RemoteGroupedFilterSelect
      {...props}
      initSelectedOptions={risks.initModulesFilterOptions}
      searchMethod={risks.searchModuleFilter}
      searchParams={searchParams}
    />
  );
}
