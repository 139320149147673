import { ReactNode } from 'react';
import { ChartVisuals } from '@src/cluster-map-work/components/charts/chart-visuals';

export class MultiplexGraphNodeVisuals<T> extends ChartVisuals {
  private readonly _visualsLayers: { [k in keyof T]: ChartVisuals };

  constructor(
    visualsLayers: { [k in keyof T]: ChartVisuals },
    private _layerSelector: (scale: number) => keyof T,
    private _popoverLayer: keyof T = Object.keys(visualsLayers)[0] as keyof T
  ) {
    super();

    this._visualsLayers = visualsLayers;
  }

  public nodeSize(ctx: CanvasRenderingContext2D, scale: number): { w: number; h: number } {
    return this.getVisualsLayer(scale).nodeSize(ctx, scale);
  }

  public paintNode(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    scale: number,
    hilight: 'hilight' | 'normal' | 'dim',
    selected: boolean
  ): void {
    this.getVisualsLayer(scale).paintNode(ctx, x, y, scale, hilight, selected);
  }

  public paintNodeInteractionArea(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    scale: number,
    color: string
  ): void {
    this.getVisualsLayer(scale).paintNodeInteractionArea(ctx, x, y, scale, color);
  }

  public getIntersectionWithEdge(
    ctx: CanvasRenderingContext2D,
    nodeX: number,
    nodeY: number,
    edgeX1: number,
    edgeY1: number,
    edgeX2: number,
    edgeY2: number,
    scale: number
  ): [number, number] {
    return this.getVisualsLayer(scale).getIntersectionWithEdge(
      ctx,
      nodeX,
      nodeY,
      edgeX1,
      edgeY1,
      edgeX2,
      edgeY2,
      scale
    );
  }

  public getDescriptionPopover(): ReactNode {
    return this._visualsLayers[this._popoverLayer].getDescriptionPopover();
  }

  private getVisualsLayer(scale: number): ChartVisuals {
    return this._visualsLayers[this._layerSelector(scale)];
  }
}
