import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { ProductFruits } from 'react-product-fruits';
import { Router, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConnectionStatus, PageSpinner } from '@src-v2/components/layout';
import { ConnectionLostLayout } from '@src-v2/components/layout/general-error-layouts/connection-lost-layout';
import { UnauthorizationLayout } from '@src-v2/components/layout/general-error-layouts/unauthorization-layout';
import { Toastify } from '@src-v2/components/toastify';
import { useInject } from '@src-v2/hooks';
import { useInitializeBreadcrumbs } from '@src-v2/hooks/use-breadcrumbs';
import { LegacyProvider } from '@src-v2/legacy';
import routes from '@src-v2/routes';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { RouteFactory } from './route-factory';

export default observer(() => {
  // DON'T REMOVE notifications - No one uses notification, but we need it to be initiated because it loads our Beamer config,
  // and the constructor is being called only there's usage of notification in useInject (because of our DI mechanism)
  // eslint-disable-next-line no-unused-vars
  const { notifications, application, analytics, history, session } = useInject();

  useEffect(() => {
    if (application.initialized) {
      analytics.track('Page View', { Path: history.location.pathname });

      return history.listen((location, action) => {
        application.setBackStack({ location, action });

        if (action !== 'REPLACE') {
          analytics.track('Page View', { Path: location.pathname });
        }
      });
    }
  }, [application.initialized, application.setBackStack, history]);

  useInitializeBreadcrumbs();

  const userInfo = {
    username: `${session.data?.environmentName}-${session.username}`, // REQUIRED - any unique user identifier
    props: {
      environmentId: session?.data?.environmentId,
    },
  };

  if (!application.initialized) {
    return <PageSpinner />;
  }

  if (application.isUpdating) {
    return <ConnectionStatus title="Updating version" contactUs="Having issues? Contact us:" />;
  }

  if (application.lostConnection) {
    return <ConnectionLostLayout />;
  }

  if (session.unauthorizedEmptyScope) {
    return <UnauthorizationLayout />;
  }

  return (
    <>
      {application.isFeatureEnabled(FeatureFlag.ProductFruit) && (
        <ProductFruits workspaceCode="xzqYytPTF1DtB9yz" language="en" user={userInfo} />
      )}
      <Helmet defaultTitle="Apiiro" titleTemplate="%s - Apiiro" />
      <Router history={history}>
        <DndProvider backend={HTML5Backend}>
          <LegacyProvider>
            <Switch>
              {routes.map(route => (
                <RouteFactory key={route.path ?? '*'} {...route} />
              ))}
            </Switch>
          </LegacyProvider>
        </DndProvider>
        <Toastify
          position={toast.POSITION.TOP_RIGHT}
          closeOnClick={false}
          hideProgressBar={false}
          draggable={false}
        />
      </Router>
    </>
  );
});
