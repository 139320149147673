import { observer } from 'mobx-react';
import styled from 'styled-components';
import { FiltersControls } from '@src-v2/components/filters/inline-control/containers/filters-controls';
import { Gutters, StickyHeader } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { InventoryObjectType } from '@src-v2/containers/inventory-overview/inventory-object';
import { InventoryOverviewTiles } from '@src-v2/containers/inventory-overview/inventory-overview-tiles';
import { InventoryTrendCards } from '@src-v2/containers/inventory-overview/inventory-trend-cards';
import { TrendCardProps } from '@src-v2/containers/inventory-overview/trend-card';
import { useInject, useSuspense } from '@src-v2/hooks';

export default observer(() => {
  const { overview } = useInject();
  const filterOptions = useSuspense(overview.getFilterOptions);
  const title = 'Inventory Overview';

  const mockItems = [
    { name: InventoryObjectType.RiskyTicket },
    { name: InventoryObjectType.Repository },
    { name: InventoryObjectType.User },
    { name: InventoryObjectType.Api },
    { name: InventoryObjectType.SensitiveData },
    { name: InventoryObjectType.Package },
    { name: InventoryObjectType.Technology },
    { name: InventoryObjectType.Infrastructure },
    { name: InventoryObjectType.Pipeline },
    { name: InventoryObjectType.ContainerImage },
    { name: InventoryObjectType.Cluster },
  ];

  const mockData: Array<Partial<TrendCardProps>> = mockItems.map(item => ({
    ...item,
    startValue: Math.floor(Math.random() * 5001),
    endValue: Math.floor(Math.random() * 5001),
  }));

  return (
    <Page title={title}>
      <StickyHeader />
      <BodyContainer>
        <FiltersControls filterOptions={filterOptions} />
        <InventoryTrendCards cardsData={mockData} />
        <InventoryOverviewTiles />
      </BodyContainer>
    </Page>
  );
});

const BodyContainer = styled(Gutters)`
  padding-top: 9rem;
  padding-bottom: 9rem;
`;
