import _ from 'lodash';
import { ReactNode } from 'react';
import { LinkObject } from 'react-force-graph-2d';
import styled from 'styled-components';
import { Heading4 } from '@src-v2/components/typography';
import { riskLevelToRiskOrder } from '@src-v2/data/risk-data';
import { ClusterMapOverlayRisksSummary } from '@src-v2/types/clusters/cluster-map-overlay-risks-summary';
import { ClusterResource } from '@src-v2/types/clusters/cluster-resource';
import { RiskLevel } from '@src-v2/types/enums/risk-level';
import {
  getChartNodePresentationConfig,
  getLinkPresentationConfig,
  getRiskLevelBadge,
} from '@src/cluster-map-work/components/charts/chart-view-presentation-config';
import {
  ChartLinkVisual,
  ChartVisuals,
} from '@src/cluster-map-work/components/charts/chart-visuals';
import { clusterChartViewPresentationConfig } from '@src/cluster-map-work/components/charts/cluster-chart/cluster-object-visuals';
import { SvgGraphNode } from '@src/cluster-map-work/components/charts/svg-graph-node';
import { intersectLineWithRect } from '@src/cluster-map-work/components/charts/utils/geometry';

type ClusterNodePresentationConfig = {
  iconUrl: string;
  friendlyName: string;
  riskBadgeOffset: number;
  svgNodesByRisk: Record<string, SvgGraphNode>;
  noRiskSvgNode: SvgGraphNode;
};

type ClusterLinkPresentationConfig = {
  color: string;
  friendlyName: string;
};

type ClusterResourceNode = {
  id: string;
  name: string;
  resource: ClusterResource;
  nodeType: ClusterResource['resourceType'];
};

type ClusterResourceLink = LinkObject<
  ClusterResource,
  {
    linkType: 'ClusterNetworkRoute' | 'DataConsumptionLink';
  }
>;

const CLUSTER_ICON_SIZE = 10;

export class ClusterChartNodeVisuals extends ChartVisuals {
  private readonly _nodeData: ClusterResourceNode;
  private readonly _nodeRiskLevel: string;
  private readonly _presConfig: ClusterNodePresentationConfig;

  constructor(nodeData: ClusterResourceNode, nodeRiskSummary: ClusterMapOverlayRisksSummary) {
    super();

    this._nodeData = nodeData;
    this._presConfig = getChartNodePresentationConfig(
      clusterChartViewPresentationConfig,
      this._nodeData.nodeType
    );

    if (nodeRiskSummary) {
      const histogram = Object.keys(
        nodeRiskSummary[JSON.stringify(this._nodeData.resource.name)]?.risksHistogramByLevel ?? {}
      );
      this._nodeRiskLevel = getHighestRiskLevel(histogram as RiskLevel[]);
    }
  }

  public nodeSize(_ctx: CanvasRenderingContext2D, _scale: number): { w: number; h: number } {
    return {
      w: CLUSTER_ICON_SIZE,
      h: CLUSTER_ICON_SIZE,
    };
  }

  public paintNode(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    scale: number,
    hilight: 'hilight' | 'normal' | 'dim',
    selected: boolean
  ): void {
    const nodeSvg = this._nodeRiskLevel
      ? this._presConfig.svgNodesByRisk[this._nodeRiskLevel]
      : this._presConfig.noRiskSvgNode;

    nodeSvg.draw(ctx, x, y, scale, selected, hilight === 'hilight', hilight === 'dim');

    if (this._nodeRiskLevel) {
      getRiskLevelBadge(this._nodeRiskLevel)?.draw(
        ctx,
        x - this._presConfig.riskBadgeOffset,
        y - this._presConfig.riskBadgeOffset,
        scale
      );
    }
  }

  public paintNodeInteractionArea(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    scale: number,
    color: string
  ): void {
    const size = this.nodeSize(ctx, scale);
    ctx.fillStyle = color;
    ctx.fillRect(x - size.w / 2, y - size.h / 2, size.w, size.h);
  }

  public getIntersectionWithEdge(
    ctx: CanvasRenderingContext2D,
    nodeX: number,
    nodeY: number,
    edgeX1: number,
    edgeY1: number,
    edgeX2: number,
    edgeY2: number,
    scale: number
  ): [number, number] {
    const { w, h } = this.nodeSize(ctx, scale);

    return intersectLineWithRect(
      edgeX1,
      edgeY1,
      edgeX2,
      edgeY2,
      nodeX - w / 2,
      nodeY - h / 2,
      nodeX + w / 2,
      nodeY + h / 2
    );
  }

  public getDescriptionPopover(): ReactNode {
    return (
      <ChartLabel>
        <Heading4>{_.escape(this._presConfig.friendlyName)}</Heading4>
        <>{_.escape(this._nodeData.name?.toString())}</>
      </ChartLabel>
    );
  }
}

export class ClusterChartLinkVisuals extends ChartLinkVisual {
  private _presConfig: ClusterLinkPresentationConfig;

  constructor(link: ClusterResourceLink) {
    super();

    this._presConfig = getLinkPresentationConfig(clusterChartViewPresentationConfig, link.linkType);
  }

  public paintLink(
    ctx: CanvasRenderingContext2D,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    _scale: number,
    hilight: 'hilight' | 'normal' | 'dim'
  ): void {
    ctx.strokeStyle = this._presConfig.color;
    ctx.lineWidth = 0.75;
    ctx.globalAlpha = hilight === 'dim' ? 0.2 : 1;
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
    ctx.globalAlpha = 1;
  }
}

function getHighestRiskLevel(riskLevels: RiskLevel[]) {
  return _.orderBy(riskLevels, level => riskLevelToRiskOrder[level] ?? 0, 'desc')[0]?.toString();
}

const ChartLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
`;
