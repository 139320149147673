import _ from 'lodash';
import {
  getApisMessageFormat,
  getApisTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Apis';
import {
  getDataModelMessageFormat,
  getDataModelTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/DataModel';
import {
  getDependencyMessageFormat,
  getDependencyTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Dependency';
import {
  getElementMissingMessageFormat,
  getElementMissingTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/ElementMissing';
import {
  getExternalGeneralFindingMessageFormat,
  getExternalGeneralFindingTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/ExternalGeneralFinding';
import {
  getCodeFindingMessageFormat,
  getFindingsTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Findings';
import {
  getFrameworkMessageFormat,
  getFrameworkTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Framework';
import {
  getGqlObjectMessageFormat,
  getGqlObjectTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/GqlObject';
import {
  getGqlOperationMessageFormat,
  getGqlOperationTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/GqlOperation';
import {
  getLicenseWithDependenciesMessageFormat,
  getLicenseWithDependenciesTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/LicenseWithDependencies';
import {
  getMissingSASTScanMessageFormat,
  getMissingSASTScanTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/MissingSASTScan';
import {
  getIssuesMessageFormat,
  getOpenIssuesTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/OpenIssues';
import {
  getProtobufMessageMessageFormat,
  getProtobufMessageTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/ProtobufMessage';
import {
  getProtobufServiceMessageFormat,
  getProtobufServiceTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/ProtobufService';
import {
  getRbacRoleMessageFormat,
  getRbacRoleTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/RbacRole';
import {
  getSecretsMessageFormat,
  getSecretsTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Secrets';
import {
  getSensitiveDataMessageFormat,
  getSensitiveDataTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/SensitiveData';
import {
  getTerraformResourceMisconfigurationFormat,
  getTerraformResourceTriggerDisplay,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/TerraformResourceMisconfiguration';
import { getUserFacingTriggerDisplay } from '@src/blocks/RiskPosture/blocks/TriggersDisplay/UserFacing';
import { openApiPaneWithRiskActions } from '@src/components/Panes/ApiPane';
import { openDataModelPaneWithRiskActions } from '@src/components/Panes/DataModelPane';
import { openDependencyPaneWithRiskActions } from '@src/components/Panes/DependencyPane';
import { openFindingPaneWithRiskActions } from '@src/components/Panes/FindingPane';
import { openFrameworkPane } from '@src/components/Panes/FrameworkPane';
import { openGqlObjectPaneWithRiskActions } from '@src/components/Panes/GqlObjectPane';
import { openGqlOperationPaneWithRiskActions } from '@src/components/Panes/GqlOperationPane';
import { openIssuePaneWithRiskActions } from '@src/components/Panes/IssuePane';
import { openRiskyLicenseDependenciesPane } from '@src/components/Panes/RiskyLicensePane';
import { openSecretPaneWithRiskActions } from '@src/components/Panes/SecretPane';
import { openSensitiveDataPaneWithRiskActions } from '@src/components/Panes/SensitiveDataPane';
import { openTerraformModuleHighlightsWithRiskActions } from '@src/components/Panes/TerraformModuleHighlightsPane';
import {
  getServerlessFunctionFormat,
  getServerlessFunctionTriggerDisplay,
} from '../TriggersDisplay/ServerlessFunction';
import { getTriggerType } from './TriggerDataUtils';

export const TRIGGER_TYPE_TO_TRIGGER_DISPLAY = {
  Api: getApisTriggerDisplay,
  Issue: getOpenIssuesTriggerDisplay,
  UserStory: getOpenIssuesTriggerDisplay,
  DataModel: getDataModelTriggerDisplay,
  Secret: getSecretsTriggerDisplay,
  SensitiveData: getSensitiveDataTriggerDisplay,
  Dependency: getDependencyTriggerDisplay,
  UserFacing: getUserFacingTriggerDisplay,
  Framework: getFrameworkTriggerDisplay,
  LicenseWithDependencies: getLicenseWithDependenciesTriggerDisplay,
  MissingSASTScan: getMissingSASTScanTriggerDisplay,
  RbacRole: getRbacRoleTriggerDisplay,
  ElementMissing: getElementMissingTriggerDisplay,
  TerraformResource: getTerraformResourceTriggerDisplay,
  GqlObject: getGqlObjectTriggerDisplay,
  GqlOperation: getGqlOperationTriggerDisplay,
  ServerlessFunction: getServerlessFunctionTriggerDisplay,
  CodeFinding: getFindingsTriggerDisplay,
  ProtobufMessage: getProtobufMessageTriggerDisplay,
  ProtobufService: getProtobufServiceTriggerDisplay,
  ExternalGeneralFinding: getExternalGeneralFindingTriggerDisplay,
};

export const TRIGGER_TYPE_TO_MESSAGE_FORMAT = {
  Api: getApisMessageFormat,
  Issue: getIssuesMessageFormat,
  UserStory: getIssuesMessageFormat,
  DataModel: getDataModelMessageFormat,
  Secret: getSecretsMessageFormat,
  SensitiveData: getSensitiveDataMessageFormat,
  Dependency: getDependencyMessageFormat,
  Framework: getFrameworkMessageFormat,
  LicenseWithDependencies: getLicenseWithDependenciesMessageFormat,
  MissingSASTScan: getMissingSASTScanMessageFormat,
  RbacRole: getRbacRoleMessageFormat,
  ElementMissing: getElementMissingMessageFormat,
  TerraformResource: getTerraformResourceMisconfigurationFormat,
  GqlObject: getGqlObjectMessageFormat,
  GqlOperation: getGqlOperationMessageFormat,
  ServerlessFunction: getServerlessFunctionFormat,
  ProtobufMessage: getProtobufMessageMessageFormat,
  ProtobufService: getProtobufServiceMessageFormat,
  CodeFinding: getCodeFindingMessageFormat,
  ExternalGeneralFinding: getExternalGeneralFindingMessageFormat,
};

export const TRIGGER_TYPE_TO_PANE = {
  Api: openApiPaneWithRiskActions,
  Issue: openIssuePaneWithRiskActions,
  UserStory: openIssuePaneWithRiskActions,
  DataModel: openDataModelPaneWithRiskActions,
  SensitiveData: openSensitiveDataPaneWithRiskActions,
  Dependency: openDependencyPaneWithRiskActions,
  TerraformResource: openTerraformModuleHighlightsWithRiskActions,
  GqlObject: openGqlObjectPaneWithRiskActions,
  GqlOperation: openGqlOperationPaneWithRiskActions,
  Secret: openSecretPaneWithRiskActions,
  LicenseWithDependencies: openRiskyLicenseDependenciesPane,
  Framework: openFrameworkPane,
  CodeFinding: openFindingPaneWithRiskActions,
};

export const getTriggerDisplay = trigger =>
  TRIGGER_TYPE_TO_TRIGGER_DISPLAY[getTriggerType(trigger)] ?? _.noop;
export const getTriggerPane = trigger => TRIGGER_TYPE_TO_PANE[getTriggerType(trigger)] ?? _.noop;
export const getTriggerMessage = trigger =>
  TRIGGER_TYPE_TO_MESSAGE_FORMAT[getTriggerType(trigger)] ?? _.noop;
export const isSupportedTrigger = trigger =>
  Object.keys(TRIGGER_TYPE_TO_TRIGGER_DISPLAY).includes(getTriggerType(trigger));

export function openRiskTriggerPane({ trigger, ...params }) {
  let messageContent;
  try {
    messageContent = trigger.riskName ?? getTriggerMessage(trigger)(trigger);
  } catch {}
  return getTriggerPane(trigger)({ ...params, trigger, messageContent });
}

const whenTypeToUrlSuffix = whenType => {
  switch (whenType) {
    case 'Secrets':
      return 'inventory-table/data/secrets';

    case 'Licenses':
      return 'inventory-table/components/licenses';

    case 'Pii':
      return 'inventory-table/data/pii';

    case 'ExternalDependency':
      return 'inventory-table/components/dependencies';

    case 'Api':
    case 'Authorization':
    case 'InputValidation':
    case 'ApiClassification':
    case 'CheckmarxVulnerability':
      return 'inventory-table/components/apis';

    case 'DataModel':
      return 'inventory-table/data/dataModels';

    case 'Kms':
    case 'DbFrameworks':
    case 'LoggingFrameworks':
    case 'Technology':
      return 'inventory-table/components/technologies';

    case 'SecurityControls':
      return 'inventory-table/controls/securityConfigurations';

    case 'RbacRole':
      return 'inventory-table/controls/roles';

    case 'OpenIssue':
      return 'inventory-table/trackedIssues';

    case 'TerraformModuleHighlights':
      return 'inventory-table/infrastructure';

    case 'GqlObject':
      return 'inventory-table/data/graphqlObjects';

    case 'GqlOperation':
      return 'inventory-table/components/graphqlOperations';

    default:
      return null;
  }
};

const getWhenType = posture => posture.rule.whenTypes[0];

export const getUrl = (ruleTriggers, urlPrefix) => {
  const urlSuffix = whenTypeToUrlSuffix(getWhenType(ruleTriggers));
  return urlPrefix && urlSuffix && `${urlPrefix}/${urlSuffix}`;
};
