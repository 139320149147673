import { useRouteMatch } from 'react-router-dom';
import {
  RemoteGroupedFilterSelect,
  RemoteGroupedFilterSelectProps,
} from '@src-v2/components/filters/inline-control/components/remote-grouped-filter-select';
import { useInject } from '@src-v2/hooks';

export function AssetCollectionKeysGroupedFilterSelect(
  props: Omit<RemoteGroupedFilterSelectProps, 'searchMethod' | 'initSelectedOptions'>
) {
  const { params } =
    useRouteMatch<{ profileType: string; profileKey: string }>('/profiles/teams/:profileKey') ?? {};

  const { overview } = useInject();

  return (
    <RemoteGroupedFilterSelect
      {...props}
      initSelectedOptions={overview.initApplicationsFilterOptions}
      searchMethod={overview.searchApplications}
      searchParams={{
        orgTeamKey: params ? params.profileKey : undefined,
      }}
    />
  );
}
