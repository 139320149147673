import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { Button } from '@src-v2/components/button-v2';
import { SearchFilterInput } from '@src-v2/components/forms/search-input';
import { Gutters, Section } from '@src-v2/components/layout';
import { TokenFirstTimeLayout } from '@src-v2/components/layout/first-time-layouts/token-first-time-layout';
import { ResultsCounter } from '@src-v2/components/persistent-search-state/persistent-search-filters';
import { TableControls } from '@src-v2/components/table/table-addons';
import { ExternalLink, Heading, ListItem, OrderedList } from '@src-v2/components/typography';
import { AccessTokensList } from '@src-v2/containers/access-tokens/access-tokens-list';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useFilters } from '@src-v2/hooks/use-filters';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';

export const AccessTokensDisplayPanel = observer(() => {
  const { rbac, application, accessTokens } = useInject();
  const accessTokensList = useSuspense(accessTokens.listAccessTokens);
  const { activeFilters } = useFilters();

  const isNewSettingsLayout = application.isFeatureEnabled(FeatureFlag.SettingsNewLayout);

  if (!rbac.canEdit(resourceTypes.AccessTokens)) {
    return <Redirect to="/settings" />;
  }

  const filteredAccessTokensList = filterTokensList(accessTokensList, activeFilters);

  return accessTokensList.length === 0 ? (
    <TokenFirstTimeLayout />
  ) : (
    <>
      <Gutters>
        <TokensPageTableControls>
          <SearchFilterInput
            defaultValue={activeFilters?.searchTerm ?? ''}
            placeholder="Search by token name"
          />
          <TokensPageTableEndControls>
            <ResultsCounter
              count={filteredAccessTokensList?.length}
              total={accessTokensList?.length}
              itemName="tokens"
            />
            <Button to="/settings/access-permissions/tokens/create" size="large">
              Create token
            </Button>
          </TokensPageTableEndControls>
        </TokensPageTableControls>
      </Gutters>

      {!isNewSettingsLayout && (
        <Content>
          <Heading>Use the Apiiro API to:</Heading>
          <OrderedList>
            <ListItem>
              Ingest findings from 3rd party tools to remediate faster with the context provided by
              the Apiiro Risk Graph
            </ListItem>
            <ListItem>
              Connect a CI/CD pipeline and assess the risk of software releases before deployment
            </ListItem>
          </OrderedList>

          <ExternalLink href="https://docs.apiiro.com/for/api">
            Read how to use the Apiiro API
          </ExternalLink>
        </Content>
      )}

      <Content>
        {!isNewSettingsLayout && <Heading>Access Tokens</Heading>}
        <AsyncBoundary>
          <AccessTokensList />
        </AsyncBoundary>
      </Content>
    </>
  );
});

const Content = styled(Section)`
  padding-top: 0;
  padding-bottom: 8rem;

  ${Heading} {
    font-size: var(--font-size-xxl);
  }

  ${ExternalLink} {
    font-weight: 600;
  }
`;

const TokensPageTableControls = styled(TableControls)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const TokensPageTableEndControls = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
`;

export const filterTokensList = (tokens, activeFilters) =>
  activeFilters?.searchTerm
    ? tokens.filter(token =>
        token.name.toLowerCase().includes(activeFilters?.searchTerm?.toLowerCase())
      )
    : tokens;
