import { Chart, ChartProvider, Line, XAxis, YAxis } from '@src-v2/components/charts';
import { ChartLegend, LineLegendItem } from '@src-v2/components/charts/legends';
import { ChartTooltip, LineDatumIndicator } from '@src-v2/components/charts/tooltips';
import { OverviewStateBoundary } from '@src-v2/components/overview/overview-state-boundary';
import { OverviewTile } from '@src-v2/components/overview/overview-tiles';
import { useRiskScoreTrend } from '@src-v2/containers/overview/tiles/risky-score-trend-tile/use-risky-score-trend';
import { RiskyScoreTrendBadge } from './risky-score-trend-badge';

const getXAxisTickLabelProps = () => ({
  fill: 'var(--color-text-secondary)',
  fontSize: 10,
  textAnchor: 'start',
  dx: 4,
  dy: -4,
});

const getYAxisTickLabelProps = () => ({
  dx: 3,
  dy: -1,
  fontSize: 11,
  textAnchor: 'end',
});

export function RiskScoreTrendTile() {
  return (
    <OverviewTile title="Risk score over time" titleBadge={<RiskyScoreTrendBadge />}>
      <RiskyScoreTrendTileContent />
    </OverviewTile>
  );
}

const RiskyScoreTrendTileContent = () => {
  const {
    data,
    isEmpty,
    chartTheme,
    formatters: { xAxisTickFormat, yAxisTickFormat },
    accessors: { xAccessor, yAccessor },
  } = useRiskScoreTrend();

  return (
    <OverviewStateBoundary isEmpty={isEmpty}>
      <ChartProvider
        xScale={{ type: 'time', nice: true }}
        yScale={{ type: 'linear', nice: true }}
        theme={chartTheme}>
        <Chart>
          <XAxis
            numTicks={3}
            tickFormat={xAxisTickFormat}
            tickLabelProps={getXAxisTickLabelProps}
          />
          <YAxis
            numTicks={4}
            tickFormat={yAxisTickFormat}
            tickLabelProps={getYAxisTickLabelProps}
          />
          <Line dataKey="Risk score" data={data} xAccessor={xAccessor} yAccessor={yAccessor} />
          <ChartTooltip datumIndicator={LineDatumIndicator} useAbsoluteYValue />
        </Chart>
        <ChartLegend item={LineLegendItem} />
      </ChartProvider>
    </OverviewStateBoundary>
  );
};
