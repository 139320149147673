import _ from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pane } from '@src-v2/components/panes/pane';
import { Heading1 } from '@src-v2/components/typography';
import { useInject } from '@src-v2/hooks';
import { primaryPaneLevel } from '@src/blocks/Pane/model';
import { ClusterLocationsView } from '@src/cluster-map-work/components/ClusterLocationsView';
import { dispatch } from '@src/store';

export function ShowOnClusterClusterMap({
  title,
  annotatedRepositoryAndModuleReferences,
  selectedNode,
}) {
  const [displayedLocations, setDisplayedLocations] = useState([]);
  const { clusters } = useInject();

  useEffect(() => {
    const processLocations = async () => {
      try {
        await clusters.enrichWithClusterConnection(annotatedRepositoryAndModuleReferences);

        const allLocations = annotatedRepositoryAndModuleReferences.flatMap(
          annotatedRepositoryAndModuleReference =>
            annotatedRepositoryAndModuleReference.clusterMapLinksList?.flatMap(
              linkList => linkList.nodeLinks
            ) ?? []
        );

        setDisplayedLocations(_.uniqWith(allLocations, _.isEqual));
      } catch (error) {
        console.error('Failed to process cluster locations:', error);
      }
    };

    void processLocations();
  }, [annotatedRepositoryAndModuleReferences, clusters]);

  return (
    <StyledPaneBody>
      <Heading1>{title}</Heading1>
      <ClusterLocationsView clusterLocations={displayedLocations} selectedNode={selectedNode} />
    </StyledPaneBody>
  );
}

const StyledPaneBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 6rem 6rem;
`;

export function openShowOnClusterMap(
  { annotatedRepositoryAndModuleReferences, title, selectedNode = undefined },
  paneState
) {
  const showOnClusterClusterMap = (
    <ShowOnClusterClusterMap
      annotatedRepositoryAndModuleReferences={annotatedRepositoryAndModuleReferences}
      title={title}
      selectedNode={selectedNode}
    />
  );

  paneState
    ? paneState.pushPane(<Pane>{showOnClusterClusterMap}</Pane>)
    : dispatch.pane.openPane({
        onClose() {},
        innerPane: true,
        level: primaryPaneLevel,
        content: showOnClusterClusterMap,
      });
}
