import { ReactNode } from 'react';
import { getCachedSvg } from '@src-v2/containers/sw-architecture-graph/caching-utils';
import {
  SymbolRenderingSettings,
  getSymbolRenderingSettings,
} from '@src-v2/containers/sw-architecture-graph/symbol-rendering-settings';
import {
  GraphNode,
  NodesClusterGraphNode,
  PimpleData,
} from '@src-v2/containers/sw-architecture-graph/types/graph-node-types';
import { humanize } from '@src-v2/utils/string-utils';
import { getCssVariable } from '@src-v2/utils/style-utils';
import { isTypeOf } from '@src-v2/utils/ts-utils';
import { ChartVisuals } from '@src/cluster-map-work/components/charts/chart-visuals';
import {
  computeIconSize,
  intersectLineWithCircle,
} from '@src/cluster-map-work/components/charts/utils/geometry';

const MAX_LABEL_WIDTH = 50;

type CacheTextMetrics = {
  nameTextMetrics: TextMetrics;
  typeTextMetrics: TextMetrics;
  truncatedName: string;
  truncatedType: string;
};

export class GraphNodeVisuals extends ChartVisuals {
  protected readonly _settings: SymbolRenderingSettings;
  protected _nodeIcon: HTMLImageElement;
  // @ts-ignore
  private _pimplesIcons: HTMLImageElement[];

  private _cachedTextMetrics: CacheTextMetrics;

  constructor(
    protected readonly _node: GraphNode,
    protected readonly _sizeSensitivity: number = 0.5,
    protected readonly _renderLabels: boolean = true,
    renderIcon: boolean = true
  ) {
    super();

    this._settings = getSymbolRenderingSettings(_node.symbol);
    void this._loadCachedImages(renderIcon);
  }

  protected get fontSize(): number {
    return 4 + (this._node.sizeFactor ?? 0) * (12 * this._sizeSensitivity);
  }

  protected get nodeTypeDisplayName() {
    return this._node.typeDisplayName ?? humanize(this._node.symbol);
  }

  protected get iconSize() {
    return computeIconSize(this._sizeSensitivity, this._node.sizeFactor ?? 0);
  }

  protected getCachedTextMetrics(ctx: CanvasRenderingContext2D): CacheTextMetrics {
    if (!this._cachedTextMetrics) {
      const { fontSize } = this;

      ctx.font = `400 ${fontSize}px Mulish`;
      const truncatedName = truncateText(this._node.displayName, MAX_LABEL_WIDTH);
      const nameTextMetrics = ctx.measureText(truncatedName);

      ctx.font = `400 1rem Mulish`;
      const truncatedType = truncateText(
        this._node.typeDisplayName ?? humanize(this._node.symbol),
        MAX_LABEL_WIDTH
      );
      const typeTextMetrics = ctx.measureText(truncatedType);

      this._cachedTextMetrics = {
        truncatedName,
        truncatedType,
        nameTextMetrics,
        typeTextMetrics,
      };
    }

    return this._cachedTextMetrics;

    function truncateText(text: string, maxWidth: number): string {
      if (ctx.measureText(text).width < maxWidth) {
        return text;
      }

      let truncatedText = text.substring(0, maxWidth / ctx.measureText('O').width - 1);
      do {
        const result = `${truncatedText}\u2026`;
        if (ctx.measureText(result).width < maxWidth) {
          return result;
        }

        truncatedText = truncatedText.substring(0, truncatedText.length - 1);
      } while (truncatedText);

      return '\u2026';
    }
  }

  public nodeSize(ctx: CanvasRenderingContext2D, _scale: number): { w: number; h: number } {
    const { iconSize } = this;
    if (!this._renderLabels) {
      return { w: iconSize * 1.5, h: iconSize * 1.5 };
    }

    const { nameTextMetrics, typeTextMetrics } = this.getCachedTextMetrics(ctx);

    const width = Math.max(nameTextMetrics!.width, typeTextMetrics!.width, iconSize + 1);
    return { h: iconSize + (4 + this.fontSize + 2 + 4) * 2, w: width + 4 };
  }

  public paintNode(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    scale: number,
    hilight: 'hilight' | 'normal' | 'dim',
    selected: boolean
  ): void {
    if (hilight === 'dim') {
      ctx.globalAlpha = 0.1;
    }

    const { w, h } = this.nodeSize(ctx, scale);
    this.drawNodeWithSizeInCanvasContext(ctx, x - w / 2, y - h / 2, w, h, scale, selected);

    ctx.globalAlpha = 1;
  }

  public paintNodeInteractionArea(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    scale: number,
    color: string
  ): void {
    const { w, h } = this.nodeSize(ctx, scale);
    ctx.fillStyle = color;
    ctx.fillRect(x - w / 2, y - h / 2, w, h);
  }

  public getIntersectionWithEdge(
    _ctx: CanvasRenderingContext2D,
    nodeX: number,
    nodeY: number,
    edgeX1: number,
    edgeY1: number,
    edgeX2: number,
    edgeY2: number,
    _scale: number
  ): [number, number] {
    return intersectLineWithCircle(edgeX1, edgeY1, edgeX2, edgeY2, nodeX, nodeY, this.iconSize / 2);
  }

  protected drawNodeWithSizeInCanvasContext(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    _scale: number,
    selected: boolean
  ) {
    const { iconSize } = this._settings;
    const strokeColor = selected ? this._settings.hoverOutlineColor : this._settings.outlineColor;

    if (strokeColor || !this._nodeIcon) {
      ctx.beginPath();
      ctx.ellipse(x + w / 2, y + h / 2, iconSize / 2, iconSize / 2, 0, 0, Math.PI * 2);

      if (strokeColor) {
        ctx.lineWidth = 1.6;
        ctx.strokeStyle = strokeColor;
        ctx.stroke();
      }

      ctx.fillStyle =
        (selected && this._settings.hoverBackgroundColor) || this._settings.backgroundColor;
      ctx.fill();
    }

    if (this._nodeIcon) {
      const iconSizeWithOverride = computeIconSize(
        this._sizeSensitivity,
        this._node.sizeFactor,
        this._settings.iconSize
      );

      ctx.drawImage(
        this._nodeIcon,
        x + (w - iconSizeWithOverride) / 2,
        y + (h - iconSizeWithOverride) / 2,
        iconSizeWithOverride,
        iconSizeWithOverride
      );
    }

    if (
      isTypeOf<NodesClusterGraphNode>(this._node, 'originalNodes') &&
      this._node.originalNodes.length
    ) {
      const badgeIconSize = iconSize * (14 / 36);
      const badgeOffset = (iconSize / 2 / Math.sqrt(2)) * 1.2;
      const badgeCenterX = x + w / 2 + badgeOffset;
      const badgeCenterY = y + h / 2 - badgeOffset;

      ctx.beginPath();
      ctx.fillStyle = this._settings.backgroundColor;
      ctx.strokeStyle = 'white';
      ctx.lineWidth = 2;
      ctx.ellipse(
        badgeCenterX,
        badgeCenterY,
        badgeIconSize / 2,
        badgeIconSize / 2,
        0,
        0,
        Math.PI * 2
      );
      ctx.stroke();
      ctx.fill();

      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.fillStyle = 'white';
      ctx.font = `3px Sans-Serif`;
      ctx.fillText(
        String(this._node.originalNodes.length),
        badgeCenterX,
        badgeCenterY,
        badgeIconSize * 0.7
      );
    } else if (this._node.pimples?.length) {
      // TODO: render pimples
    }

    if (this._renderLabels) {
      const { truncatedName, truncatedType } = this.getCachedTextMetrics(ctx);

      ctx.font = '4px Mulish 400';
      let textY = y + (h + iconSize) / 2 + 4;
      ctx.fillStyle = getCssVariable('--color-blue-gray-70');
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(truncatedType!, x + w / 2, textY);

      textY += 8;
      ctx.font = `${this.fontSize}px Mulish 400`;
      ctx.fillStyle = '#6C7393';
      ctx.fillText(truncatedName!, x + w / 2, textY);
    }
  }

  public getDescriptionPopover(): ReactNode {
    return (
      <>
        {this.nodeTypeDisplayName}
        <br />
        {this._node.displayName}
      </>
    );
  }

  private async _loadCachedImages(renderIcon: boolean) {
    return await (renderIcon
      ? Promise.all([this._loadCachedIcon(), this._loadCachedPimples()])
      : this._loadCachedPimples());
  }

  private async _loadCachedIcon() {
    this._nodeIcon = await getCachedSvg(this._settings.iconSrc);
  }

  private async _loadCachedPimples() {
    this._pimplesIcons = await Promise.all(
      this._node.pimples.filter(isSymbolPimple).map(pimple => getCachedSvg(pimple.content.symbol))
    );
  }
}

function isSymbolPimple(pimple: any): pimple is PimpleData & { content: { symbol: string } } {
  return Boolean(pimple.content?.symbol);
}
