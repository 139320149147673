import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { CollapsibleCard } from '@src-v2/components/cards';
import { Collapsible } from '@src-v2/components/collapsible';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { SearchFilterInput } from '@src-v2/components/forms/search-input';
import { SvgIcon } from '@src-v2/components/icons';
import { ErrorLayout } from '@src-v2/components/layout';
import { DefinitionsFirstTimeLayout } from '@src-v2/components/layout/first-time-layouts/definitions-first-time-layout';
import { ContactUsLink } from '@src-v2/components/links';
import { ResultsCounter } from '@src-v2/components/persistent-search-state/persistent-search-filters';
import { SelectMenu } from '@src-v2/components/select-menu';
import { TableControls } from '@src-v2/components/table/table-addons';
import { ProcessTag } from '@src-v2/components/tags';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { Caption1, Paragraph } from '@src-v2/components/typography';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useFormatDate, useInject, useSuspense, useToggle } from '@src-v2/hooks';
import { useFilters } from '@src-v2/hooks/use-filters';
import { useScrollIntoAnchor } from '@src-v2/hooks/use-scroll-into-anchor';
import { GovernanceType } from '@src-v2/types/enums/GovernanceType';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { dataAttr, stopPropagation } from '@src-v2/utils/dom-utils';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { PageLoading } from '@src/components/PageLoading';
import RuleName from '@src/components/Rule/RuleName';
import Rule, { OptionBox, StyledTitle } from './../blocks/Rule';

const GovernanceItemsTable = ({
  fetchData,
  clearData,
  itemOptions,
  isLoading,
  items,
  createItemFunctionOrOptions,
  duplicateItem,
  setEditedItem,
  deleteItem,
  namePrefixFormatter,
  nameFormatter,
  itemType,
  itemTypePlural,
  itemConverter = item => item,
  definitionFormatting,
  setRuleByDefinition,
  ruleByDefinition,
  failedToLoad,
}) => {
  const { rbac, application } = useInject();
  const { activeFilters } = useFilters();
  const [itemToDelete, setItemToDelete] = useState(null);
  const { definitions } = useInject();
  useScrollIntoAnchor(null, [isLoading]);

  useEffect(() => clearData, [clearData]);

  useEffect(() => {
    fetchData({ invalidateCache: false });
  }, [fetchData, clearData]);

  const deleteRule = useCallback(() => {
    if (itemToDelete?.key === ruleByDefinition?.definitionKey) {
      setRuleByDefinition({});
    }
    deleteItem(itemToDelete);
    setItemToDelete(null);
    return true;
  }, [itemToDelete, deleteItem, setItemToDelete]);

  const cancel = useCallback(() => setItemToDelete(null), [setItemToDelete]);

  const filteredItems = useMemo(
    () =>
      activeFilters.searchTerm?.length > 0
        ? items.filter(item =>
            `${namePrefixFormatter?.(item)} ${item.fullDisplayName || item.name}`
              .toLowerCase()
              .includes(activeFilters.searchTerm?.toLowerCase())
          )
        : items,
    [items, activeFilters.searchTerm]
  );

  const tooltipContent = `Contact your admin to create a ${itemType}`;
  const canEditGovernance =
    rbac.canEdit(resourceTypes.Governance) ||
    (itemType === GovernanceType.Policy && rbac.canEdit(resourceTypes.GovernancePolicies)) ||
    (itemType === GovernanceType.Definition && rbac.canEdit(resourceTypes.GovernanceDefinition));

  const smartPolicyAvailable = application.isFeatureEnabled(FeatureFlag.SmartPolicies);
  const definitionsData = useSuspense(definitions.getDefinitions);

  const isEmptyState = itemType === GovernanceType.Definition && definitionsData.length === 0;

  return isEmptyState ? (
    <DefinitionsFirstTimeLayout onClick={createItemFunctionOrOptions} />
  ) : isLoading ? (
    <PageLoading />
  ) : (
    <>
      <GovernancePageTableControls>
        <SearchFilterInput
          defaultValue={activeFilters?.searchTerm ?? ''}
          placeholder={`Search by ${itemType} name`}
        />
        <GovernancePageTableEndControls>
          <ResultsCounter
            count={filteredItems?.length}
            total={items?.length}
            itemName={itemTypePlural}
          />
          <Tooltip content={tooltipContent} disabled={canEditGovernance}>
            {typeof createItemFunctionOrOptions === 'function' ? (
              <Button disabled={!canEditGovernance} onClick={createItemFunctionOrOptions}>
                Create {itemType}
              </Button>
            ) : (
              <CreateItemSelectMenu
                disabled={!canEditGovernance}
                variant={Variant.PRIMARY}
                placeholder={`Create ${itemType}`}>
                {createItemFunctionOrOptions.map((createItemOption, optionIndex) => (
                  <Dropdown.Item key={optionIndex} onClick={createItemOption.handler}>
                    {createItemOption.title}
                  </Dropdown.Item>
                ))}
              </CreateItemSelectMenu>
            )}
          </Tooltip>
        </GovernancePageTableEndControls>
      </GovernancePageTableControls>
      {filteredItems.length > 0 ? (
        <ItemsListContainer>
          {filteredItems.map(item => (
            <JumpToLink key={item.key} name={item.key}>
              <CollapsibleItem
                item={item}
                itemType={itemType}
                formattedName={nameFormatter ? nameFormatter(item) : null}
                prefix={
                  namePrefixFormatter
                    ? addInterpunctSeparator(namePrefixFormatter(item), itemType)
                    : itemType
                }
                canEditGovernance={canEditGovernance}
                canEdit={
                  canEditGovernance &&
                  (item.canCurrentUserEdit ?? true) &&
                  (!smartPolicyAvailable || !item.isSeed)
                }
                isSeed={item.isSeed}
                definitionDisplay={
                  definitionFormatting?.(item) ?? (
                    <Rule isReadOnly rule={itemConverter(item)} options={itemOptions} />
                  )
                }
                onEdit={() => setEditedItem(item)}
                onDelete={() => setItemToDelete(item)}
                onDuplicate={duplicateItem ? () => duplicateItem(item) : null}
              />
            </JumpToLink>
          ))}
        </ItemsListContainer>
      ) : (
        <NoticeContainer>
          {failedToLoad ? (
            <Paragraph>
              Cannot display {itemTypePlural} at this time, please contact us at: <ContactUsLink />
            </Paragraph>
          ) : (
            <ErrorLayout.NoResults />
          )}
        </NoticeContainer>
      )}
      {itemToDelete && (
        <ConfirmationModal
          title={`Delete ${itemToDelete.name}?`}
          submitStatus="failure"
          submitText="Delete"
          onClose={cancel}
          onSubmit={deleteRule}>
          Are you sure you want to delete this {itemType}?
        </ConfirmationModal>
      )}
    </>
  );
};

export default GovernanceItemsTable;

function CollapsibleItem({
  item,
  formattedName,
  prefix,
  canEdit,
  canEditGovernance,
  isSeed,
  itemType,
  definitionDisplay,
  onEdit,
  onDelete,
  onDuplicate,
}) {
  const [isDisabled, toggleIsDisabled] = useToggle(item.isDisabled);
  const { governance, toaster, application } = useInject();

  const handleDisable = async () => {
    try {
      toggleIsDisabled();
      await governance.setRuleDisabled({ key: item.key, isDisabled: !isDisabled });
    } catch (error) {
      toggleIsDisabled(isDisabled);
      toaster.error('Failed to change policy status');
    }
  };

  return (
    <CollapsibleContainer
      lazyLoad
      data-disabled={dataAttr(isDisabled)}
      title={
        <>
          <RuleName
            rule={item}
            type={itemType}
            prefix={prefix}
            isDisabled={isDisabled}
            formattedName={formattedName}
            onDisableToggleClick={handleDisable}
            useTags={application.isFeatureEnabled('SmartPolicies')}
            canEditGovernance={canEditGovernance}
          />
          <DropdownMenu
            className="ruleModifiersTestMarker"
            onClick={stopPropagation}
            onItemClick={stopPropagation}>
            <DropdownItemWithDisableTooltipSupport
              disabled={!canEdit}
              onClick={onEdit}
              toolTipContent={
                isSeed
                  ? 'You cannot edit Apiiro seed policies: instead, duplicate, edit, and save it with a new name.'
                  : 'Contact your admin to edit or delete a policy'
              }>
              <SvgIcon name="Edit" /> Edit
            </DropdownItemWithDisableTooltipSupport>

            <DropdownItemWithDisableTooltipSupport
              disabled={!canEditGovernance}
              toolTipContent="Contact your admin to edit or delete a policy"
              onClick={onDuplicate}>
              <SvgIcon name="Copy" /> Duplicate
            </DropdownItemWithDisableTooltipSupport>

            <DropdownItemWithDisableTooltipSupport
              disabled={!canEdit}
              onClick={onDelete}
              toolTipContent={
                isSeed
                  ? 'You cannot delete Apiiro seed policies. To stop generating risks based on a policy, disable it: you can always enable the policy later.'
                  : 'Contact your admin to edit or delete a policy'
              }>
              <SvgIcon name="Trash" /> Delete
            </DropdownItemWithDisableTooltipSupport>
          </DropdownMenu>
        </>
      }>
      <RuleContent data-test-marker={itemType}>
        <DefinitionDisplay>{definitionDisplay}</DefinitionDisplay>
        {itemType === GovernanceType.Policy && <ChangesData item={item} disabled={isDisabled} />}
      </RuleContent>
    </CollapsibleContainer>
  );
}

function DropdownItemWithDisableTooltipSupport({ disabled, onClick, toolTipContent, children }) {
  return (
    <Tooltip content={toolTipContent} disabled={!disabled}>
      <DropdownItemWithDisableTooltipSupportItemContainer>
        <Dropdown.Item disabled={disabled} onClick={onClick}>
          {children}
        </Dropdown.Item>
      </DropdownItemWithDisableTooltipSupportItemContainer>
    </Tooltip>
  );
}

const CollapsibleContainer = styled(CollapsibleCard)`
  width: 100%;

  &[data-disabled] {
    background-color: var(--color-blue-gray-10);

    ${HorizontalStack} {
      color: var(--color-blue-gray-50);
    }

    ${Collapsible.Title} {
      ${ProcessTag} {
        background-color: var(--color-green-30);
      }
    }
  }

  ${Collapsible.Title} {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    ${ProcessTag} {
      background-color: var(--color-green-35);
    }
  }
`;

const ChangesData = styled(({ item, disabled, className }) => {
  const createdAt = useFormatDate(item.createdAt, 'PP');
  const updatedAt = useFormatDate(item.updatedAt, 'PP');
  const disabledAt = useFormatDate(item.lastDisabledAt, 'PP');

  return (
    <Caption1 className={className}>
      {item.createdAt && item.createdBy && `Created by ${item.createdBy} on ${createdAt}`}
      {item.updatedAt &&
        item.updatedBy &&
        `${'\n'}Last edited by ${item.updatedBy} on ${updatedAt}`}
      {disabled &&
        item.lastDisabledAt &&
        item.lastDisabledBy &&
        `${'\n'}Disabled by ${item.lastDisabledBy} on ${disabledAt}`}
    </Caption1>
  );
})`
  white-space: pre;
  align-self: flex-end;
  text-align: end;
  line-height: 5rem;
  color: var(--color-blue-gray-60);
`;

const DropdownItemWithDisableTooltipSupportItemContainer = styled.div`
  height: 8rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: stretch;
  width: 100%;

  ${Dropdown.Item} {
    flex-grow: 1;
  }
`;

const ItemsListContainer = styled.div`
  display: flex;
  padding-bottom: 6rem;
  align-items: stretch;
  flex-direction: column;
  gap: 6rem;
`;

const RuleContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;
  justify-content: space-between;
  cursor: default;

  ${OptionBox} {
    margin-top: 0;
    padding: 0;
  }

  ${StyledTitle},
  [data-test-marker="rule-portion-value"] {
    color: ${props => (props.disabled ? 'var(--color-blue-gray-55)' : 'inherit')};
  }
`;

const DefinitionDisplay = styled.div`
  width: 100%;
`;

const NoticeContainer = styled.div`
  text-align: center;
`;

const JumpToLink = styled.a``;

const GovernancePageTableControls = styled(TableControls)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const GovernancePageTableEndControls = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
`;

const CreateItemSelectMenu = styled(SelectMenu)``;
