import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSuspense, useTypeahead } from '@src-v2/hooks';
import { useGraphSearchState } from '@src/cluster-map-work/components/graph-search-input';

export function useChartSearchState({ getSearchResults, fetchArgs } = {}) {
  const [numSearchResults, setNumSearchResults] = useState(0);
  const [searchResultSelectedIndex, setSearchResultSelectedIndex] = useState(null);
  const [searchStatus, setSearchStatus] = useState('inactive');
  const graphSearchState = useGraphSearchState();

  const runSearch = useCallback(
    async ({ searchTerm }, { cancelToken }) => {
      if (searchTerm) {
        return await getSearchResults({ fetchArgs, searchTerm, cancelToken });
      }

      return null;
    },
    [getSearchResults, fetchArgs]
  );

  const [setSearchTerm, searchResults] = useTypeahead(runSearch);

  useEffect(() => {
    if (graphSearchState.searchTerm) {
      setSearchResultSelectedIndex(null);
      setNumSearchResults(null);
      setSearchStatus('running');
    } else {
      setSearchStatus('inactive');
    }

    setSearchTerm({ searchTerm: graphSearchState.searchTerm });
  }, [graphSearchState.searchTerm, setSearchTerm, setNumSearchResults]);

  useEffect(() => {
    if (searchResults?.length || searchStatus === 'running') {
      setNumSearchResults(searchResults?.length);
      setSearchStatus('results');
    }
  }, [searchResults]);

  const searchSelectedNodeId = useMemo(
    () =>
      searchResults &&
      searchResultSelectedIndex !== null &&
      searchResultSelectedIndex < searchResults.length
        ? searchResults[searchResultSelectedIndex].node
        : null,
    [searchResultSelectedIndex, searchResults]
  );

  return {
    graphSearchState,
    numSearchResults,
    searchResultSelectedIndex,

    searchTerm: graphSearchState.searchTerm,

    setSearchResultSelectedIndex,
    searchSelectedNodeId,
    searchResults,
    searchStatus,
  };
}

export function useServerFetchedChartState({
  loadGraphData,
  chartSearchState,
  chartFilterState,
  loadNodeRiskSummary,
  fetchArgs,
  initialSelectedNode,
}) {
  const [nodeRiskSummary, setNodeRiskSummary] = useState();

  const graphData = useSuspense(loadGraphData, {
    filters: chartFilterState?.activeFilters,
    fetchArgs,
  });

  useEffect(() => {
    async function loadRiskSummary() {
      try {
        const riskSummary = await loadNodeRiskSummary({
          filters: chartFilterState?.activeFilters,
          fetchArgs,
        });
        setNodeRiskSummary(riskSummary);
      } catch (error) {
        console.error('Failed to load risk summary:', error);
      }
    }

    void loadRiskSummary();
  }, [JSON.stringify(chartFilterState?.activeFilters), fetchArgs]);

  const searchHilightList = useMemo(
    () => chartSearchState?.searchResults?.map(r => r.node),
    [chartSearchState?.searchResults]
  );

  const externalSelectedNode = useMemo(() => {
    if (chartSearchState?.searchSelectedNodeId) {
      return graphData.nodes.find(node => node.id === chartSearchState?.searchSelectedNodeId);
    }

    if (!initialSelectedNode) {
      return null;
    }

    return graphData.nodes.find(node => node.id === initialSelectedNode);
  }, [initialSelectedNode, chartSearchState?.searchSelectedNodeId]);

  return useMemo(
    () => ({
      graphData,
      nodeRiskSummary,
      externalSelectedNode,
      chartSearchState,
      highlightsList: searchHilightList,
    }),
    [graphData, nodeRiskSummary, externalSelectedNode, chartSearchState, searchHilightList]
  );
}
