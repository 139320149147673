const clearData = () => ({});

export default {
  state: clearData(),
  reducers: {
    setHasFetchedData: (state, hasFetchedData) => ({
      ...state,
      hasFetchedData,
    }),
    clearData,
  },
  selectors: (slice, createSelector) => ({
    hasFetchedData: () => slice(state => state?.hasFetchedData),
    repositoryKey: () => (rootState, props) => props.match.params.key,
    moduleKey: () => (rootState, props) => props.match.params.moduleKey,
    moduleProfile: models =>
      createSelector(
        [
          models.modulePage.repositoryKey,
          models.modulePage.moduleKey,
          models.repositoryProfiles.profilesByKey,
        ],
        (repositoryKey, moduleKey, repositoryProfilesByKey) =>
          repositoryProfilesByKey &&
          repositoryProfilesByKey[`${repositoryKey}-${decodeURIComponent(moduleKey)}`]
      ),
    moduleRiskTriggers: models =>
      createSelector(
        [models.repositoryPage.moduleKey, models.repositoryProfiles.riskTriggersByKey],
        (moduleKey, repositoryRiskTriggersByKey) =>
          repositoryRiskTriggersByKey && repositoryRiskTriggersByKey[decodeURIComponent(moduleKey)]
      ),
  }),
  effects: dispatch => ({
    async fetchData({ key, invalidateCache, moduleKey }) {
      await Promise.all([
        dispatch.repositoryProfiles.getModuleProfileAsync({ key, invalidateCache, moduleKey }),
        dispatch.governanceRules.fetchRules({ invalidateCache }),
      ]);
      this.setHasFetchedData(true);
    },
  }),
};
