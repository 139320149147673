import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { DefaultErrorFallback } from '@src-v2/components/async-boundary';
import { Page } from '@src-v2/components/layout/page';
import {
  CenteredMessage,
  ReportsFrame,
} from '@src-v2/containers/pages/reporting/components/reporting-common-components';
import { applyRules } from '@src-v2/containers/pages/reporting/css-rules/reports-utils';
import { useMetabaseLoadingState } from '@src-v2/containers/pages/reporting/hooks/use-metabse-loading-state';
import { useInject } from '@src-v2/hooks';
import { useBreadcrumbs } from '@src-v2/hooks/use-breadcrumbs';

export default function MyOrgReports() {
  const { reporting } = useInject();
  const [state, setState] = useMetabaseLoadingState(reporting.getOrganizationReportsUrl);

  useBreadcrumbs({
    breadcrumbs: [
      { label: 'Overview', to: '/reporting' },
      {
        label: 'Reports workspace',
        to: `/reporting/myOrgReports`,
      },
    ],
  });

  const handleIframeLoad = () => {
    applyRules(document.getElementById('reportsFrame') as HTMLIFrameElement);
    setState(prevState => ({ ...prevState, loading: false }));
  };

  return (
    <Page title="Reports">
      {state.pageLoading && (
        <CenteredMessage>
          <LogoSpinner />
        </CenteredMessage>
      )}
      {state.error && <CenteredMessage>{state.error}</CenteredMessage>}
      {state.iframeUrl && (
        <ReportsFrame
          id="reportsFrame"
          onError={() => setState(prev => ({ ...prev, error: <DefaultErrorFallback /> }))}
          onLoad={handleIframeLoad}
          src={state.iframeUrl}
        />
      )}
    </Page>
  );
}
