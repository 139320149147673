import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { CircleButton } from '@src-v2/components/button-v2';
import { ControlledCard } from '@src-v2/components/cards/controlled-card';
import { ClampText } from '@src-v2/components/clamp-text';
import { SvgIcon } from '@src-v2/components/icons';
import { Table } from '@src-v2/components/table/table';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Size } from '@src-v2/components/types/enums/size';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { DataTable } from '@src-v2/containers/data-table/data-table';
import { usePullRequestScanContext } from '@src-v2/containers/pr-logs/pane/pr-scan-context-provider';
import { RiskIconCell } from '@src-v2/containers/risks/risks-common-cells';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useClientDataTable } from '@src-v2/hooks/use-client-data-table';
import { RiskTriggerSummaryResponse } from '@src-v2/types/risks/risk-trigger-summary-response';
import { Column } from '@src-v2/types/table';

export const PrScanRisksCard = () => {
  return (
    <ControlledCard triggerOpenState={{ isOpen: true }} title="Risks">
      <AsyncBoundary>
        <CardContent />
      </AsyncBoundary>
    </ControlledCard>
  );
};

const CardContent = () => {
  const { pullRequestScan } = useInject();
  const {
    pullRequestScan: { key },
  } = usePullRequestScanContext();

  const pullRequestRisks = useSuspense(pullRequestScan.getPullRequestRisks, { key });

  const dataModel = useClientDataTable(pullRequestRisks, {
    key: 'pr-scan-risks',
    columns: tableColumns,
    ignorePagination: true,
    hasToggleColumns: false,
    hasReorderColumns: false,
    isPinFeatureEnabled: false,
  });

  return (
    <>
      <DataTable expandable dataModel={dataModel}>
        {item => <DataTable.Row key={item.key} data={item} />}
      </DataTable>
    </>
  );
};

const tableColumns: Column<RiskTriggerSummaryResponse>[] = [
  {
    key: 'risk-column',
    label: 'Risk',
    width: '14rem',
    resizeable: false,
    Cell: RiskIconCell,
  },
  {
    key: 'risk-description',
    label: 'Description',
    Cell: ({ data, ...props }) => (
      <Table.Cell {...props}>
        <ClampText>{data.riskName ?? data.displayName}</ClampText>
      </Table.Cell>
    ),
  },
  {
    key: 'view-code-link',
    label: <></>,
    width: '13rem',
    resizeable: false,
    Cell: ({ data, ...props }) => (
      <Table.CenterCell {...props}>
        {data.codeReference?.relativeFilePath && (
          <Tooltip content="View in code">
            <CircleButton
              variant={Variant.TERTIARY}
              size={Size.MEDIUM}
              href={generateCodeReferenceUrl(data.relatedEntity, data.codeReference)}>
              <SvgIcon name="External" />
            </CircleButton>
          </Tooltip>
        )}
      </Table.CenterCell>
    ),
  },
];
