import { useOpenRiskPane } from '@src-v2/containers/risks/use-open-risk-pane';
import { useRiskDataModelManager } from '@src-v2/containers/risks/use-risk-data-model-manager';
import { DataTableGrouping } from '@src-v2/models/data-table-grouping';

export function useGroupingRiskPaneManager({
  dataGroupingModel,
}: {
  dataGroupingModel: DataTableGrouping<any>;
}) {
  const { handleOpen, handleClose, handleModify } = useRiskDataModelManager(
    dataGroupingModel.childrenTables
  );

  useOpenRiskPane({
    onOpen: handleOpen,
    onClose: handleClose,
    onModify: handleModify,
  });
}
