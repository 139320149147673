const hideAddToDashboardRules = [
  `#root:has(header [aria-label="badge icon"]) ~ * [data-testid="add-to-dashboard-button"] { display: none !important; }`,
  `#root:has([id="Dashboard-Parameters-And-Cards-Container"]) [name="Pre-defined reports"] { display: none !important; }`,
  `[id="DataPopover"] [aria-label="Pre-defined reports"] { display: none !important; }`,
  `[id="DataPopover"]:has([aria-label="Pre-defined reports"][aria-selected="true"]) [data-testid="select-list"] { display: none !important; }`,
  `#DataPopover :has(> [data-testid="list-search-field"]) { display: none !important; }`,
  `aside[data-testid="add-card-sidebar"] input { display: none !important; }`,
];

export const applyRules = (iframe: HTMLIFrameElement | null) => {
  if (iframe) {
    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

    if (iframeDoc) {
      const stylesheets = iframeDoc.styleSheets;
      for (let i = 0; i < stylesheets.length; i++) {
        const stylesheet = stylesheets[i];
        try {
          if (stylesheet.cssRules) {
            hideAddToDashboardRules.forEach(rule => {
              try {
                stylesheet.insertRule(rule, stylesheet.cssRules.length);
              } catch (e) {
                console.warn('Could not insert rule:', rule, e);
              }
            });
          }
        } catch (e) {
          console.warn('Could not modify stylesheet on load:', e);
        }
      }
    }
  }
};
