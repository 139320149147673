import { useMemo } from 'react';
import styled from 'styled-components';
import { Badge } from '@src-v2/components/badges';
import { TextButton } from '@src-v2/components/button-v2';
import { Card } from '@src-v2/components/cards';
import { OverviewTilesGrid } from '@src-v2/components/overview/overview-tiles';
import { Heading5 } from '@src-v2/components/typography';
import { inventoryTrendCardData } from '@src-v2/containers/inventory-overview/inventory-object';
import { TrendCard, TrendCardProps } from '@src-v2/containers/inventory-overview/trend-card';

export const InventoryTrendCards = ({
  cardsData,
}: {
  cardsData: Array<Partial<TrendCardProps>>;
}) => (
  <OverviewTrendCardsGrid>
    {cardsData.map((card: Partial<TrendCardProps>) => (
      <InventoryTrendCard key={card.name} card={card} />
    ))}
  </OverviewTrendCardsGrid>
);

const InventoryTrendCard = ({ card }: { card: Partial<TrendCardProps> }) => {
  const { title, to, color, timeFilterInsensitive, connectUrl } = useMemo(
    () => inventoryTrendCardData[card.name],
    [inventoryTrendCardData, card.name]
  );

  if (!title) {
    throw new Error('missing Inventory Trend Cards data');
  }

  return (
    <TrendCard
      key={card.name}
      title={title}
      startValue={card.startValue}
      endValue={card.endValue}
      color={color}
      name={card.name}
      to={to}
      timeFilterInsensitive={timeFilterInsensitive}
      connectUrl={connectUrl}
    />
  );
};

const OverviewTrendCardsGrid = styled(OverviewTilesGrid)`
  padding: 4rem 0;

  ${Card} {
    --card-padding: 3rem;
    display: flex;

    ${Heading5} {
      margin: 0 2rem;
    }

    ${TextButton} {
      display: block;
    }

    ${Badge} {
      margin-left: auto;
    }
  }
`;
