import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Size } from '@src-v2/components/types/enums/size';
import { useOpenDataModelReferencePane } from '@src-v2/containers/entity-pane/use-open-data-model-reference-pane';
import { generateMockData } from '@src-v2/containers/sw-architecture-graph/data';
import { GraphLinkVisuals } from '@src-v2/containers/sw-architecture-graph/sw-graph-visuals/sw-graph-link-visuals';
import { GraphNodeVisuals } from '@src-v2/containers/sw-architecture-graph/sw-graph-visuals/sw-graph-node-visuals';
import { MultiplexGraphNodeVisuals } from '@src-v2/containers/sw-architecture-graph/sw-graph-visuals/sw-multiplex-graph-node-visuals';
import { getSymbolRenderingSettings } from '@src-v2/containers/sw-architecture-graph/symbol-rendering-settings';
import { GraphEdge } from '@src-v2/containers/sw-architecture-graph/types/graph-edge-types';
import {
  DataGraphNode,
  GraphNode,
} from '@src-v2/containers/sw-architecture-graph/types/graph-node-types';
import { isTypeOf } from '@src-v2/utils/ts-utils';
import { ChartView, ChartViewProps } from '@src/cluster-map-work/components/charts/chart-view';
import { computeIconSize } from '@src/cluster-map-work/components/charts/utils/geometry';

const DEFAULT_SIZE_SENSITIVITY = 0.5;
const LABEL_DISPLAY_SCALING_THRESHOLD = 2;
const ICON_DISPLAY_THRESHOLD = 20;

const SwArchitectureNodeVisualsFactory = (node: GraphNode) => {
  const { iconDisplayThreshold = ICON_DISPLAY_THRESHOLD } = getSymbolRenderingSettings(node.symbol);
  return new MultiplexGraphNodeVisuals(
    {
      [Size.SMALL]: new GraphNodeVisuals(node, DEFAULT_SIZE_SENSITIVITY, false, false),
      [Size.MEDIUM]: new GraphNodeVisuals(node, DEFAULT_SIZE_SENSITIVITY, false, true),
      [Size.LARGE]: new GraphNodeVisuals(node),
    },
    (scale: number) =>
      computeIconSize(DEFAULT_SIZE_SENSITIVITY, node.sizeFactor ?? 0) * scale < iconDisplayThreshold
        ? Size.SMALL
        : scale < LABEL_DISPLAY_SCALING_THRESHOLD
          ? Size.MEDIUM
          : Size.LARGE
  );
};

const SwArchitectureLinkVisualsFactory = (edge: GraphEdge) => new GraphLinkVisuals(edge);

export const SwArchitectureGraph = () => {
  const chartData = useMemo(generateMockData, []);

  const openPane = useOpenDataModelReferencePane();
  const handleNodeClicked: ChartViewProps<GraphNode, GraphEdge>['onNodeSelected'] = useCallback(
    (node, reason) => {
      if (reason === 'click' && node && isTypeOf<DataGraphNode>(node, 'dataModelReference')) {
        openPane(node.dataModelReference);
      }
    },
    [openPane]
  );

  return (
    <GraphContainer>
      <ChartView
        chartData={chartData}
        externalSelectedNode={null}
        nodeVisualsFactory={SwArchitectureNodeVisualsFactory}
        linkVisualsFactory={SwArchitectureLinkVisualsFactory}
        graphLayoutMode="force"
        onNodeSelected={handleNodeClicked}
      />
    </GraphContainer>
  );
};

const GraphContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  height: 200rem;
  width: 100%;
  padding: 1.5rem;
`;
