import { IObjectDidChange } from 'mobx';
import { useCallback } from 'react';
import { DataTable } from '@src-v2/models/data-table';
import { RiskTriggerSummaryResponse } from '@src-v2/types/risks/risk-trigger-summary-response';
import { modify } from '@src-v2/utils/mobx-utils';

export function useRiskDataModelManager(dataModels: DataTable<RiskTriggerSummaryResponse, any>[]) {
  const handleOpen = useCallback(
    (trigger: RiskTriggerSummaryResponse) => {
      dataModels?.forEach(model => model.setExpandedItem(trigger));
    },
    [dataModels]
  );

  const handleClose = useCallback(() => {
    dataModels?.forEach(model => {
      if (model.expanded) {
        model.setExpandedItem(null);
      }
    });
  }, [dataModels]);

  const handleModify = useCallback(
    (item: RiskTriggerSummaryResponse, change: IObjectDidChange<RiskTriggerSummaryResponse>) => {
      const model = dataModels.find(model =>
        model.searchState.items?.some(i => i.key === item.key)
      );

      if (model) {
        const updatedItem = model.searchState.items?.find(i => i.key === item.key);
        if (updatedItem) {
          modify(
            updatedItem,
            change.name,
            change.type === 'add' || change.type === 'update' ? change.newValue : null
          );
        }
      }
    },
    [dataModels]
  );

  return { handleOpen, handleClose, handleModify };
}
