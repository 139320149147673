import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import Bootstrap from '@src-v2/containers/bootstrap';
import GlobalStyle from '@src-v2/style/loader';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <>
    <GlobalStyle />
    <Bootstrap />
  </>
);

if (module.hot) {
  module.hot.accept();
}
