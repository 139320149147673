import { IObjectDidChange } from 'mobx';
import { useCallback, useEffect } from 'react';
import { usePaneState } from '@src-v2/components/panes/pane-context-provider';
import { useRiskTypeToPane } from '@src-v2/containers/entity-pane/use-risk-type-to-pane';
import { RisksContext, useRisksContext } from '@src-v2/containers/risks/risks-context';
import { useInject, useQueryParams } from '@src-v2/hooks';
import { RiskTriggerSummaryResponse } from '@src-v2/types/risks/risk-trigger-summary-response';

interface RiskPaneCallbacks {
  onOpen: (trigger: RiskTriggerSummaryResponse) => void;
  onClose: () => void;
  onModify: (
    item: RiskTriggerSummaryResponse,
    change: IObjectDidChange<RiskTriggerSummaryResponse>
  ) => void;
}

export function useOpenRiskPane({ onOpen, onClose, onModify }: RiskPaneCallbacks) {
  const { risks, toaster, asyncCache } = useInject();
  const { pushPane } = usePaneState();
  const getRiskPane = useRiskTypeToPane();
  const { queryParams, updateQueryParams } = useQueryParams();
  const { risksService } = useRisksContext();

  const riskObserver = useCallback(
    (change: IObjectDidChange<RiskTriggerSummaryResponse>) => {
      onModify(change.object, change);
    },
    [onModify]
  );

  useEffect(() => {
    if (queryParams.trigger) {
      void tryOpenPane();
    } else {
      onClose();
    }

    async function tryOpenPane() {
      try {
        const trigger = await asyncCache.suspend(risks.getTrigger, { key: queryParams.trigger });
        onOpen(trigger);

        const Pane = getRiskPane(trigger.elementType);
        pushPane(
          <RisksContext risksService={risksService}>
            <Pane
              triggerKey={trigger.key}
              riskObserver={riskObserver}
              onClose={() => updateQueryParams({ trigger: null, pane: null })}
            />
          </RisksContext>
        );
      } catch (error) {
        if (error.response?.status === 404) {
          toaster.success("Congrats! This risk doesn't exist anymore.");
          return;
        }
        throw error;
      }
    }
  }, [queryParams.trigger]);

  return riskObserver;
}
