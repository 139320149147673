import { useMemo } from 'react';
import styled from 'styled-components';
import { OverviewTile } from '@src-v2/components/overview/overview-tiles';
import {
  DetailedTrendNames,
  DetailedTrendTiles,
} from '@src-v2/containers/inventory-overview/detaild-trend-object';
import { DetailedTrendItem } from '@src-v2/containers/inventory-overview/detailed-trend-list-item';
import { useInject, useSuspense } from '@src-v2/hooks';

const useInventoryOverviewData = (category: DetailedTrendTiles) => {
  const { inventory } = useInject();
  const inventoryOverviewData = useSuspense(inventory.getInventoryOverviewMock);
  return useMemo(() => inventoryOverviewData[category], [inventoryOverviewData, category]);
};
export const BasicDetailsTrendTile = ({
  overviewTitle,
  category,
}: {
  overviewTitle: string;
  category: DetailedTrendTiles;
}) => (
  <Tile title={overviewTitle}>
    {Object.entries(useInventoryOverviewData(category)).map(([subCat, details]) => (
      <DetailedTrendItem
        key={subCat}
        category={subCat as DetailedTrendNames}
        startValue={details.startValue}
        endValue={details.endValue}
      />
    ))}
  </Tile>
);

const Tile = styled(OverviewTile)`
  justify-content: unset;
  gap: 3rem;
`;
