import { Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import emptyBox from '@src-v2/assets/images/empty-state/empty-box.svg';
import NoResultsFlashlight from '@src-v2/assets/images/empty-state/no-results-flashlight.svg';
import { Button } from '@src-v2/components/button-v2';
import { Paragraph } from '@src-v2/components/typography';

enum OverviewStateMode {
  None = 'none',
  Disabled = 'disabled',
  NoData = 'no-data',
  NoResults = 'no-results',
  IsEmpty = 'empty',
}

export const OverviewStateBoundary = ({
  isDisabled = false,
  isEmpty = false,
  noData = false,
  isRisksTile = false,
  noCTA = false,
  customEmptyStateCTA = <Fragment />,
  children,
  ...props
}: {
  isDisabled?: boolean;
  isEmpty?: boolean;
  noData?: boolean;
  isRisksTile?: boolean;
  noCTA?: boolean;
  customEmptyStateCTA?: ReactNode;
  children: ReactNode;
}) => {
  const stateMode = isDisabled
    ? OverviewStateMode.Disabled
    : noData
      ? OverviewStateMode.NoData
      : isEmpty
        ? OverviewStateMode.IsEmpty
        : OverviewStateMode.None;

  if (stateMode === OverviewStateMode.None) {
    return <>{children}</>;
  }

  const Image =
    stateMode === OverviewStateMode.Disabled || stateMode === OverviewStateMode.NoData
      ? EmptyBoxImage
      : NoResultsFlashlight;

  return (
    <StateContainer {...props}>
      <Image />
      {stateMode === OverviewStateMode.Disabled ? (
        <Paragraph data-disabled-state>
          No data available as one or more applications are module-based
        </Paragraph>
      ) : (
        <>
          {stateMode === OverviewStateMode.IsEmpty ? (
            <Paragraph>No results found</Paragraph>
          ) : (
            <Paragraph>No data available</Paragraph>
          )}
          {stateMode === OverviewStateMode.NoData && customEmptyStateCTA}
        </>
      )}
    </StateContainer>
  );
};

const StateContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3rem;
  align-items: center;

  ${Paragraph} {
    font-size: var(--font-size-s);
    font-weight: 500;
    text-align: center;

    &[data-risk-tile] {
      margin-top: 0;
    }

    &[data-disabled-state] {
      padding: 0 6rem;
      font-size: var(--font-size-s);
    }
  }

  ${Button} {
    margin-top: 1rem;
  }
}
`;

const EmptyBoxImage = styled(emptyBox)`
  width: 40rem;
  height: 28rem;
`;
