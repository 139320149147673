import _ from 'lodash';
import { GraphEdge } from '@src-v2/containers/sw-architecture-graph/types/graph-edge-types';
import {
  DataGraphNode,
  GraphNode,
} from '@src-v2/containers/sw-architecture-graph/types/graph-node-types';
import { RepositoryDataModelReference } from '@src-v2/types/data-model-reference/data-model-reference';
import { ProviderGroup } from '@src-v2/types/enums/provider-group';
import { RiskLevel } from '@src-v2/types/enums/risk-level';
import { PartialRecord } from '@src-v2/types/partial-record';
import { entries } from '@src-v2/utils/ts-utils';

const connectionDictionary: PartialRecord<string, string> = {
  api: 'module',
};

export const generateMockData = (count: number = 50) => {
  const items: GraphNode[] = [];

  const availableTypes = entries(typeToConstructor)
    .filter(([_, ctor]) => Boolean(ctor))
    .map(([key]) => key);

  for (let i = 0; i < count; i++) {
    items.push(typeToConstructor[availableTypes[_.random(0, availableTypes.length - 1)]](i));
  }

  const links: GraphEdge[] = [];
  const itemsByType = _.groupBy(items, i => i.symbol.split('.')[0]);

  for (let i = 0; i < count; i++) {
    const currentItem = items[i];
    let connectionSymbol = connectionDictionary[currentItem.symbol];
    if (currentItem.symbol.startsWith('repository')) {
      continue;
    }

    connectionSymbol ??= 'repository';

    const optionalConnections = itemsByType[connectionSymbol];

    const entityToConnect = optionalConnections[_.random(0, optionalConnections.length - 1)];

    links.push({
      source: entityToConnect.id,
      sourceSymbol: entityToConnect.symbol,
      target: currentItem.id,
      role: 'contains',
    });
  }

  return { nodes: items, links };
};

const typeToConstructor: { [key: string]: ((i: number) => GraphNode) | null } = {
  nodeCluster: null,
  module: (i: number): DataGraphNode => ({
    type: 'data',
    dataModelReference: undefined,
    displayName: `Module ${i}`,
    id: `module_${i}`,
    pimples: [],
    symbol: 'module',
    typeDisplayName: 'Module',
  }),
  component: null,
  repository: (i: number): DataGraphNode => {
    const dataModel: RepositoryDataModelReference = {
      identifier: '685674dd-e40d-4356-aa32-140e940ddc10',
      repositoryKey: '685674dd-e40d-4356-aa32-140e940ddc10',
    };
    return {
      dataModelReference: dataModel,
      type: 'data',
      displayName: `Mock repository (id: ${i})`,
      id: `repository_${i}`,
      pimples: [],
      symbol: `repository.${i % 3 ? ProviderGroup.AzureCloud : i % 2 ? ProviderGroup.Github : ProviderGroup.Gitlab}`,
      typeDisplayName: 'Repository',
    };
  },
  artifact: null,
  externalArtifact: null,
  externalTechnology: null,
  computeResource: null,
  api: (i: number): DataGraphNode => ({
    dataModelReference: { identifier: `api ref ${i}` },
    type: 'data',
    displayName: `API ${i}`,
    id: `api_${i}`,
    pimples: [],
    symbol: 'api',
    typeDisplayName: 'API',
    riskLevel: i % 6 ? RiskLevel.High : i % 5 ? RiskLevel.Low : undefined,
  }),
  endpoint: null,
  dataModel: (i: number): DataGraphNode => ({
    dataModelReference: undefined,
    type: 'data',
    displayName: `Data model: ${i}`,
    id: `data_model_${i}`,
    pimples: [],
    symbol: 'dataModel',
  }),
  risk: null,
};
