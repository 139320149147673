import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { CircleButton, TextButton } from '@src-v2/components/button-v2';
import { CheckboxToggle, Radio } from '@src-v2/components/forms';
import {
  CheckboxControl,
  InputControl,
  UploadFileControl,
} from '@src-v2/components/forms/form-controls';
import { InputClickableLabel } from '@src-v2/components/forms/form-input-label';
import { FormLayoutV2 } from '@src-v2/components/forms/form-layout-v2';
import { SvgIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Size } from '@src-v2/components/types/enums/size';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import {
  ExternalLink,
  Heading3,
  Heading5,
  Paragraph,
  SubHeading3,
} from '@src-v2/components/typography';
import { ManagedSemgrepTitle } from '@src-v2/containers/connectors/server-modal/connector-editor';
import { ReloadRulesetModal } from '@src-v2/containers/pages/general-settings/managed-semgrep/reload-ruleset-modal';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { formatDate } from '@src-v2/utils/datetime-utils';

enum OPTIONS_NAMES {
  SEMGREP_MANUAL = 'semgrep_manual',
  GITHUB_REPOSITORY = 'github_repository',
  UPLOAD_SEMGREP_RULESET = 'upload_semgrep_ruleset',
}

const semgrepStandardUrl = 'https://github.com/semgrep/semgrep-rules';

export const ManagedSemgrepSettingsForm = () => {
  const { setValue, watch } = useFormContext();
  const githubPublicUrlOriginal = useRef();
  const tarFileNameOriginal = useRef();

  const [
    rulesTarFileNameTime,
    isConfigureSemgrepEnabled,
    rulesSetGitRepositoryTime,
    tarFileName,
    rulesSetGitRepository,
  ] = watch([
    'rulesTarFileNameTime',
    'isConfigureSemgrepEnabled',
    'rulesSetGitRepositoryTime',
    'tarFileName',
    'rulesSetGitRepository',
  ]);
  const [modalElement, setModal, closeModal] = useModalState();

  const latestUpdateDate = useMemo(
    () => rulesTarFileNameTime || rulesSetGitRepositoryTime,
    [rulesTarFileNameTime, rulesSetGitRepositoryTime]
  );

  const selectedOption = useMemo(() => {
    if (rulesSetGitRepository && rulesSetGitRepository !== semgrepStandardUrl) {
      githubPublicUrlOriginal.current = rulesSetGitRepository;
      return OPTIONS_NAMES.GITHUB_REPOSITORY;
    }
    if (tarFileName) {
      tarFileNameOriginal.current = tarFileName;
      return OPTIONS_NAMES.UPLOAD_SEMGREP_RULESET;
    }
    return OPTIONS_NAMES.SEMGREP_MANUAL;
  }, [rulesSetGitRepository, tarFileName]);

  useEffect(() => {
    setValue(
      'rulesSetGitRepository',
      rulesSetGitRepository ? rulesSetGitRepository : semgrepStandardUrl,
      {
        shouldDirty: true,
      }
    );
  }, []);

  const [optionSelected, setOptionSelected] = useState<OPTIONS_NAMES>(selectedOption);

  const handleOptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setOptionSelected(event.target.value as OPTIONS_NAMES),
    []
  );

  return (
    <FormLayoutV2.Section>
      <Header>
        <Heading3>Configure Managed Semgrep</Heading3>
        <SubHeading3 data-variant={Variant.SECONDARY}>
          Apiiro's built-in Managed Semgrep automatically scans all your monitored code concurrently
          with Apiiro's native analysis according to your configured Semgrep rules and adds Apiiro
          context to get more informative and detailed code findings.
        </SubHeading3>
      </Header>
      <InputClickableLabelWrapper>
        <CheckboxControl
          Component={CheckboxToggle}
          defaultValue={false}
          name="isConfigureSemgrepEnabled"
        />
        <>{isConfigureSemgrepEnabled ? 'Active' : 'Inactive'}</>
      </InputClickableLabelWrapper>
      {isConfigureSemgrepEnabled && (
        <>
          <RadioButtonWrapper>
            <Heading5>Define a ruleset</Heading5>
            <RadioLabel>
              <Radio
                value={OPTIONS_NAMES.SEMGREP_MANUAL}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleOptionChange(event);
                  setValue('rulesSetGitRepository', semgrepStandardUrl, {
                    shouldDirty: true,
                  });
                  setValue('tarFileName', null);
                }}
                checked={optionSelected === OPTIONS_NAMES.SEMGREP_MANUAL}
              />
              <ManagedSemgrepTitle>
                <>Semgrep standard open source rules</>
                <Tooltip content="https://github.com/semgrep/semgrep-rules">
                  <CircleButton
                    href="https://github.com/semgrep/semgrep-rules"
                    size={Size.SMALL}
                    variant={Variant.TERTIARY}>
                    <SvgIcon name="External" />
                  </CircleButton>
                </Tooltip>
              </ManagedSemgrepTitle>
            </RadioLabel>
            <ManagedSemgrepLabelContainer>
              <RadioLabel>
                <Radio
                  value={OPTIONS_NAMES.GITHUB_REPOSITORY}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleOptionChange(event);
                    setValue(
                      'rulesSetGitRepository',
                      githubPublicUrlOriginal.current ? githubPublicUrlOriginal.current : '',
                      {
                        shouldDirty: true,
                      }
                    );
                    setValue('tarFileName', null);
                  }}
                  checked={optionSelected === OPTIONS_NAMES.GITHUB_REPOSITORY}
                />
                <>GitHub public repository</>
              </RadioLabel>
              {optionSelected === OPTIONS_NAMES.GITHUB_REPOSITORY && (
                <InputControl
                  name="rulesSetGitRepository"
                  placeholder="Enter a repository URL..."
                  rules={{
                    required: true,
                    pattern: {
                      value: /^https?:\/\/github\.com\/[\w-]+(?:\/[\w-]+(?:\/[\w-]+)*)?\/?$/,
                      message: 'Please enter a valid GitHub repository URL',
                    },
                  }}
                />
              )}
            </ManagedSemgrepLabelContainer>
            <ManagedSemgrepLabelContainer>
              <RadioLabel>
                <Radio
                  value={OPTIONS_NAMES.UPLOAD_SEMGREP_RULESET}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleOptionChange(event);
                    setValue('rulesSetGitRepository', '');
                    setValue('tarFileName', tarFileNameOriginal.current);
                  }}
                  checked={optionSelected === OPTIONS_NAMES.UPLOAD_SEMGREP_RULESET}
                />
                <>Upload a .tar file</>
              </RadioLabel>
              {optionSelected === OPTIONS_NAMES.UPLOAD_SEMGREP_RULESET && (
                <span>
                  <Paragraph>You can add a file up to 4mb (.tar)</Paragraph>
                  <UploadFileControl
                    name="tarFileName"
                    label="Upload file"
                    accept=".tar"
                    maxSize={4}
                    rules={{ required: true }}
                  />
                </span>
              )}
            </ManagedSemgrepLabelContainer>
          </RadioButtonWrapper>
          <ExternalLink href="https://docs.apiiro.com/orchestrate-tools/managed_semgrep">
            Read our docs for detailed instructions
          </ExternalLink>
          {latestUpdateDate && (
            <FormLayoutV2.SectionFooter>
              <SvgIcon name="Info" size={Size.XXSMALL} />
              Last update: {formatDate(latestUpdateDate, 'time')}.
              {optionSelected !== OPTIONS_NAMES.UPLOAD_SEMGREP_RULESET && (
                <TextButton
                  underline
                  size={Size.XXSMALL}
                  onClick={() =>
                    setModal(
                      <ReloadRulesetModal
                        url={rulesSetGitRepository}
                        onClose={closeModal}
                        setValue={setValue}
                      />
                    )
                  }>
                  Reload latest ruleset
                </TextButton>
              )}
            </FormLayoutV2.SectionFooter>
          )}
        </>
      )}
      {modalElement}
    </FormLayoutV2.Section>
  );
};

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ManagedSemgrepLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${Paragraph} {
    color: var(--color-blue-gray-60);
  }

  & > :not(${InputClickableLabel}) {
    padding-left: 6rem;
  }
`;

const InputClickableLabelWrapper = styled(InputClickableLabel)`
  display: flex;
  gap: 2rem;
  align-items: center;
  width: fit-content;
`;

const RadioLabel = styled(InputClickableLabelWrapper)`
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
