import { useMemo } from 'react';
import styled from 'styled-components';
import { ActivityIndicator } from '@src-v2/components/activity-indicator';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { Badge, BadgeColors } from '@src-v2/components/badges';
import { BusinessImpactIndicator } from '@src-v2/components/business-impact-indictor';
import { TextButton } from '@src-v2/components/button-v2';
import { ControlledCard, ControlledCardProps } from '@src-v2/components/cards/controlled-card';
import { Divider } from '@src-v2/components/divider';
import { ElementSeparator } from '@src-v2/components/element-separator';
import { ElementInsights } from '@src-v2/components/entity-pane/evidence/element-insights';
import {
  CenteredEvidenceLine,
  EvidenceLine,
  EvidenceLinesWrapper,
} from '@src-v2/components/entity-pane/evidence/evidence-line';
import { VendorIcon } from '@src-v2/components/icons';
import { Size } from '@src-v2/components/types/enums/size';
import { EllipsisText, ExternalLink, SubHeading4 } from '@src-v2/components/typography';
import { useUserStoryPaneContext } from '@src-v2/containers/entity-pane/user-story/use-user-story-pane-context';
import { useInject, useSuspense } from '@src-v2/hooks';
import { CodeContext } from '@src-v2/types/inventory-elements/code-context';
import { Insight } from '@src-v2/types/risks/insight';
import { stopPropagation } from '@src-v2/utils/dom-utils';
import { pluralFormat } from '@src-v2/utils/number-utils';

export function SourceCodeStoryTicketCard(props: ControlledCardProps) {
  const { inventory } = useInject();
  const { element } = useUserStoryPaneContext();
  const codeContext = useSuspense(inventory.getCodeContext, { key: element.key });

  if (!codeContext?.length) {
    return null;
  }

  return (
    <AsyncBoundary>
      <ControlledCard {...props} title="Source code">
        <SourceCodeSubHeader>
          This ticket was linked to{' '}
          {pluralFormat(codeContext?.length, 'repository', 'repositories', true)}
        </SourceCodeSubHeader>

        <EvidenceLinesWrapper>
          <ElementSeparator separator={<Divider />}>
            {codeContext.map((code, index) => (
              <CodeContextContent code={code} key={index} />
            ))}
          </ElementSeparator>
        </EvidenceLinesWrapper>
      </ControlledCard>
    </AsyncBoundary>
  );
}

const RepositoryName = ({ name, branchName }: { name: string; branchName: string }) => (
  <EllipsisText>
    {name}
    {branchName && <> ({branchName})</>}
  </EllipsisText>
);

const CodeContextContent = ({ code }: { code: CodeContext }) => {
  const { repository, pullRequests } = code;

  const {
    businessImpact,
    isActive,
    isDeployed,
    isInternetExposed,
    key,
    name,
    provider,
    branchName,
  } = repository;

  const insights = useMemo<Insight[]>(() => {
    const insightsList = [];

    if (isDeployed) {
      insightsList.push({
        badge: 'Deployed',
        sentiment: 'Negative' as const,
      });
    }

    if (isInternetExposed) {
      insightsList.push({
        badge: 'Internet exposed',
        sentiment: 'Negative' as const,
      });
    }

    return insightsList;
  }, [isDeployed, isInternetExposed]);

  return (
    <>
      <CenteredEvidenceLine isExtendedWidth label="Repository name">
        {provider && <VendorIcon name={provider} />}
        {key ? (
          <TextButton underline to={`/profiles/repositories/${key}`} onClick={stopPropagation}>
            <RepositoryName name={name} branchName={branchName} />
          </TextButton>
        ) : (
          <RepositoryName name={name} branchName={branchName} />
        )}
        <BusinessImpactIndicator businessImpact={businessImpact} />
        <ActivityIndicator active={isActive} />
      </CenteredEvidenceLine>
      {Boolean(insights.length) && (
        <EvidenceLine isExtendedWidth label="Repository insights">
          <ElementInsights insights={insights} />
        </EvidenceLine>
      )}
      <EvidenceLine isExtendedWidth label="Linked through">
        <PullRequestsWrapper>
          {pullRequests.map(pullRequest => {
            const { id, isOpen, url } = pullRequest;

            return (
              <PullRequestWrapper>
                <PullRequestLink>
                  Pull request
                  <ExternalLink href={url}>#{id}</ExternalLink>
                </PullRequestLink>
                <Badge size={Size.XSMALL} color={BadgeColors.Blue}>
                  {isOpen ? 'Open' : 'Merged'}
                </Badge>
              </PullRequestWrapper>
            );
          })}
        </PullRequestsWrapper>
      </EvidenceLine>
    </>
  );
};

const SourceCodeSubHeader = styled(SubHeading4)`
  margin-bottom: 4rem;
`;

const PullRequestsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const PullRequestWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const PullRequestLink = styled.span`
  display: flex;
  gap: 1rem;
`;
