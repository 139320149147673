import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton } from '@src-v2/components/buttons';
import { useInject } from '@src-v2/hooks';
import { dataAttr } from '@src-v2/utils/dom-utils';

export const JwtCopyBanner = ({ jwt }) => {
  const { toaster } = useInject();
  const { pathname } = useLocation();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(jwt);
    toaster.success('Token copied to clipboard!');
  };

  return (
    <TokenCopyBanner data-edit={dataAttr(pathname.includes('edit'))}>
      New access token created, quickly copy to clipboard{' '}
      <IconButton name="Copy" onClick={handleCopy} />
    </TokenCopyBanner>
  );
};

const TokenCopyBanner = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-size-s);
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 0.25rem solid var(--color-blue-gray-20);
  gap: 2rem;

  &[data-edit] {
    margin-top: 0;
  }
`;
