import { format, isAfter } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { BadgeColors } from '@src-v2/components/badges';
import { useOverviewFilters } from '@src-v2/components/overview/use-overview-filters';
import {
  ChartAccessors,
  ChartFormatters,
  RiskScoreTrendResult,
  RiskyScoreTrendData,
  TrendStyle,
} from '@src-v2/containers/overview/tiles/risky-score-trend-tile/types';
import { useInject, useSuspense } from '@src-v2/hooks';
import { abbreviate } from '@src-v2/utils/number-utils';

const getTrendStyle = (percentageChange: number): TrendStyle => {
  const abbreviatedChange = parseFloat(abbreviate(percentageChange, 1));

  let trendColor: BadgeColors;
  let badgeIcon: string;
  let lineColor: string;

  if (abbreviatedChange > 0) {
    trendColor = BadgeColors.Red;
    badgeIcon = 'TrendUp';
    lineColor = 'var(--color-red-50)';
  } else if (abbreviatedChange < 0) {
    trendColor = BadgeColors.Green;
    badgeIcon = 'TrendDown';
    lineColor = 'var(--color-green-45)';
  } else {
    trendColor = BadgeColors.Blue;
    badgeIcon = 'Minus';
    lineColor = 'var(--color-blue-60)';
  }

  return { trendColor, badgeIcon, lineColor };
};

export function useRiskScoreTrend(): RiskScoreTrendResult {
  const { overview } = useInject();
  const { activeFilters: filters = {} } = useOverviewFilters();
  const riskScoreResults = useSuspense(overview.getRiskScoreTrend, {
    filters,
  });

  const { data: baseData, percentageChange } = useMemo(() => {
    const data = riskScoreResults
      .map(item => ({
        date: new Date(item.date),
        count: item.count,
      }))
      .sort((a, b) => a.date.getTime() - b.date.getTime());

    let percentageChange: number;
    if (data.length < 2) {
      percentageChange = 0;
    } else {
      const firstValue = data[0].count;
      const lastValue = data[data.length - 1].count;
      if (firstValue === 0) {
        percentageChange = lastValue > 0 ? 100 : 0;
      } else {
        percentageChange = ((lastValue - firstValue) / firstValue) * 100;
      }
    }

    const now = new Date();
    const nonFutureDatesData = data.filter(item => !isAfter(new Date(item.date), now));

    return { data: nonFutureDatesData, percentageChange };
  }, [riskScoreResults]);

  const metrics = useMemo(() => {
    if (baseData.length === 0) {
      return { minValue: 0, maxValue: 0, range: 0, isConstantNonZero: false };
    }
    const dataCountValues = baseData.map(d => d.count);
    const minValue = Math.min(...dataCountValues);
    const maxValue = Math.max(...dataCountValues);
    const range = maxValue - minValue;
    const isConstantNonZero = maxValue === 0;

    return { minValue, maxValue, range, isConstantNonZero };
  }, [baseData]);

  const data = useMemo(() => {
    if (baseData.length === 0) {
      return [];
    }

    if (metrics.isConstantNonZero || metrics.range === 0) {
      return baseData.map(item => ({
        ...item,
        normalizedCount: 0.8,
      }));
    }

    return baseData.map(item => ({
      ...item,
      normalizedCount: (item.count - metrics.minValue) / metrics.range,
    }));
  }, [baseData, metrics]);

  const style = useMemo(() => getTrendStyle(percentageChange), [percentageChange]);
  const chartTheme = useMemo(() => ({ colors: [style.lineColor] }), [style.lineColor]);

  const formatters: ChartFormatters = {
    xAxisTickFormat: useCallback((tick: Date | number | string) => format(tick, 'd/M'), []),
    yAxisTickFormat: useCallback(
      (value: number) => {
        if (metrics.range === 0) {
          return abbreviate(metrics.minValue, 0);
        }
        return abbreviate(value * metrics.range + metrics.minValue, 0);
      },
      [metrics]
    ),
  };

  const accessors: ChartAccessors = {
    xAccessor: useCallback((d: RiskyScoreTrendData) => d.date, []),
    yAccessor: useCallback((d: RiskyScoreTrendData) => d.normalizedCount, []),
  };

  const isEmpty = data.length === 0 || data.every(item => item.count === 0);

  return {
    data,
    percentageChange,
    isEmpty,
    chartTheme,
    formatters,
    accessors,
    metrics,
    style,
  };
}
