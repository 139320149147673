import Color from 'color';
import { getChartIcon } from '@src-v2/containers/sw-architecture-graph/caching-utils';
import { getVendorIconUrl } from '@src-v2/data/icons';
import { ProviderGroup } from '@src-v2/types/enums/provider-group';
import { getCssVariable } from '@src-v2/utils/style-utils';

type NodeSymbol = string;
const BASE_ICON_SIZE = 16;

export class SymbolRenderingSettings {
  iconSrc: string;
  iconSize?: number;
  iconDisplayThreshold?: number;
  backgroundColor?: string;
  outlineColor?: string;
  hoverOutlineColor?: string;
  hoverBackgroundColor?: string;

  constructor(settings: SymbolRenderingSettings) {
    this.iconSrc = settings.iconSrc;
    this.iconSize = settings.iconSize ?? BASE_ICON_SIZE;
    this.iconDisplayThreshold = settings.iconDisplayThreshold;
    this.backgroundColor = settings.backgroundColor || 'white';
    this.outlineColor = settings.outlineColor;
    this.hoverOutlineColor =
      settings.hoverOutlineColor || Color(this.backgroundColor).darken(0.7).toString();
  }
}

const generateVendorIconSettings = (providerGroup: ProviderGroup): SymbolRenderingSettings => {
  return new SymbolRenderingSettings({
    iconSrc: getVendorIconUrl(providerGroup),
    outlineColor: getCssVariable('--color-blue-gray-30'),
    hoverOutlineColor: getCssVariable('--color-blue-gray-40'),
    hoverBackgroundColor: getCssVariable('--color-blue-gray-20'),
    iconDisplayThreshold: 0,
  });
};

const moduleIconSettings = new SymbolRenderingSettings({
  iconSrc: getChartIcon('Module'),
  backgroundColor: getCssVariable('--color-blue-gray-55'),
});

const settingsByNodeType: Record<NodeSymbol, SymbolRenderingSettings> = {
  nodeCluster: new SymbolRenderingSettings({
    iconSrc: getChartIcon('Cluster'),
    backgroundColor: 'white',
  }),
  module: moduleIconSettings,
  component: moduleIconSettings,
  risk: new SymbolRenderingSettings({ iconSrc: getChartIcon('Risk') }),
  api: new SymbolRenderingSettings({
    iconSrc: getChartIcon('Api'),
    backgroundColor: getCssVariable('--color-blue-65'),
  }),
  'repository.Github': generateVendorIconSettings(ProviderGroup.Github),
  'repository.AzureCloud': generateVendorIconSettings(ProviderGroup.AzureCloud),
  'repository.Gitlab': generateVendorIconSettings(ProviderGroup.Gitlab),
  artifact: moduleIconSettings,
  externalArtifact: new SymbolRenderingSettings({
    iconSrc: getChartIcon('Dependency'),
    backgroundColor: getCssVariable('--color-purple-45'),
  }),
  externalTechnology: moduleIconSettings,
  computeResource: moduleIconSettings,
  dataModel: new SymbolRenderingSettings({
    iconSrc: getChartIcon('DataModel'),
    backgroundColor: getCssVariable('--color-orange-50'),
  }),
};

export const getSymbolRenderingSettings = (symbol: NodeSymbol): SymbolRenderingSettings =>
  settingsByNodeType[symbol];
