import * as d3 from 'd3';
import _ from 'lodash';
import {
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ForceGraphMethods, LinkObject, NodeObject } from 'react-force-graph-2d';
import styled from 'styled-components';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { isEmptyDeep } from '@src-v2/utils/object-utils';
import {
  ChartLinkVisual,
  ChartVisuals,
} from '@src/cluster-map-work/components/charts/chart-visuals';
import { ChartZoomControl } from '@src/cluster-map-work/components/charts/chart-zoom-control';
import {
  D3GraphOverview,
  D3GraphOverviewMethods,
  SizedForceGraph2D,
} from '@src/cluster-map-work/components/charts/graph-utils';
import { VerticalStack } from '@src/components/VerticalStack';

const DOUBLE_CLICK_SPEED = 200;

export type NodeSelectionReason = 'click' | 'external';

export type NodeObjectWithNeighbours<TNode> = NodeObject<{ nodeData: TNode }> & {
  neighbours: NodeObjectWithNeighbours<TNode>[];
  nodeVisual?: ChartVisuals;
};
export type LinkObjectForChartView<TNode, TEdge> = LinkObject<
  NodeObjectWithNeighbours<TNode>,
  {
    edgeData: TEdge;
    edgeVisual?: ChartLinkVisual;
  }
>;

export type TNodeBase = { id: string };
export type TEdgeBase = { source: string; target: string };
export type ChartData<TNode extends TNodeBase, TEdge extends TEdgeBase> = {
  nodes: TNode[];
  links: TEdge[];
};

export type RenderedChartData<TNode extends TNodeBase, TEdge extends TEdgeBase> = {
  nodes: NodeObjectWithNeighbours<TNode>[];
  links: LinkObjectForChartView<TNode, TEdge>[];
  nodesById: { [nodeId: string]: NodeObjectWithNeighbours<TNode> };
};

export type NodeVisualsFactory<TNode> = (node: TNode) => ChartVisuals;
export type EdgeVisualsFactory<TEdge extends TEdgeBase> = (edge: TEdge) => ChartLinkVisual;

type NodeTooltipProps = { content: ReactNode; position: { x: number; y: number } };

function createRenderedChartData<TNode extends TNodeBase, TEdge extends TEdgeBase>(
  chartData?: ChartData<TNode, TEdge>
): RenderedChartData<TNode, TEdge> {
  const nodes = chartData.nodes.map<NodeObjectWithNeighbours<TNode>>(node => ({
    id: node.id,
    nodeData: node,
    neighbours: [] as NodeObjectWithNeighbours<TNode>[],
  }));

  const nodesById = _.keyBy(nodes, node => node.id);

  const links: LinkObjectForChartView<TNode, TEdge>[] = chartData.links.map(edge => ({
    source: nodesById[edge.source],
    target: nodesById[edge.target],
    edgeData: edge,
  }));

  links.forEach(link => {
    if (typeof link.source === 'object' && typeof link.target === 'object') {
      link.source.neighbours.push(link.target);
      link.target.neighbours.push(link.source);
    }
  });

  return {
    nodes,
    links,
    nodesById,
  };
}

export type GraphLayoutMode = 'force' | 'radial' | 'manual';
export type ChartViewProps<TNode extends TNodeBase, TEdge extends TEdgeBase> = {
  chartData: ChartData<TNode, TEdge>;
  nodeVisualsFactory: NodeVisualsFactory<TNode>;
  linkVisualsFactory: EdgeVisualsFactory<TEdge>;
  externalSelectedNode: any;
  highlightsList?: TNode[];
  overviewSize?: { width: number; height: number };
  maxZoom?: number;
  graphLayoutMode: GraphLayoutMode;
  onGraphLayoutModeChanged?: (newLayoutMode: GraphLayoutMode) => void;
  onNodeSelected?: (node: TNode, selectionReason: NodeSelectionReason) => void;
};

export function ChartView<TNode extends TNodeBase, TEdge extends TEdgeBase>({
  chartData,
  nodeVisualsFactory,
  linkVisualsFactory,
  externalSelectedNode,
  highlightsList,
  overviewSize,
  graphLayoutMode,
  maxZoom = 8,
  onNodeSelected,
  onGraphLayoutModeChanged,
}: ChartViewProps<TNode, TEdge>) {
  // References
  const chartRef =
    useRef<
      ForceGraphMethods<NodeObjectWithNeighbours<TNode>, LinkObjectForChartView<TNode, TEdge>>
    >();
  const overviewRef = useRef<D3GraphOverviewMethods>();

  // Graph data
  const renderedGraphData = useMemo(
    () =>
      (chartData && createRenderedChartData(chartData)) || {
        nodes: [],
        links: [],
        nodesById: {},
      },
    [chartData]
  );

  useEffect(() => {
    if (isEmptyDeep(renderedGraphData)) {
      return;
    }

    renderedGraphData.nodes.forEach(node => (node.nodeVisual = nodeVisualsFactory(node.nodeData)));
    renderedGraphData.links.forEach(link => (link.edgeVisual = linkVisualsFactory(link.edgeData)));
  }, [renderedGraphData, nodeVisualsFactory, linkVisualsFactory]);

  useEffect(() => {
    onGraphLayoutModeChanged?.('force');
    chartRef.current.zoom(1);
    chartRef.current.centerAt(0, 0);
    setHoveredNode(null);
    setSelectedNode(null);
  }, [renderedGraphData]);

  // Hilight, hover and selection
  const [selectedNode, setSelectedNode] = useState<NodeObjectWithNeighbours<TNode> | null>(null);
  const [hoveredNode, setHoveredNode] = useState<NodeObjectWithNeighbours<TNode> | null>(null);

  const nodeTooltipProps = useMemo<NodeTooltipProps | null>(() => {
    if (!hoveredNode?.nodeVisual) {
      return null;
    }
    const { nodeVisual } = hoveredNode;
    const graphBbox = chartRef.current.getGraphBbox(n => n.id === hoveredNode.id);
    return {
      content: nodeVisual.getDescriptionPopover(),
      position: chartRef.current.graph2ScreenCoords(hoveredNode.x, graphBbox.y[0]),
    };
  }, [hoveredNode]);

  const selectNode = useCallback(
    (node: NodeObjectWithNeighbours<TNode>, reason: NodeSelectionReason) => {
      setSelectedNode(node);
      onNodeSelected?.(node?.nodeData, reason);
    },
    [setSelectedNode, onNodeSelected]
  );

  const effectiveHighlightedNodes = useMemo(() => {
    if (highlightsList) {
      return new Set(highlightsList);
    }

    const cliqueFocusNode = hoveredNode || selectedNode;
    if (!cliqueFocusNode) {
      return null;
    }

    const ret = new Set();
    const visitQueue = [renderedGraphData.nodesById[cliqueFocusNode.id]];

    for (let queueIndex = 0; queueIndex < visitQueue.length; queueIndex++) {
      const currentNode = visitQueue[queueIndex];
      if (!ret.has(currentNode.id)) {
        ret.add(currentNode.id);
        visitQueue.push(...currentNode.neighbours);
      }
    }

    return ret;
  }, [highlightsList, hoveredNode, selectedNode]);

  const isNodeHighlighted = (node: NodeObjectWithNeighbours<TNode>) =>
    effectiveHighlightedNodes && effectiveHighlightedNodes.has(node.id);

  const isEdgeHilighted = (edge: LinkObjectForChartView<TNode, TEdge>) =>
    isNodeHighlighted(edge.source as NodeObjectWithNeighbours<TNode>) ||
    isNodeHighlighted(edge.target as NodeObjectWithNeighbours<TNode>);

  useEffect(() => {
    chartRef.current.zoomToFit(500, 10, isNodeHighlighted);
  }, [highlightsList, renderedGraphData]);

  // Zoom, layout and node flight
  const [selectionZoomPending, setSelectionZoomPending] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(0);
  const [minZoomFactor, setMinZoomFactor] = useState(0.05);
  const screenBoundingBox = useRef({ top: 0, left: 0, width: 1, height: 1 });
  const clusterBoundingBox = useRef({ top: 0, left: 0, width: 1, height: 1 });
  const canvasSize = useRef({ width: 1, height: 1 });

  const calculateMinZoomFactor = useCallback(() => {
    return Math.min(
      Math.max(
        canvasSize.current.width / (2 * clusterBoundingBox.current.width),
        canvasSize.current.height / (2 * clusterBoundingBox.current.height)
      ),
      1
    );
  }, [canvasSize, clusterBoundingBox]);

  useEffect(() => {
    const forceGraph = chartRef.current;

    if (!forceGraph || !renderedGraphData) {
      return;
    }

    if (graphLayoutMode === 'manual') {
      renderedGraphData.nodes.forEach(node => {
        if (node.x !== undefined && node.y !== undefined) {
          node.fx = node.x;
          node.fy = node.y;
        }
      });

      if (selectionZoomPending) {
        setSelectionZoomPending(null);
        zoomToNode(selectionZoomPending);
      } else {
        handleZoomRequest(null, calculateMinZoomFactor(), true, true);
      }
    } else {
      renderedGraphData.nodes.forEach(node => {
        delete node.fx;
        delete node.fy;
      });

      switch (graphLayoutMode) {
        case 'force':
          forceGraph.d3Force('charge', d3.forceManyBody().strength(-300));
          forceGraph.d3Force('link', d3.forceLink(renderedGraphData.links as any));
          forceGraph.d3Force('center', d3.forceCenter());
          break;

        case 'radial':
          forceGraph.d3Force(
            'charge',
            orderedRadialForce(renderedGraphData.nodes.length * 5, 0, 0)
          );
          forceGraph.d3Force('link', null);
          forceGraph.d3Force('center', null);
          break;

        default:
          break;
      }

      forceGraph.d3ReheatSimulation();
    }
  }, [graphLayoutMode, renderedGraphData, calculateMinZoomFactor]);

  const handlePreRenderFrame = useCallback(
    (canvasContext: CanvasRenderingContext2D) => {
      if (!chartRef.current) {
        return;
      }

      const {
        x: [xmin, xmax],
        y: [ymin, ymax],
      } = chartRef.current.getGraphBbox();

      Object.assign(clusterBoundingBox.current, {
        top: ymin,
        left: xmin,
        width: xmax - xmin,
        height: ymax - ymin,
      });

      const screenBoundingBoxTopLeft = chartRef.current.screen2GraphCoords(0, 0);
      const screenBoundingBoxBottomRight = chartRef.current.screen2GraphCoords(
        canvasContext.canvas.clientWidth,
        canvasContext.canvas.clientHeight
      );

      Object.assign(screenBoundingBox.current, {
        top: screenBoundingBoxTopLeft.y,
        left: screenBoundingBoxTopLeft.x,
        width: screenBoundingBoxBottomRight.x - screenBoundingBoxTopLeft.x,
        height: screenBoundingBoxBottomRight.y - screenBoundingBoxTopLeft.y,
      });

      canvasSize.current.width = canvasContext.canvas.clientWidth;
      canvasSize.current.height = canvasContext.canvas.clientHeight;
    },
    [chartRef]
  );

  const handleForceStop = useCallback(() => {
    onGraphLayoutModeChanged?.('manual');
  }, [selectionZoomPending, setSelectionZoomPending]);

  useEffect(() => {
    if (externalSelectedNode && externalSelectedNode.id !== selectedNode?.id) {
      const newSelection = renderedGraphData.nodesById[externalSelectedNode.id];
      if (!newSelection) {
        return;
      }

      selectNode(newSelection, 'external');
      zoomToNode(newSelection);
    }
  }, [renderedGraphData, externalSelectedNode]);

  const zoomToNode = useCallback(
    (zoomTarget: NodeObjectWithNeighbours<TNode>) => {
      if (graphLayoutMode === 'manual') {
        setMinZoomFactor(0);

        chartRef.current.zoom(0.5, 500);

        setTimeout(() => {
          chartRef.current.centerAt(zoomTarget.x, zoomTarget.y, 500);
        }, 300);

        setTimeout(() => {
          chartRef.current.zoom(3, 400);
        }, 600);

        setTimeout(() => {
          setMinZoomFactor(calculateMinZoomFactor());
        }, 1050);
      } else {
        setSelectionZoomPending(zoomTarget);
      }
    },
    [setSelectionZoomPending, setMinZoomFactor, graphLayoutMode, chartRef, calculateMinZoomFactor]
  );

  const handleZoomEnd = useCallback(
    (zoom: { k: number }) => {
      setCurrentZoom(zoom.k);
    },
    [setCurrentZoom]
  );

  const handleZoomRequest = useCallback(
    (_: MouseEvent, newZoom: number, isHome: boolean, ignoreBounds: boolean = false) => {
      if (ignoreBounds) {
        setMinZoomFactor(0);
      }

      chartRef.current.zoom(newZoom, 200);
      if (isHome) {
        chartRef.current.centerAt(0, 0, 200);
      }

      setTimeout(() => {
        setMinZoomFactor(calculateMinZoomFactor());
      }, 250);
    },
    [chartRef.current, setMinZoomFactor, calculateMinZoomFactor]
  );

  // Interaction
  const [lastClick, setLastClick] = useState<{
    timeStamp: number;
    node: NodeObjectWithNeighbours<TNode>;
  } | null>();

  const handleNodeDoubleClick = (node: NodeObjectWithNeighbours<TNode>) => {
    if (node) {
      chartRef.current.zoom(5, 500);
      chartRef.current.centerAt(node.x, node.y, 500);
    }
  };

  const handleNodeClicked = (node: NodeObjectWithNeighbours<TNode> | null, event?: MouseEvent) => {
    if (
      (event?.timeStamp || 0) - (lastClick?.timeStamp || 0) < DOUBLE_CLICK_SPEED &&
      lastClick?.node === node
    ) {
      setLastClick(null);
      handleNodeDoubleClick(node);
    } else {
      setLastClick({ timeStamp: event?.timeStamp, node });
      selectNode(node, 'click');
    }
  };

  const handleBackgroundClicked = () => {
    handleNodeClicked(null);
  };

  const handleHoveredNode = useCallback(
    (node: NodeObjectWithNeighbours<TNode>) => setHoveredNode(node),
    [setHoveredNode]
  );

  const handleOverviewClick = useCallback((_: ReactMouseEvent, chartX: number, chartY: number) => {
    chartRef.current.centerAt(chartX, chartY, 100);
  }, []);

  const handleOverviewDragTo = useCallback((_: ReactMouseEvent, chartX: number, chartY: number) => {
    chartRef.current.centerAt(chartX, chartY, 0);
  }, []);

  // Rendering
  const paintNodeForClusterResource = (
    node: NodeObjectWithNeighbours<TNode>,
    canvasContext: CanvasRenderingContext2D,
    globalScale: number
  ) => {
    const boundingBox = screenBoundingBox.current;
    if (
      node.x < boundingBox.left ||
      node.x >= boundingBox.left + boundingBox.width ||
      node.y < boundingBox.top ||
      node.y >= boundingBox.top + boundingBox.height ||
      !node.nodeVisual
    ) {
      return;
    }

    const mode = !isNodeHighlighted(node) && effectiveHighlightedNodes ? 'dim' : 'normal';
    node.nodeVisual.paintNode(
      canvasContext,
      node.x,
      node.y,
      globalScale,
      mode,
      node === selectedNode
    );
  };

  const paintEdgeForClusterLink = (
    link: LinkObjectForChartView<TNode, TEdge>,
    canvasContext: CanvasRenderingContext2D,
    globalScale: number
  ) => {
    if (!link.edgeVisual) {
      return;
    }

    const linkSourceNode = link.source as NodeObjectWithNeighbours<TNode>;
    const linkTargetNode = link.target as NodeObjectWithNeighbours<TNode>;

    const [lineNodeIntersectionX, lineNodeIntersectionY] = linkTargetNode?.nodeVisual
      ? linkTargetNode?.nodeVisual.getIntersectionWithEdge(
          canvasContext,
          linkTargetNode.x,
          linkTargetNode.y,
          linkSourceNode.x,
          linkSourceNode.y,
          linkTargetNode.x,
          linkTargetNode.y,
          globalScale
        )
      : [linkTargetNode.x, linkTargetNode.y];
    link.edgeVisual.paintLink(
      canvasContext,
      linkSourceNode.x,
      linkSourceNode.y,
      lineNodeIntersectionX,
      lineNodeIntersectionY,
      globalScale,
      effectiveHighlightedNodes && !isEdgeHilighted(link) ? 'dim' : 'normal'
    );
  };

  const handlePostRenderFrame = () => {
    if (overviewRef.current) {
      overviewRef.current.refreshOverview();
    }
  };

  return (
    <>
      <SizedForceGraph2D
        maxZoom={maxZoom}
        minZoom={minZoomFactor}
        chartRef={chartRef}
        graphData={renderedGraphData}
        nodeCanvasObject={paintNodeForClusterResource}
        linkCanvasObject={paintEdgeForClusterLink}
        onNodeClick={handleNodeClicked}
        onNodeHover={handleHoveredNode}
        onBackgroundClick={handleBackgroundClicked}
        onRenderFramePre={handlePreRenderFrame}
        onRenderFramePost={handlePostRenderFrame}
        onZoomEnd={handleZoomEnd}
        cooldownTime={5000}
        onEngineStop={handleForceStop}
      />

      <ZoomControlsContainer>
        <CenteredVerticalStack>
          <ChartZoomControl
            minZoom={minZoomFactor}
            maxZoom={maxZoom}
            value={currentZoom}
            onChange={handleZoomRequest}
          />
        </CenteredVerticalStack>

        {overviewSize && (
          <OverviewContainer
            style={{
              width: overviewSize.width,
              height: overviewSize.height,
            }}>
            <GraphOverview
              ref={overviewRef}
              graphData={renderedGraphData}
              selectedNode={selectedNode}
              overviewBoundingBox={clusterBoundingBox.current}
              screenBoundingBox={screenBoundingBox.current}
              onClick={handleOverviewClick}
              onDragTo={handleOverviewDragTo}
            />
          </OverviewContainer>
        )}
      </ZoomControlsContainer>
      {nodeTooltipProps && (
        <Tooltip visible={Boolean(nodeTooltipProps.content)} content={nodeTooltipProps.content}>
          <TooltipAnchor {...nodeTooltipProps.position} />
        </Tooltip>
      )}
    </>
  );
}

const GraphOverview = styled(D3GraphOverview)`
  width: 100%;
  height: 100%;
  padding: 2rem;
  background: white;
`;

const OverviewContainer = styled.div`
  margin-left: 5rem;
  background: white;
  border-radius: 12px;
  border: solid 1px #e2e3f5;
  overflow: hidden;
`;

const ZoomControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  position: absolute;
  bottom: 6rem;
  left: 6rem;
`;

const CenteredVerticalStack = styled(VerticalStack)`
  align-items: center;
`;

const TooltipAnchor = styled.div<{ x: number; y: number }>`
  position: absolute;
  top: ${props => `${props.y}px`};
  left: ${props => `${props.x + 6}px`};
`;

function orderedRadialForce(
  radius: number,
  centerX: number,
  centerY: number,
  strength: number = 0.1
) {
  let _nodes: NodeObject[];
  let _targetPositions: { x: number; y: number }[];

  function force(alpha: number) {
    for (let i = 0, n = _nodes.length; i < n; ++i) {
      const node = _nodes[i];
      const targetPosition = _targetPositions[i];

      const dx = node.x! - targetPosition.x || 1e-6;
      const dy = node.y! - targetPosition.y || 1e-6;

      node.vx! -= Math.sign(dx) * Math.pow(Math.abs(dx) * Math.sqrt(alpha) * strength, 0.75);
      node.vy! -= Math.sign(dy) * Math.pow(Math.abs(dy) * Math.sqrt(alpha) * strength, 0.75);
    }
  }

  force.initialize = function (nodes: NodeObject[]) {
    _nodes = nodes;
    const n = nodes.length;
    _targetPositions = new Array(n);
    for (let i = 0; i < n; ++i) {
      _targetPositions[i] = {
        x: Math.cos((2 * Math.PI * i) / n) * radius + centerX,
        y: Math.sin((2 * Math.PI * i) / n) * radius + centerY,
      };
    }
  };

  return force;
}
