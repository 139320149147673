import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SplitScreen } from '@src-v2/components/layout/split-screen';
import { useToggle } from '@src-v2/hooks';
import { ChartView } from '@src/cluster-map-work/components/charts/chart-view';

export function ChartViewWithPropertiesPane({
  chartData,
  nodeVisualsFactory,
  linkVisualsFactory,
  externalSelectedNode,
  initialShowProperties,
  highlightsList,
  overviewSize,
  maxZoom = 8,
  onNodeSelected,
  onCreatePropertiesPane,
  graphLayoutMode,
  onGraphLayoutModeChanged,
}) {
  const [propertiesPane, setPropertiesPane] = useState(null);
  const [showProperties, toggleShowProperties] = useToggle(initialShowProperties);

  const handleNodeSelection = useCallback(
    (node, reason) => {
      onNodeSelected?.(node, reason);
      setPropertiesPane(
        onCreatePropertiesPane !== null ? onCreatePropertiesPane(node, toggleShowProperties) : null
      );

      if (reason === 'click' && ((node && !showProperties) || (!node && showProperties))) {
        toggleShowProperties();
      }
    },
    [
      onNodeSelected,
      setPropertiesPane,
      onCreatePropertiesPane,
      toggleShowProperties,
      showProperties,
    ]
  );

  return (
    <ChartAndPropertiesPaneContainer>
      <SplitScreen asideOpen={showProperties} defaultWidth="120rem">
        <ChartView
          chartData={chartData}
          nodeVisualsFactory={nodeVisualsFactory}
          linkVisualsFactory={linkVisualsFactory}
          externalSelectedNode={externalSelectedNode}
          initialShowProperties={initialShowProperties}
          highlightsList={highlightsList}
          overviewSize={overviewSize}
          maxZoom={maxZoom}
          graphLayoutMode={graphLayoutMode}
          onGraphLayoutModeChanged={onGraphLayoutModeChanged}
          onNodeSelected={handleNodeSelection}
        />

        <SplitScreen.Aside>{propertiesPane}</SplitScreen.Aside>
      </SplitScreen>
    </ChartAndPropertiesPaneContainer>
  );
}

const ChartAndPropertiesPaneContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
`;
