import { add } from 'date-fns';
import { useCallback } from 'react';
import styled from 'styled-components';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { SimpleSelect } from '@src-v2/containers/simple-select';

export const ExtensionSelect = styled(({ onChange, ...props }) => {
  const handleSelect = useCallback(
    ({ value }) => {
      onChange(add(new Date(), { [value.timeUnit]: value.duration }));
    },
    [onChange]
  );

  return (
    <SimpleSelect
      {...props}
      variant={Variant.FILTER}
      options={extensionOptions}
      identity={({ label }) => label}
      onSelect={handleSelect}
      popover={ExtensionPopover}
    />
  );
})`
  width: 73rem;
  height: 9rem;
  border-radius: 2rem;
  margin-left: 0;
`;

const ExtensionPopover = styled(DropdownMenu.Popover)`
  width: 73rem;
`;

const extensionOptions = [
  { label: '30 Days', value: { duration: 30, timeUnit: 'days' } },
  { label: '1 Year', value: { duration: 12, timeUnit: 'months' } },
];
