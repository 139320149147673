import * as chartIcons from '@src-v2/assets/chart-icons';

const svgImagesCache: Record<string, Promise<HTMLImageElement>> = {};

export function getCachedSvg(iconUrl: string): Promise<HTMLImageElement> {
  const entry = svgImagesCache[iconUrl];
  if (entry) {
    return entry;
  }

  const imagePromise = new Promise<HTMLImageElement>(resolve => {
    const image = document.createElement('img');
    image.onload = () => resolve(image);
    image.src = iconUrl;
  });

  svgImagesCache[iconUrl] = imagePromise;
  return imagePromise;
}

export function getChartIcon(name: string) {
  const icon = (chartIcons as Record<string, string>)[name];
  if (!icon) {
    console.info(`ChartIcon "${name}" not found`);
  }

  return icon;
}
