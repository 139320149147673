import styled from 'styled-components';
import { LinkMode, TextButton } from '@src-v2/components/button-v2';
import { ControlledCard, ControlledCardProps } from '@src-v2/components/cards/controlled-card';
import { EvidenceLine } from '@src-v2/components/entity-pane/evidence/evidence-line';
import { SvgIcon } from '@src-v2/components/icons';
import { Size } from '@src-v2/components/types/enums/size';
import { CardContentWrapper } from '@src-v2/containers/pages/artifacts/artifact-pane/components/common';
import { ImageVersionComponent } from '@src-v2/containers/risks/risk-image-version-component';
import { Finding } from '@src-v2/types/inventory-elements/lightweight-finding-response';
import { stopPropagation } from '@src-v2/utils/dom-utils';
import { makeUrl } from '@src-v2/utils/history-utils';

export const AboutAffectedAssetCard = ({
  finding,
  artifactKey,
  ...props
}: {
  finding: Partial<Finding>;
  artifactKey: string;
} & ControlledCardProps) => {
  const { packageName, packageVersions, artifactName, artifactImageIdentifications } = finding;

  return (
    <ControlledCard {...props} title="Affected assets">
      <CardContentWrapper>
        <>
          {packageName && (
            <EvidenceLine isExtendedWidth label="Dependency">
              {packageName}
              {packageVersions.length > 0 ? `: ${packageVersions[0].trimEnd()}` : ''}
            </EvidenceLine>
          )}
          {Boolean(artifactName) && (
            <EvidenceLine isExtendedWidth label="Container image">
              <IconWrapper name="ContainerImage" size={Size.XSMALL} />
              <ContainerImageButton
                disabled={Boolean(!artifactKey)}
                mode={LinkMode.INTERNAL}
                size={Size.XSMALL}
                onClick={stopPropagation}
                to={makeUrl(`/inventory/artifacts/${artifactKey}/risks`, {})}>
                {artifactName}
              </ContainerImageButton>
            </EvidenceLine>
          )}
          {Boolean(artifactImageIdentifications?.length) && (
            <EvidenceLine isExtendedWidth label="Version identifiers">
              <ImageVersionComponent data={finding} />
            </EvidenceLine>
          )}
        </>
      </CardContentWrapper>
    </ControlledCard>
  );
};

const ContainerImageButton = styled(TextButton)`
  cursor: ${props => (props.underline ? 'pointer' : 'default')};
`;

const IconWrapper = styled(SvgIcon)`
  color: var(--color-blue-gray-50);
`;
