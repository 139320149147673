import { MouseEvent, useCallback } from 'react';
import styled from 'styled-components';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { Table } from '@src-v2/components/table/table';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { LearningButton } from '@src-v2/containers/learning-stats';
import { HelpModal } from '@src-v2/containers/modals/help-modal';
import { useInject } from '@src-v2/hooks';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { RiskLevel } from '@src-v2/types/enums/risk-level';
import { ScanStatus } from '@src-v2/types/enums/scan-status';

export const PullRequestRiskCell = ({
  pullRequestId,
  scanStatus,
  riskLevel = RiskLevel.None,
  ...props
}: {
  pullRequestId: number;
  scanStatus: ScanStatus;
  riskLevel: RiskLevel;
}) => {
  const { session } = useInject();
  const [modalElement, setModal, closeModal] = useModalState();

  const handleContactHelpClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setModal(<HelpModal subject={`PR Scan failed - PR #${pullRequestId}`} onClose={closeModal} />);
  }, []);

  return (
    <Cell {...props}>
      {scanStatus === ScanStatus.Pending || scanStatus === ScanStatus.InProgress ? (
        <LearningButton.Animation size={3} width={0.75} margin={2} />
      ) : scanStatus === ScanStatus.Diffed ? (
        <RiskIcon riskLevel={riskLevel} />
      ) : (
        <Tooltip
          interactive
          content={
            <>
              Pull request scanning failed.
              <br />
              If the issue persist{' '}
              {!session.data.jiraServiceDeskEnabled ? (
                <ContactApiiroButton onClick={handleContactHelpClick}>
                  contact
                  <br />
                  Apiiro support
                </ContactApiiroButton>
              ) : (
                'contact your Apiiro admin'
              )}
            </>
          }>
          <SvgIcon name="Report" />
        </Tooltip>
      )}
      {modalElement}
    </Cell>
  );
};

const Cell = styled(Table.CenterCell)`
  > ${BaseIcon}[data-name="Report"] {
    color: var(--color-blue-gray-50);
  }
`;

const ContactApiiroButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
