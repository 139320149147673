export function intersectLineWithRect(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  rx1: number,
  ry1: number,
  rx2: number,
  ry2: number
): [number, number] {
  const slope = (y2 - y1) / (x2 - x1);

  if (x1 < rx1) {
    const verticalEdge1IntersectPoint = slope * (rx1 - x1) + y1;
    if (verticalEdge1IntersectPoint <= ry2 && verticalEdge1IntersectPoint >= ry1) {
      return [rx1, verticalEdge1IntersectPoint];
    }
  }

  if (x1 > rx2) {
    const verticalEdge2IntersectPoint = slope * (rx2 - x1) + y1;
    if (verticalEdge2IntersectPoint <= ry2 && verticalEdge2IntersectPoint >= ry1) {
      return [rx2, verticalEdge2IntersectPoint];
    }
  }

  if (y1 < ry1) {
    const horizontalEdge1IntersectPoint = (ry1 - y1) / slope + x1;
    if (horizontalEdge1IntersectPoint <= rx2 && horizontalEdge1IntersectPoint >= rx1) {
      return [horizontalEdge1IntersectPoint, ry1];
    }
  }

  if (y1 > ry2) {
    const horizontalEdge2IntersectPoint = (ry2 - y1) / slope + x1;
    if (horizontalEdge2IntersectPoint <= rx2 && horizontalEdge2IntersectPoint >= rx1) {
      return [horizontalEdge2IntersectPoint, ry2];
    }
  }

  return [x2, y2];
}

export function intersectLineWithCircle(
  edgeX1: number,
  edgeY1: number,
  edgeX2: number,
  edgeY2: number,
  cx: number,
  cy: number,
  radius: number
): [number, number] {
  // Slope = inf
  if (Math.abs(edgeX1 - edgeX2) < 0.0001) {
    if (Math.abs(edgeX1 - cx) > 0.0001) {
      return [edgeX1, edgeY1];
    }

    const edgeMinY = Math.min(edgeY1, edgeY2);
    const edgeMaxY = Math.max(edgeY1, edgeY2);
    const circleBottomCandidate = cy + radius;
    if (circleBottomCandidate >= edgeMinY && circleBottomCandidate <= edgeMaxY) {
      return [edgeX1, circleBottomCandidate];
    }
    return [edgeX1, cy - radius];
  }

  // Calculate slope, ofs (y = slope * x + ofs)
  const slope = (edgeY2 - edgeY1) / (edgeX2 - edgeX1);
  const ofs = edgeY1 - edgeX1 * slope;

  // Intersect y = ax + b and (y-cy)^2 + (x-cx)^2 = r^2; calculate quadrant coefficients
  const qB = 2 * (slope * (ofs - cy) - cx);
  const qA = slope * slope + 1;
  const qC = (ofs - cy) * (ofs - cy) + cx * cx - radius * radius;

  const discriminant = qB * qB - 4 * qA * qC;
  if (discriminant < 0) {
    return [edgeX1, edgeY1];
  }

  const discrimnantRoot = Math.sqrt(discriminant);

  const candidate = (x: number): [number, number] | null => {
    if (x < Math.min(edgeX1, edgeX2) || x > Math.max(edgeX1, edgeX2)) {
      return null;
    }

    return [x, slope * x + ofs];
  };

  return (
    candidate((-qB + discrimnantRoot) / (2 * qA)) ||
    candidate((-qB - discrimnantRoot) / (2 * qA)) || [edgeX1, edgeY1]
  );
}

export function computeIconSize(sizeSensitivity: number, sizeFactor: number = 0, iconSize = 14) {
  return iconSize * Math.pow(2.1 * (0.5 + sizeSensitivity), sizeFactor);
}
