import { ReactNode } from 'react';

export abstract class ChartVisuals {
  public abstract nodeSize(ctx: CanvasRenderingContext2D, scale: number): { w: number; h: number };

  public abstract paintNode(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    scale: number,
    hilight: 'hilight' | 'normal' | 'dim',
    selected: boolean
  ): void;

  public abstract paintNodeInteractionArea(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    scale: number,
    color: string
  ): void;

  public abstract getIntersectionWithEdge(
    ctx: CanvasRenderingContext2D,
    nodeX: number,
    nodeY: number,
    edgeX1: number,
    edgeY1: number,
    edgeX2: number,
    edgeY2: number,
    scale: number
  ): [number, number];

  public abstract getDescriptionPopover(): ReactNode;
}

export abstract class ChartLinkVisual {
  public abstract paintLink(
    ctx: CanvasRenderingContext2D,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    scale: number,
    hilight: 'hilight' | 'normal' | 'dim'
  ): void;
}
