export function strokeArrow(
  ctx: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  startColor: string,
  endColor?: string
) {
  ctx.save();

  const angle = Math.atan2(y2 - y1, x2 - x1);
  const length = Math.sqrt((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1));

  if (!endColor) {
    ctx.fillStyle = startColor;
    ctx.strokeStyle = startColor;
  } else {
    const grad = ctx.createLinearGradient(0, 0, length - 1, 0);
    grad.addColorStop(0, startColor);
    grad.addColorStop(1, endColor);
    ctx.strokeStyle = grad;
    ctx.fillStyle = endColor;
  }

  ctx.translate(x1, y1);
  ctx.rotate(angle);

  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(length - 1, 0);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(length - 3, -2);
  ctx.lineTo(length - 3, 2);
  ctx.lineTo(length, 0);
  ctx.fill();
  ctx.restore();
}
