import styled from 'styled-components';
import bullsEyeUrl from '@src-v2/assets/images/empty-state/bulls-eye.svg';
import { GeneralErrorLayout } from '@src-v2/components/layout/error-layout';
import { ExternalLink } from '@src-v2/components/typography';
import { StyledProps } from '@src-v2/types/styled';

export const OopsSomethingWrongLayout = () => {
  return (
    <GeneralErrorLayout
      image={bullsEyeUrl}
      title="Oops! Something went wrong"
      description={
        <>
          <GeneralErrorLayout.Text>You can refresh or try again later</GeneralErrorLayout.Text>
          <GeneralErrorLayout.Text>
            Having issues?{' '}
            <ExternalLink href="https://apiiro.atlassian.net/servicedesk/customer/portals">
              Contact our support
            </ExternalLink>
          </GeneralErrorLayout.Text>
        </>
      }
    />
  );
};

export const TilesOopsSomethingWrongLayout = styled((props: StyledProps) => {
  return (
    <GeneralErrorLayout
      {...props}
      image={bullsEyeUrl}
      title="Oops! Something went wrong"
      description="You can refresh or try again later"
    />
  );
})`
  height: 100%;
  align-items: flex-start;
  margin-top: 4rem;

  ${GeneralErrorLayout.Container} {
    gap: 0;
  }

  ${GeneralErrorLayout.Title} {
    font-size: var(--font-size-s);
    font-weight: 600;
    line-height: 5rem;
  }

  ${GeneralErrorLayout.Description} {
    font-size: var(--font-size-xs);
    font-weight: 300;
    line-height: 4rem;
  }
`;
