export { default as Api } from './api.svg';
export { default as Cloud } from './cloud.svg';
export { default as Comm } from './communication.svg';
export { default as DataModel } from './datamodel.svg';
export { default as Datastore } from './datastore.svg';
export { default as Dependency } from './dependency.svg';
export { default as Endpoint } from './endpoint.svg';
export { default as GenAi } from './gen_ai.svg';
export { default as Generic } from './generic.svg';
export { default as Kms } from './kms.svg';
export { default as Logging } from './logging.svg';
export { default as MessageQueue } from './message_queue.svg';
export { default as Module } from './module.svg';
export { default as Monitoring } from './monitoring.svg';
export { default as Payment } from './payment.svg';
export { default as Repository } from './repository.svg';
export { default as Serialization } from './serialization.svg';
