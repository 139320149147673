import _ from 'lodash';
import { observer } from 'mobx-react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AnalyticsDataField, AnalyticsLayer } from '@src-v2/components/analytics-layer';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { ElementSeparator } from '@src-v2/components/element-separator';
import { FiltersMenu } from '@src-v2/components/filters/menu-control/filters-menu';
import { SearchFilterInput } from '@src-v2/components/forms/search-input';
import { InfiniteScroll } from '@src-v2/components/infinite-scroll';
import { ErrorLayout, Gutters } from '@src-v2/components/layout';
import { ApplicationGroupFirstTimeLayout } from '@src-v2/components/layout/first-time-layouts/application-group-first-time-layout';
import { ApplicationFirstTimeLayout } from '@src-v2/components/layout/first-time-layouts/applications-first-time-layout';
import { Page } from '@src-v2/components/layout/page';
import { StickyHeader } from '@src-v2/components/layout/sticky-header';
import { SortOptionsSelect } from '@src-v2/components/persistent-search-state/sort-options-select';
import { SwitchButtonByRoute } from '@src-v2/components/switch-button-by-route';
import { TableControls } from '@src-v2/components/table/table-addons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { ApplicationsFunnel } from '@src-v2/containers/applications/funnel/applications-funnel';
import { RepositoriesFunnel } from '@src-v2/containers/repositpories/funnel/repositories-funnel';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject, useQueryParams, useSuspense } from '@src-v2/hooks';
import { useFilterSearch, useFilters } from '@src-v2/hooks/use-filters';
import { usePersistentSearchState } from '@src-v2/hooks/use-search-state';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { abbreviate, formatNumber } from '@src-v2/utils/number-utils';
import Consumables from '@src/blocks/ConsumableInfiniteScroll/blocks/Consumables';
import profilesService from '@src/services/profilesService';

export function SearchPage({
  profileType,
  assetCollectionCount,
  getOrganizationProfileAsync,
  title = 'applications',
}) {
  const { rbac, application, profiles, applicationProfilesV2 } = useInject();

  useEffect(() => {
    getOrganizationProfileAsync();
  }, [getOrganizationProfileAsync]);

  const navigation = profileType !== 'repositories' && [
    { label: 'Applications', to: '/profiles/applications' },
    { label: 'Application Groups', to: '/profiles/groups' },
  ];

  const [applicationsSearchState, applicationGroupsSearchState] = useSuspense([
    [applicationProfilesV2.searchProfiles, {}],
    [
      profiles.searchProfiles,
      {
        profileType: 'applicationGroups',
      },
    ],
  ]);

  return (
    <Page title={title}>
      {profileType !== 'repositories' && applicationsSearchState.total === 0 ? (
        <ApplicationFirstTimeLayout />
      ) : (
        <>
          <StickyHeader navigation={navigation}>
            {profileType !== 'repositories' && (
              <SwitchButtonByRoute
                assetCollectionCount={assetCollectionCount}
                applicationGroupsCount={applicationGroupsSearchState?.count}
                disabled={!rbac.canEdit(resourceTypes.Products) || !rbac.hasGlobalScopeAccess}
              />
            )}
          </StickyHeader>
          {application.isFeatureEnabled(FeatureFlag.ProfileInsightFilter) &&
            application.isFeatureEnabled(FeatureFlag.SearchPageFunnel) &&
            ((profileType === 'assetCollections' && <ApplicationsFunnel />) ||
              (profileType === 'repositories' && <RepositoriesFunnel />))}

          {profileType === 'applicationGroups' && applicationGroupsSearchState?.count === 0 ? (
            <ApplicationGroupFirstTimeLayout />
          ) : (
            <AsyncBoundary>
              <SearchContent
                key={profileType}
                profileType={profileType}
                assetCollectionCount={assetCollectionCount}
                itemName={title}
              />
            </AsyncBoundary>
          )}
        </>
      )}
    </Page>
  );
}

export const SearchContent = observer(function SearchContent({
  profileType,
  assetCollectionCount,
  itemName,
}) {
  const { profiles, rbac } = useInject();
  const [filterGroups, sortOptions] = useSuspense([
    [profiles.getFilterOptions, { profileType }],
    [profiles.getSortOptions, { profileType }],
  ]);

  const {
    activeOptions: filterOptions,
    allOptions,
    handleFiltersSearch,
    resetFiltersSearch,
  } = useFilterSearch(filterGroups);

  const { activeFilters, updateFilters, removeFilters } = useFilters();
  const activeFiltersCount = _.sumBy(
    Object.values(activeFilters)
      .map(activeFilter => activeFilter.values)
      .filter(Array.isArray),
    'length'
  );

  const canEditProducts = rbac.hasGlobalScopeAccess && rbac.canEdit(resourceTypes.Products);
  const { profileTypeDisplayName } = profilesService.getProfileDefinition(profileType);

  const [searchCounters, setSearchCounters] = useState({ count: null, total: null });

  const handleSearchStateChanged = useCallback(
    searchState => {
      setSearchCounters({ count: searchState.count, total: searchState.total });
    },
    [setSearchCounters]
  );

  return (
    <AnalyticsLayer analyticsData={{ [AnalyticsDataField.Context]: profileTypeDisplayName }}>
      <Gutters>
        <TableControls>
          <SearchFilterInput
            defaultValue={activeFilters?.searchTerm}
            placeholder={`Search by ${profileTypeDisplayName} name`}
          />

          <TableControls.Actions>
            <ElementSeparator as="div">
              <ResultsCounter
                count={searchCounters.count}
                total={searchCounters.total}
                itemName={itemName}
              />
              {activeFiltersCount > 0 && (
                <Tooltip
                  content={
                    <ActiveFiltersList activeFilters={activeFilters} filterOptions={filterGroups} />
                  }
                  placement="bottom">
                  <span>{activeFiltersCount} Filters applied</span>
                </Tooltip>
              )}
            </ElementSeparator>
            {sortOptions.length > 1 && <SortOptionsSelect sortOptions={sortOptions} />}
            {filterGroups.length > 0 && (
              <FiltersMenu
                data={filterOptions}
                activeValues={activeFilters}
                autoOpenCount={filterGroups === filterOptions ? 3 : Infinity}
                onChange={updateFilters}
                onSearch={handleFiltersSearch}
                onClose={resetFiltersSearch}
                onSave={params => profiles.saveCustomFilter({ ...params, profileType })}
                onRemove={key => profiles.deleteCustomFilter({ profileType, key })}
                onClear={removeFilters}
                controlOperator
              />
            )}
          </TableControls.Actions>
        </TableControls>
        <AsyncBoundary>
          <ConsumablesInfiniteScroll
            profileType={profileType}
            assetCollectionCount={assetCollectionCount}
            filterOptions={allOptions}
            canEditProducts={canEditProducts}
            onSearchStateChanged={handleSearchStateChanged}
          />
        </AsyncBoundary>
      </Gutters>
    </AnalyticsLayer>
  );
});

const ConsumablesInfiniteScroll = observer(
  ({ profileType, filterOptions, assetCollectionCount, canEditProducts, onSearchStateChanged }) => {
    const { queryParams } = useQueryParams();
    const { activeFilters } = useFilters();
    const { profiles } = useInject();

    const sortOptions = useSuspense(profiles.getSortOptions, { profileType });

    const { searchTerm, operator, ...filters } = activeFilters;
    const searchState = usePersistentSearchState(profiles.searchProfiles, {
      sort: queryParams.sort ?? sortOptions[0]?.key,
      profileType,
      searchTerm,
      operator,
      filters,
    });

    useEffect(() => {
      onSearchStateChanged(searchState);
    }, [searchState?.count, searchState?.items]);

    return (
      <InfiniteScroll
        disabled={searchState.items.length === searchState.count}
        searchState={searchState}>
        {searchState.count === 0 ? (
          <EmptyContainer>
            {profileType === 'assetCollections' && _.isEmpty(activeFilters) ? (
              <>
                <Paragraph>No applications configured, click to create</Paragraph>
                <SwitchButtonByRoute
                  disabled={!canEditProducts}
                  assetCollectionCount={assetCollectionCount}
                />
              </>
            ) : (
              <ErrorLayout.NoResults />
            )}
          </EmptyContainer>
        ) : (
          <Consumables
            name={profileType}
            profiles={searchState.items}
            filterOptions={filterOptions}
          />
        )}
      </InfiniteScroll>
    );
  }
);

function ResultsCounter({ count, total, itemName }) {
  return count === total
    ? `${abbreviate(count)} ${itemName}`
    : `${formatNumber(count)} out of ${abbreviate(total)} ${itemName.toLowerCase()}`;
}

function ActiveFiltersList({ activeFilters, filterOptions }) {
  const titlesMap = useMemo(
    () =>
      new Map(
        filterOptions.flatMap(({ key, title, options }) =>
          [[key, title]].concat(options.map(({ value, title }) => [[key, value].join(), title]))
        )
      ),
    [filterOptions]
  );

  // only display array values; exclude searchTerm & operator
  return Object.entries(activeFilters).map(
    ([key, { values }]) =>
      Array.isArray(values) && (
        <Fragment key={key}>
          <Heading>{titlesMap.get(key)}</Heading>
          {values.map(value => (
            <Paragraph key={value}>{titlesMap.get([key, value].join())}</Paragraph>
          ))}
        </Fragment>
      )
  );
}

const EmptyContainer = styled.div`
  text-align: center;
`;
