export enum CodeFrameworkType {
  InputValidation = 'Input Validation',
  Authorization = 'Authorization',
  Authentication = 'Authentication',
  MultiFactorAuthentication = 'MultiFactorAuthentication',
  SessionManagement = 'Session Management',
  Encryption = 'Encryption',
  Logging = 'Logging',
  Monitoring = 'Monitoring',
  DataStorage = 'Data Store Connection',
  DataAccess = 'Generic Data Store Access',
  Aws = 'AWS',
  Gcp = 'GCP',
  Azure = 'Azure',
  Ui = 'UI',
  ApiServer = 'Web Server',
  ApiClient = 'Web Client',
  CiCd = 'CI/CD',
  Containers = 'Containers',
  InfraAsCode = 'Infra-as-Code',
  KeyManagementService = 'Key Management',
  Serialization = 'Data Serialization',
  Rpc = 'RPC',
  Runtime = 'Runtime',
  Utility = 'Utility',
  Language = 'Language',
  Test = 'Test',
  ApiGateway = 'API Gateway',
  CloudCompute = 'Cloud Compute',
  CloudStorage = 'Cloud Storage',
  CloudCodeBase = 'Cloud Provisioned Data Store',
  AppEngine = 'Cloud App Engine',
  Cdn = 'Content Delivery Network (CDN)',
  Firewall = 'Cloud Network Firewall',
  VirtualNetwork = 'Cloud Virtual Network',
  WebApplicationFirewall = 'Cloud Web Application Firewall',
  MessageQueue = 'Message Queue',
  GenerativeAi = 'Generative AI',
  Payments = 'Payments',
  Messaging = 'Messaging',
  Marketing = 'Marketing',
  Ticketing = 'Ticketing',
  CustomerEngagementPlatform = 'Customer Engagement Platform',
  AIMLModels = 'AI/ML models',
  AIMLDatasets = 'AI/ML datasets',
}
