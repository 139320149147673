import styled from 'styled-components';
import { LinkMode, TextButton } from '@src-v2/components/button-v2';
import { Card } from '@src-v2/components/cards';
import { SvgIcon } from '@src-v2/components/icons';
import { Size } from '@src-v2/components/types/enums/size';
import { Heading5 } from '@src-v2/components/typography';
import { InventoryObjectType } from '@src-v2/containers/inventory-overview/inventory-object';
import { TrendBadge } from '@src-v2/containers/inventory-overview/trend-badge';
import { calcChangePercentage } from '@src-v2/containers/inventory-overview/utils';

export enum BackdropColors {
  Blue = 'blue',
  Glue = 'glue',
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Purple = 'purple',
  Pink = 'pink',
  Orange = 'Orange',
}

export interface TrendCardProps {
  title: string;
  startValue: number;
  endValue: number;
  color: BackdropColors;
  name: InventoryObjectType;
  to: string;
  timeFilterInsensitive: boolean;
  connectUrl: string;
}

export const TrendCard = ({
  title,
  startValue,
  endValue,
  color,
  name,
  to,
  timeFilterInsensitive,
  connectUrl,
}: TrendCardProps) => {
  const percentageChange = calcChangePercentage(startValue, endValue);
  const diff = endValue - startValue;
  const numberChange = startValue === endValue ? 'Steady' : diff > 0 ? `+${diff}` : diff;
  const hasDataToDisplay = startValue !== undefined;

  return (
    <Card>
      <IconBackdrop color={color} name={name} />
      <Heading5>
        {title}
        <TextButton
          mode={LinkMode.INTERNAL}
          size={Size.XXSMALL}
          to={hasDataToDisplay ? to : connectUrl}
          showArrow={!hasDataToDisplay}
          underline={!hasDataToDisplay}>
          {hasDataToDisplay ? `${endValue.toLocaleString()} (${numberChange})` : <>Connect</>}
        </TextButton>
      </Heading5>
      {!timeFilterInsensitive && hasDataToDisplay && <TrendBadge percentage={percentageChange} />}
    </Card>
  );
};

const IconBackdrop = styled(
  ({ color, name, ...props }: { color: BackdropColors; name: string }) => (
    <div {...props} data-color={color}>
      <SvgIcon name={name} size={Size.SMALL} />
    </div>
  )
)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  flex-shrink: 0;
  border-radius: 1rem;

  &[data-color=${BackdropColors.Orange}] {
    background-color: var(--color-orange-20);
    color: var(--color-orange-60);
  }
  &[data-color=${BackdropColors.Purple}] {
    background-color: var(--color-purple-20);
    color: var(--color-purple-50);
  }
  &[data-color=${BackdropColors.Green}] {
    background-color: var(--color-green-20);
    color: var(--color-green-60);
  }
  &[data-color=${BackdropColors.Pink}] {
    background-color: var(--color-pink-20);
    color: var(--color-pink-55);
  }
  &[data-color=${BackdropColors.Red}] {
    background-color: var(--color-red-20);
    color: var(--color-red-60);
  }
  &[data-color=${BackdropColors.Blue}] {
    background-color: var(--color-blue-25);
    color: var(--color-blue-70);
  }
  &[data-color=${BackdropColors.Yellow}] {
    background-color: var(--color-yellow-20);
    color: var(--color-yellow-60);
  }
  &[data-color=${BackdropColors.Glue}] {
    background-color: var(--color-blue-gray-20);
    color: var(--color-blue-gray-60);
  }
`;
