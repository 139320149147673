import {
  CSSProperties,
  MouseEvent,
  MutableRefObject,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import ForceGraph2D, {
  ForceGraphMethods,
  ForceGraphProps,
  LinkObject,
  NodeObject,
} from 'react-force-graph-2d';
import styled from 'styled-components';
import { SearchInput } from '@src-v2/components/forms/search-input';
import { Link } from '@src-v2/components/typography';
import { useResizeObserver } from '@src-v2/hooks';
import { assignStyledNodes } from '@src-v2/types/styled';
import {
  NodeObjectWithNeighbours,
  RenderedChartData,
  TEdgeBase,
  TNodeBase,
} from '@src/cluster-map-work/components/charts/chart-view';
import { MultiSelect } from '@src/cluster-map-work/components/multi-select';

export type D3GraphOverviewMethods = {
  refreshOverview(): void;
};

type D3GraphOverviewBaseProps<TNode extends TNodeBase, TEdge extends TEdgeBase> = {
  graphData: RenderedChartData<TNode, TEdge>;
  overviewBoundingBox: { top: number; left: number; width: number; height: number };
  screenBoundingBox: { top: number; left: number; width: number; height: number };
  style?: CSSProperties;
  selectedNode: NodeObjectWithNeighbours<TNode>;
  className?: string;
  onClick?: (event: MouseEvent, chartX: number, chartY: number) => void;
  onDragTo?: (event: MouseEvent, chartX: number, chartY: number) => void;
  background?: string;
  foreground?: string;
  selectedNodeForeground?: string;
  viewportBackground?: string;
  viewportBorder?: string;
};
const D3GraphOverviewBase = <TNode extends TNodeBase, TEdge extends TEdgeBase>(
  {
    graphData,
    overviewBoundingBox,
    screenBoundingBox,
    style,
    selectedNode,
    className,
    onClick,
    onDragTo,
    background = 'white',
    foreground = '#B6B9C9',
    selectedNodeForeground = '#012B70',
    viewportBackground = '#D5E2F4',
    viewportBorder = '#D5E2F4',
  }: D3GraphOverviewBaseProps<TNode, TEdge>,
  ref: MutableRefObject<D3GraphOverviewMethods>
) => {
  const canvasRef = useRef<HTMLCanvasElement>();
  const canvasVWidth = 200;
  const canvasVHeight = 200;

  useImperativeHandle(ref, () => ({
    refreshOverview,
  }));

  const toOverviewCoordinate = useCallback(
    (x: number, y: number) => {
      return [
        ((x - overviewBoundingBox.left) / overviewBoundingBox.width) * canvasVWidth,
        ((y - overviewBoundingBox.top) / overviewBoundingBox.width) * canvasVHeight,
      ];
    },
    [overviewBoundingBox]
  );

  const clientToChartCoordinate = useCallback(
    (clientX: number, clientY: number) => {
      const clientRect = canvasRef.current.getBoundingClientRect();

      const [canvasRelativeX, canvasRelativeY] = [
        (clientX - clientRect.left) / clientRect.width,
        (clientY - clientRect.top) / clientRect.height,
      ];

      return [
        overviewBoundingBox.left + canvasRelativeX * overviewBoundingBox.width,
        overviewBoundingBox.top + canvasRelativeY * overviewBoundingBox.height,
      ];
    },
    [overviewBoundingBox, canvasRef.current]
  );

  const toOverviewSize = useCallback(
    (w: number, h: number) => {
      return [
        (w / overviewBoundingBox.width) * canvasVWidth,
        (h / overviewBoundingBox.width) * canvasVHeight,
      ];
    },
    [overviewBoundingBox]
  );

  const refreshOverview = () => {
    const canvasContext = canvasRef.current.getContext('2d');

    canvasContext.fillStyle = background;
    canvasContext.fillRect(0, 0, canvasVWidth, canvasVHeight);

    canvasContext.fillStyle = viewportBackground;
    canvasContext.strokeStyle = viewportBorder;
    const [viewPortX, viewPortY] = toOverviewCoordinate(
      screenBoundingBox.left,
      screenBoundingBox.top
    );
    const [viewPortWidth, viewPortHeight] = toOverviewSize(
      screenBoundingBox.width,
      screenBoundingBox.height
    );
    canvasContext.fillRect(viewPortX, viewPortY, viewPortWidth, viewPortHeight);
    canvasContext.strokeRect(viewPortX, viewPortY, viewPortWidth, viewPortHeight);

    const drawNode = (node: NodeObjectWithNeighbours<TNode>) => {
      canvasContext.beginPath();
      const [cx, cy] = toOverviewCoordinate(node.x, node.y);
      canvasContext.ellipse(cx, cy, 3, 3, 0, 0, 2 * Math.PI);
      canvasContext.fill();
    };

    canvasContext.fillStyle = foreground;
    graphData.nodes.forEach(drawNode);

    if (selectedNode) {
      canvasContext.fillStyle = selectedNodeForeground;
      drawNode(selectedNode);
    }
  };

  const handleCanvasClick = useCallback((event: MouseEvent<HTMLCanvasElement>) => {
    if (onClick) {
      const [chartCoordinateX, chartCoordinateY] = clientToChartCoordinate(
        event.clientX,
        event.clientY
      );

      onClick(event, chartCoordinateX, chartCoordinateY);
    }
  }, []);

  const handleCanvasMove = useCallback((event: MouseEvent<HTMLCanvasElement>) => {
    if (event.buttons & 1 && onDragTo) {
      const [chartCoordinateX, chartCoordinateY] = clientToChartCoordinate(
        event.clientX,
        event.clientY
      );

      onDragTo(event, chartCoordinateX, chartCoordinateY);
    }
  }, []);

  return (
    <canvas
      className={className}
      height={canvasVWidth}
      width={canvasVHeight}
      style={style}
      ref={canvasRef}
      onClick={handleCanvasClick}
      onMouseMove={handleCanvasMove}
    />
  );
};

export const D3GraphOverview = forwardRef(D3GraphOverviewBase);

export function SizedForceGraph2D<NodeType, LinkType>(
  props: ForceGraphProps<NodeObject<NodeType>, LinkObject<NodeType, LinkType>> & {
    chartRef: MutableRefObject<
      ForceGraphMethods<NodeObject<NodeType>, LinkObject<NodeType, LinkType>>
    >;
  }
) {
  const ref = useRef(null);
  const [{ width, height }, setDimensions] = useState({ width: 0, height: 0 });

  useResizeObserver(
    ref,
    useCallback(({ contentRect }: { contentRect: DOMRect }) => {
      if (contentRect.width !== width || contentRect.height !== height) {
        setDimensions(contentRect);
      }
    }, [])
  );

  return (
    <SizedForceGraph2DContainer ref={ref}>
      <ForceGraph2D width={width} height={height} {...props} ref={props.chartRef} />
    </SizedForceGraph2DContainer>
  );
}

export const InChartHeaderControls = assignStyledNodes(
  styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;

    top: 0;
    left: 0;

    width: 100%;
  `,
  {
    SearchHeader: styled.div`
      display: flex;

      flex-direction: row;
      align-items: center;

      padding: 6rem 7rem;
      gap: 3rem;

      background: linear-gradient(
        180deg,
        #fbfbfe 0%,
        rgba(251, 251, 254, 0.96) 21.35%,
        rgba(251, 251, 254, 0.96) 75%,
        rgba(251, 251, 254, 0) 100%
      );
    `,

    SearchBox: styled(SearchInput)`
      margin-right: 3rem;
      width: 90rem;
    `,

    MultiSelectFilter,
  }
);

type MultiSelectFilterProps = {
  filterOptions: any;
  onChange: (filterOptions: any) => void;
};

function MultiSelectFilter({ filterOptions, onChange }: MultiSelectFilterProps) {
  const handleComponentUpdate = useCallback(
    (index: number, newComponent: any) => {
      const newFilterOptions = [...filterOptions];
      newFilterOptions[index] = newComponent;

      onChange(newFilterOptions);
    },
    [onChange, filterOptions]
  );

  const handleClearFilters = useCallback(() => {
    const newFilterOptions = filterOptions.map((filterComponent: any) => ({
      ...filterComponent,
      options: filterComponent.options.map((filterOption: any) => ({
        ...filterOption,
        isSelected: false,
      })),
    }));
    onChange(newFilterOptions);
  }, [onChange, filterOptions]);

  const anySelected = useMemo(
    () =>
      Boolean(
        filterOptions.find((filterComponent: any) =>
          filterComponent.options.find((option: any) => option.isSelected)
        )
      ),
    [filterOptions]
  );

  return (
    <MultiSelectFilterContainer>
      {filterOptions.map((filterComponent: any, index: number) => (
        <MultiSelectFilterSingleFilterDropdown
          key={`filter${index}`}
          filterComponent={filterComponent}
          onChange={newComponent => handleComponentUpdate(index, newComponent)}
        />
      ))}
      {anySelected && (
        <StyledLink to="#" onClick={handleClearFilters}>
          Clear filters
        </StyledLink>
      )}
    </MultiSelectFilterContainer>
  );
}

type MultiSelectFilterSingleFilterDropdownProps = {
  filterComponent: any;
  onChange: (filterComponent: any) => void;
};

function MultiSelectFilterSingleFilterDropdown({
  filterComponent,
  onChange,
}: MultiSelectFilterSingleFilterDropdownProps) {
  const selected = useMemo(
    () => new Set(filterComponent.options.filter((option: any) => option.isSelected)),
    [filterComponent]
  );

  const handleSelectionChange = useCallback(
    (newSelection: any) => {
      const newFilterComponent = {
        ...filterComponent,
        options: filterComponent.options.map((option: any) => ({
          ...option,
          isSelected: newSelection.has(option),
        })),
      };

      onChange(newFilterComponent);
    },
    [filterComponent, onChange]
  );

  const placeholder = useMemo(() => {
    return selected.size === 0
      ? filterComponent.componentDisplayName
      : selected.size === 1
        ? `${filterComponent.componentDisplayName}: ${selected.values().next().value.displayName}`
        : `${filterComponent.componentDisplayName}: ${selected.size} selected`;
  }, [filterComponent]);

  return (
    <MultiSelect
      options={filterComponent.options as any[]}
      value={selected}
      placeholder={placeholder}
      identity={(option?: any) => option?.displayName}
      onChange={handleSelectionChange}
    />
  );
}

const MultiSelectFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-right: 3rem;
`;

const SizedForceGraph2DContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-weight: 600;
`;
