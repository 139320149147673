import { resourceTypes } from '@src-v2/data/rbac-types';
import ioc from '@src-v2/ioc';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';

export const getSettingsNavigationOptions = () => [
  {
    key: 'general',
    label: 'GENERAL',
    items: [
      {
        key: 'sla',
        label: 'SLA',
        route: '/settings/sla',
        hidden: !ioc.rbac.canEdit(resourceTypes.Global),
      },
      {
        key: 'risk-score',
        label: 'Risk score',
        route: '/settings/risk-score',
        hidden:
          !ioc.application.isFeatureEnabled(FeatureFlag.RiskScore) ||
          !ioc.rbac.canEdit(resourceTypes.Global),
      },
      {
        key: 'sca-configuration',
        label: 'SCA preferences',
        route: '/settings/sca-configuration',
        hidden:
          !ioc.application.isFeatureEnabled(FeatureFlag.ScaProviderOrder) ||
          !ioc.rbac.canEdit(resourceTypes.Global),
      },
      {
        key: 'monitor-design-risks',
        label: 'Monitor design risks',
        route: '/settings/monitor-design-risks',
        hidden: !ioc.rbac.canEdit(resourceTypes.Global),
      },
      {
        key: 'managed-semgrep',
        label: 'Managed Semgrep',
        route: '/settings/managed-semgrep',
        hidden:
          !ioc.application.isFeatureEnabled(FeatureFlag.SemgrepUIConfig) ||
          !ioc.rbac.canEdit(resourceTypes.Global),
      },
    ],
  },
  {
    key: 'access-permissions',
    label: 'ACCESS PERMISSIONS',
    items: [
      {
        key: 'roles',
        label: 'Roles & permissions',
        route: '/settings/access-permissions/roles',
        hidden:
          !ioc.application.configuration.supportsUserClaims ||
          (!ioc.rbac.canEdit(resourceTypes.Global) && !ioc.rbac.canEdit(resourceTypes.Roles)),
      },
      {
        key: 'user-groups',
        label: 'User groups',
        route: '/settings/access-permissions/user-groups',
        hidden: !ioc.application.configuration.supportsUserClaims,
      },
      {
        key: 'tokens',
        label: 'Access tokens',
        route: '/settings/access-permissions/tokens',
        hidden:
          !ioc.rbac.canEdit(resourceTypes.AccessTokens) && !ioc.rbac.canEdit(resourceTypes.Global),
      },
    ],
  },
];
