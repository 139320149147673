import { observer } from 'mobx-react';
import { useCallback } from 'react';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { Skeleton } from '@src-v2/components/animations/skeleton';
import { Pagination } from '@src-v2/components/pagination';
import { TablePagination as Container } from '@src-v2/components/table/table-addons';
import { Paragraph } from '@src-v2/components/typography';
import { SimpleSelect } from '@src-v2/containers/simple-select';
import { useNamespacePageParams } from '@src-v2/hooks/use-namespace-page-params';
import { formatNumber } from '@src-v2/utils/number-utils';

const pageSizeOptions = [20, 50, 100];
const pageSizeOptionIdentifier = option => `${option} per page`;

export const TablePagination = observer(
  ({ searchState, namespace = 'fl', itemName = 'results', ...props }) => {
    const trackAnalytics = useTrackAnalytics();
    const { pageParams, updateNamespacePageParams } = useNamespacePageParams(namespace);
    const itemsRange = searchState.itemsRange.map(value => formatNumber(value));

    const selectedPage = Number(searchState.params.pageNumber) ?? Number(pageParams?.page) ?? 0;

    const onSetPageSize =
      searchState.params.onSetLimit ??
      useCallback(
        pageSize => {
          updateNamespacePageParams({ page: null, pageSize });
        },
        [namespace, updateNamespacePageParams]
      );

    const onSetPage =
      searchState.params.onSetPageNumber ??
      useCallback(
        pageNumber => updateNamespacePageParams({ page: pageNumber }),
        [namespace, updateNamespacePageParams]
      );

    const handlePaginationClick = useCallback(
      pageNumber => {
        onSetPage(pageNumber - 1);
        trackAnalytics(AnalyticsEventName.Pagination, {
          [AnalyticsDataField.PageNumber]: pageNumber,
          [AnalyticsDataField.ResultsPerPage]: searchState.items.length,
        });
      },
      [trackAnalytics, onSetPage]
    );

    return (
      <Container {...props} data-test-marker="pagination-container">
        {searchState.pageCount > 0 && !searchState.isLoadingCounters ? (
          <>
            <Pagination
              total={searchState.pageCount}
              selected={Math.min(selectedPage + 1, searchState.pageCount)}
              onClick={handlePaginationClick}
            />
            <SimpleSelect
              options={pageSizeOptions}
              defaultValue={searchState.params.limit}
              identity={pageSizeOptionIdentifier}
              onSelect={onSetPageSize}
              data-test-marker="pagination-select"
            />
            <Paragraph>
              {itemsRange.join('-')} of {formatNumber(searchState.count)} {itemName}
            </Paragraph>
          </>
        ) : (
          <>
            <Skeleton.Text length={15} />
            <Skeleton.Select />
            <Skeleton.Text length={20} />
          </>
        )}
      </Container>
    );
  }
);
