import {
  FormEvent,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import { Controller, FieldError, FieldErrors, FieldValues, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Input } from '@src-v2/components/forms';
import { InputControl } from '@src-v2/components/forms/form-controls';
import { Field, Label } from '@src-v2/components/forms/modal-form-layout';
import { LabelWrapper } from '@src-v2/containers/connectors/server-modal/connector-editor';
import { Connection } from '@src-v2/types/connector/connectors';
import { StubAny } from '@src-v2/types/stub-any';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { humanize } from '@src-v2/utils/string-utils';
import { SubHeading4 } from '@src/src-v2/components/typography';

interface CustomRenderProps {
  onChange: (...event: any[]) => void;
  field: {
    value: string;
    disabled?: boolean;
    [key: string]: StubAny;
  };
}

interface BaseFieldProperties {
  name?: string;
  type?: string;
  displayName?: string;
  placeholder?: string;
  rules?: {
    required?: boolean;
    validate?: {
      validateMaxLength?: (value: string) => string | boolean;
      noWhitespace?: (value: string) => boolean;
      uniqueServer?: (value: string) => boolean | string;
      validateWebhook?: (value: string) => boolean;
      validateWizClientId?: (value: string) => boolean;
      validateBrokerPublicKey?: (value: string) => boolean;
      regionRegex?: (value: string) => boolean;
      [key: string]: ((value: string) => boolean | string) | undefined;
    };
    [key: string]: unknown;
  };
  defaultValue?: string;
  disabled?: boolean;
  subLabel?: string;
  label?: ReactNode;
  onChange?: (
    setValue: (name: string, value: StubAny, options?: { shouldValidate?: boolean }) => void,
    event: FormEvent<HTMLInputElement>,
    getValues?: () => FieldValues
  ) => void;
  render?: (
    props: CustomRenderProps,
    setError: (name: string, error: { type: string; message: string }) => void
  ) => ReactElement;
  tokenExpireDate?: ReactElement;
  separateComponent?: ReactElement;
  existingServers?: Connection[];
  providerGroupName?: string;
  allowMultipleConnectorsUrl?: boolean;
  fixedDisplayValue?: string;
  fixedValue?: string;
}

type ProviderConfiguration = BaseFieldProperties &
  Partial<{
    name: string;
    tokenExpireDate: ReactElement;
    separateComponent: ReactElement;
  }>;

interface ConnectorEditorFieldsProps {
  fieldOptions: ProviderConfiguration[];
  fieldErrors: FieldErrors;
  isEdit: boolean;
}

/**
 * @param {FieldOption[]} fieldOptions
 * @param fieldErrors
 * @param {boolean} isEdit
 */
export const ConnectorEditorFields = ({
  fieldOptions,
  fieldErrors,
  isEdit,
}: ConnectorEditorFieldsProps) => {
  const { setValue, setError, getValues } = useFormContext();

  const preventFormDefaults = useCallback<KeyboardEventHandler<HTMLInputElement>>(event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  return (
    <>
      {fieldOptions.map(option => {
        if ('separateComponent' in option && option.separateComponent !== undefined) {
          return option.separateComponent;
        }

        if ('tokenExpireDate' in option && option.tokenExpireDate !== undefined) {
          return option.tokenExpireDate;
        }

        if (typeof option?.name !== 'string') {
          return null;
        }

        const fieldError = fieldErrors[option.name];

        return (
          <Field key={option.name}>
            <LabelWrapper>
              {option.label ?? (
                <Label required={option.rules?.required}>
                  {option.displayName ?? humanize(option.name)}
                </Label>
              )}
              {option.subLabel ? <SubHeading4>{option.subLabel}</SubHeading4> : null}
            </LabelWrapper>
            {option.fixedDisplayValue ? (
              <FixedValueInput option={option} setValue={setValue} isEdit={isEdit} />
            ) : option.allowMultipleConnectorsUrl ? (
              <AllowMultipleConnectorsInput option={option} isEdit={isEdit} />
            ) : (
              <Controller
                name={option.name}
                rules={option.rules}
                defaultValue={option.defaultValue}
                render={({ field: { onChange, ...field }, fieldState: { invalid } }) =>
                  option.render?.({ onChange, field }, setError) ?? (
                    <Input
                      {...field}
                      value={field.value ?? ''}
                      data-invalid={dataAttr(invalid)}
                      onKeyPress={preventFormDefaults}
                      defaultValue={option.defaultValue}
                      placeholder={option.placeholder}
                      type={option.type ?? 'text'}
                      onChange={event => {
                        option.onChange?.(setValue, event, getValues);
                        onChange(event);
                      }}
                      // @ts-expect-error
                      disabled={field.disabled || option.disabled}
                    />
                  )
                }
              />
            )}
            {(fieldError?.type === 'validateMaxLength' ||
              fieldError?.type === 'validateBrokerPublicKey') && (
              <ErrorsMessage>
                {isFieldError(fieldError)
                  ? fieldError.message
                  : typeof fieldError === 'string'
                    ? fieldError
                    : ''}
              </ErrorsMessage>
            )}
          </Field>
        );
      })}
    </>
  );
};

const isFieldError = (error: unknown): error is FieldError => {
  return typeof error === 'object' && error !== null && 'message' in error;
};

const FixedValueInput = ({
  option,
  setValue,
  isEdit,
}: {
  option: ProviderConfiguration;
  setValue: (name: string, value: string) => void;
  isEdit: boolean;
}) => {
  useEffect(() => {
    if (!isEdit) {
      setValue(option.name, option.fixedValue);
    }
  }, [option.name, option.fixedDisplayValue, isEdit]);

  return <Input value={option.fixedDisplayValue} readOnly disabled />;
};

const AllowMultipleConnectorsInput = ({
  option,
  isEdit,
}: {
  option: ProviderConfiguration;
  isEdit: boolean;
}) => {
  const { watch } = useFormContext();
  const urlValue = watch('url');

  return (
    <InputControl
      name="url"
      defaultValue={urlValue || option.defaultValue}
      placeholder={option.placeholder}
      disabled={isEdit}
      rules={{
        ...option.rules,
      }}
    />
  );
};

const MessageContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-top: 2rem;
  flex-direction: column;
  border-radius: 1rem;
  border: 0.25rem solid transparent;
  gap: 1rem;
`;

export const ErrorsMessage = styled(MessageContainer)`
  color: var(--color-red-60);
  background-color: var(--color-red-10);
  border-color: var(--color-red-20);
`;

/**
 * @typedef {Object} FieldOption
 * @property {string} name
 * @property {string} [displayName] Label's text, if null would use name
 * @property {ReactElement} [label] if exists will override displayName and name as the Label
 * @property {string} [type] Input's type
 * @property {string} [fixedDisplayValue] Will disable the Input and present the fixed value
 * @property {string} [fixedValue] Sends this value as the field value when fixedDisplayValue is set
 * @property {string} [defaultValue]
 * @property {string} [placeholder] Placeholder value, in case a default value does not exist
 * @property {Object[]} [rules] react-hook-form controller rules
 * @property {boolean} [disabled]
 * @property {onChange} [onChange]
 */

/**
 * @callback onChange enables to manipulate the form data from outside when input changes
 * @param {setValue} setValue
 * @param {Object} event
 * @param {getValues} getValues
 */

/**
 * @callback setValue react-hook-form default setValue function
 * @param {string} name
 * @param value
 * @param {Object} [options]
 */
