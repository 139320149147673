import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Banner } from '@src-v2/components/banner';
import { Button } from '@src-v2/components/button-v2';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';

export const DefinitionBanner = ({
  setRule,
  addRule,
  setOpenedCreateRuleFromBanner,
  rule: { definitionType, definitionName },
}) => {
  const { rbac } = useInject();
  const history = useHistory();
  const titlePrefix = addInterpunctSeparator(definitionType, definitionName);
  const canCreateGovernancePolicy =
    rbac.canEdit(resourceTypes.Governance) || rbac.canEdit(resourceTypes.GovernancePolicies);

  const handleAction = useCallback(() => {
    history.push('/governance/rules');
    setOpenedCreateRuleFromBanner(true);
    addRule();
  }, [setOpenedCreateRuleFromBanner, addRule]);

  const handleClose = useCallback(() => setRule({}), [setRule]);

  return (
    <Banner
      title={`${titlePrefix} was successfully created!`}
      description={
        canCreateGovernancePolicy &&
        "Now let's create a governance policy to define and manage the risk"
      }
      onClose={handleClose}>
      {canCreateGovernancePolicy && (
        <Button onClick={handleAction} size="small">
          Create policy
        </Button>
      )}
    </Banner>
  );
};
