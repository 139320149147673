import { useCallback } from 'react';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Heading1, Heading4, Paragraph, SubHeading4 } from '@src-v2/components/typography';
import { useInject } from '@src-v2/hooks';

export function ReloadRulesetModal({
  onClose,
  url,
  setValue,
  ...props
}: {
  onClose: () => void;
  url: string;
  setValue: (key: string, value: unknown) => void;
}) {
  const { organization, toaster } = useInject();

  const handleSubmit = useCallback(async () => {
    try {
      await organization.managedSemgrepPostGitUrl(url);
      setValue('rulesSetGitRepositoryTime', new Date().toLocaleString());
      toaster.success(
        <>
          <Heading4>Ruleset reloaded successfully</Heading4>
          <SubHeading4>This ruleset update may take a few minutes </SubHeading4>
        </>
      );
    } catch (error) {
      toaster.error('Failed to reload ruleset, please try again');
    } finally {
      onClose?.();
    }
  }, []);

  return (
    <ConfirmationModal
      {...props}
      title={<Heading1>Reload latest ruleset</Heading1>}
      confirmOnClose
      submitText="Reload"
      onClose={onClose}
      onSubmit={handleSubmit}>
      <Paragraph>
        Reload the latest ruleset to use the most up-to-date version from the repository. This
        action replaces your current ruleset.
      </Paragraph>
    </ConfirmationModal>
  );
}
