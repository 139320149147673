import { observer } from 'mobx-react';
import { Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from '@src-v2/components/cards';
import { InwardSection } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { StickyHeader } from '@src-v2/components/layout/sticky-header';
import { ProtectedRoute as Route } from '@src-v2/components/protected-route';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { AccessTokenForm } from '@src-v2/containers/access-tokens/access-token-form';
import { AccessTokensDisplayPanel } from '@src-v2/containers/access-tokens/access-tokens-display-panel';
import { ManualFindingsContent } from '@src-v2/containers/manual-findings/manual-findings-content';
import { ManualFindingsForm } from '@src-v2/containers/manual-findings/manual-findings-form';
import { GeneralSettings } from '@src-v2/containers/pages/general-settings/general-settings';
import { ProcessTagsPage } from '@src-v2/containers/process-tags/process-tags-page';
import { RolesForm } from '@src-v2/containers/roles/role-form';
import { RolesPage } from '@src-v2/containers/roles/roles-page';
import { UserGroupCreatePage } from '@src-v2/containers/user-groups/user-groups-create-page';
import { UserGroupsForm } from '@src-v2/containers/user-groups/user-groups-form';
import { UserGroupsPage } from '@src-v2/containers/user-groups/user-groups-page.tsx';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';

export default observer(() => {
  const { application, accessTokens } = useInject();

  return (
    <Switch>
      <Route path="/settings/access-permissions" exact>
        <Page title="Access Permissions">
          <StickyHeader />
          <Content>
            {application.configuration.supportsUserClaims &&
              application.integrations?.connectedToScm && (
                <SettingsCard to="/settings/access-permissions/roles">
                  <Heading>Roles & Permissions</Heading>
                  <Paragraph>
                    Create and manage roles and permissions by action type, application scope, etc.
                  </Paragraph>
                </SettingsCard>
              )}
            {application.configuration.supportsUserClaims &&
              application.integrations?.connectedToScm && (
                <SettingsCard to="/settings/access-permissions/user-groups">
                  <Heading>User Groups</Heading>
                  <Paragraph>
                    Create and manage user groups to assign roles and permissions
                  </Paragraph>
                </SettingsCard>
              )}
            <SettingsCard to="/settings/access-permissions/tokens">
              <Heading>Access Tokens</Heading>
              <Paragraph>Create and manage access tokens to use the Apiiro API</Paragraph>
            </SettingsCard>
            {application.isFeatureEnabled(FeatureFlag.ProcessTags) && (
              <SettingsCard to="/settings/access-permissions/process-tags">
                <Heading>SSDLC Processes</Heading>
                <Paragraph>
                  Create and manage security processes labels to tag governance policies, prioritize
                  assets by security process, etc.
                </Paragraph>
              </SettingsCard>
            )}
          </Content>
        </Page>
      </Route>
      <Route
        path={[
          '/settings/access-permissions/roles/create',
          '/settings/access-permissions/roles/:key/edit',
        ]}
        component={RolesForm}
      />
      <Route path="/settings/access-permissions/roles" component={RolesPage} exact />

      {application.isFeatureEnabled(FeatureFlag.ManualFindingsEntry) && (
        <Route path="/settings/manual-findings/create" component={ManualFindingsForm} />
      )}
      {application.isFeatureEnabled(FeatureFlag.ManualFindingsEntry) && (
        <Route path="/settings/manual-findings/:key/edit" component={ManualFindingsForm} />
      )}
      {application.isFeatureEnabled(FeatureFlag.ManualFindingsEntry) && (
        <Route path="/settings/manual-findings" component={ManualFindingsContent} />
      )}
      <Route path="/settings/access-permissions/tokens">
        <Page title="Access Tokens">
          <Switch>
            <Route path="/settings/access-permissions/tokens/create">
              <AccessTokenForm
                title="New Access Token"
                dataFetcher={accessTokens.createAccessToken}
              />
            </Route>
            <Route path="/settings/access-permissions/tokens/:key/edit">
              <AccessTokenForm
                title="View & edit token"
                dataFetcher={accessTokens.getAccessToken}
              />
            </Route>
            <Route>
              <AccessTokensDisplayPanel />
            </Route>
          </Switch>
        </Page>
      </Route>
      <Route path="/settings/access-permissions/process-tags">
        <ProcessTagsPage />
      </Route>
      <Route
        path={[
          '/settings/access-permissions/user-groups/create',
          '/settings/access-permissions/user-groups/:key/edit',
        ]}>
        <UserGroupsForm>
          <UserGroupCreatePage />
        </UserGroupsForm>
      </Route>
      <Route path="/settings/access-permissions/user-groups">
        <UserGroupsPage />
      </Route>
      <Route path="/settings/general">
        <GeneralSettings />
      </Route>
      <Redirect to="/settings/access-permissions" />
    </Switch>
  );
});

const Content = styled(InwardSection)`
  display: flex;
  flex-direction: column;
  gap: 8rem;
`;

const SettingsCard = styled(Card)`
  ${Heading} {
    margin-bottom: 1rem;
    font-size: var(--font-size-xxxl);
  }
`;
