import { Fragment } from 'react';
import styled from 'styled-components';
import { Paragraph } from '@src-v2/components/typography';
import { ArtifactImageIdentification } from '@src-v2/types/artifacts/artifacts-types';

const ImageVersionTooltipRow = styled(Paragraph)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ImageVersionTooltipRowLabel = styled.span`
  white-space: nowrap;
  flex-shrink: 0;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding-left: 1rem;
`;

const Value = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  text-align: left;
`;

export const ImageVersionTooltipContent = ({
  identification,
}: {
  identification: ArtifactImageIdentification;
}) => {
  const { repoTags = [], repoDigests = [], fullImageIdSignature = '' } = identification ?? {};

  return (
    <>
      {repoTags?.length > 0 && (
        <ImageVersionTooltipRow>
          <ImageVersionTooltipRowLabel>Repo tags:</ImageVersionTooltipRowLabel>
          <ValueContainer>
            <Value>
              {repoTags.map((tag, index) => (
                <Fragment key={index}>
                  {tag}
                  {index < repoTags.length - 1 && '\n'}
                </Fragment>
              ))}
            </Value>
          </ValueContainer>
        </ImageVersionTooltipRow>
      )}

      {repoDigests?.length > 0 && (
        <ImageVersionTooltipRow>
          <ImageVersionTooltipRowLabel>Repo digests:</ImageVersionTooltipRowLabel>
          <ValueContainer>
            <Value>
              {repoDigests.map((digest, index) => (
                <Fragment key={index}>
                  {digest}
                  {index < repoDigests.length - 1 && '\n'}
                </Fragment>
              ))}
            </Value>
          </ValueContainer>
        </ImageVersionTooltipRow>
      )}

      {fullImageIdSignature && (
        <ImageVersionTooltipRow>
          <ImageVersionTooltipRowLabel>Image ID:</ImageVersionTooltipRowLabel>
          <ValueContainer>
            <Value>{fullImageIdSignature}</Value>
          </ValueContainer>
        </ImageVersionTooltipRow>
      )}
    </>
  );
};
