import { ReactNode } from 'react';
import styled from 'styled-components';
import { CircleButton } from '@src-v2/components/button-v2';
import { StatusIcon, SvgIcon } from '@src-v2/components/icons';
import { Size } from '@src-v2/components/types/enums/size';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { Heading, Heading4, Paragraph } from '@src-v2/components/typography';
import { StyledProps, assignStyledNodes } from '@src-v2/types/styled';

export enum BannerTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

type BannerProps = {
  title?: ReactNode;
  description?: ReactNode;
  type?: BannerTypes;
  children?: ReactNode;
  onClose?: () => void;
};

const _Banner = styled(
  ({
    title,
    description,
    children,
    type = BannerTypes.INFO,
    onClose,
    ...props
  }: StyledProps<BannerProps>) => {
    const icon =
      type === BannerTypes.SUCCESS
        ? 'Success'
        : type === BannerTypes.INFO
          ? 'InfoSolid'
          : 'Warning';
    return (
      <Container {...props} data-type={type}>
        <StatusIcon size={Size.LARGE} name={icon} />
        <Banner.Content>
          {title && <Heading4>{title}</Heading4>}
          {description && <Paragraph>{description}</Paragraph>}
        </Banner.Content>
        <Banner.Actions>{children}</Banner.Actions>
        {Boolean(onClose) && (
          <CircleButton variant={Variant.TERTIARY} size={Size.LARGE} onClick={onClose}>
            <SvgIcon name="CloseLarge" size={Size.LARGE} />
          </CircleButton>
        )}
      </Container>
    );
  }
)``;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 4rem auto;
  padding: 3rem 4rem;
  align-items: center;
  border-radius: 3rem;
  border: 0.25rem solid var(--color-blue-60);
  background-color: var(--color-blue-25);
  gap: 3rem;

  &[data-type=${BannerTypes.SUCCESS}] {
    background-color: var(--color-green-10);
    border-color: var(--color-green-50);

    ${StatusIcon} {
      color: var(--color-green-50);
    }
  }

  &[data-type=${BannerTypes.INFO}] {
    background-color: var(--color-blue-15);
    border-color: var(--color-blue-60);

    ${StatusIcon} {
      color: var(--color-blue-60);
    }
  }

  &[data-type=${BannerTypes.WARNING}] {
    background-color: var(--color-orange-15);
    border-color: var(--color-orange-55);

    ${StatusIcon} {
      color: var(--color-orange-55);
    }
  }

  &[data-type=${BannerTypes.ERROR}] {
    background-color: var(--color-red-10);
    border-color: var(--color-red-50);

    ${StatusIcon} {
      color: var(--color-red-50);
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 6rem;

  ${Heading} {
    font-size: var(--font-size-m);
    font-weight: 700;
    margin-bottom: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 3rem;
`;

export const Banner = assignStyledNodes(_Banner, {
  Content,
  Actions,
});
