import { Badge, BadgeColors } from '@src-v2/components/badges';
import { SvgIcon } from '@src-v2/components/icons';
import { Size } from '@src-v2/components/types/enums/size';
import { abbreviate } from '@src-v2/utils/number-utils';

export const TrendBadge = ({ percentage, ...props }: { percentage: number }) => {
  const trendColor = percentage ? BadgeColors.Blue : BadgeColors.Glue;
  const badgeIcon = percentage > 0 ? 'TrendUp' : percentage < 0 ? 'TrendDown' : 'Minus';

  return (
    <Badge {...props} color={trendColor} size={Size.XSMALL}>
      <SvgIcon name={badgeIcon} />
      &nbsp;
      {abbreviate(Math.abs(percentage), 0)}%
    </Badge>
  );
};
