import { useParams } from 'react-router-dom';
import {
  RemoteGroupedFilterSelect,
  RemoteGroupedFilterSelectProps,
} from '@src-v2/components/filters/inline-control/components/remote-grouped-filter-select';
import { useInject } from '@src-v2/hooks';

type ArtifactCustomRemoteFilterProps = Omit<
  RemoteGroupedFilterSelectProps,
  'searchMethod' | 'initSelectedOptions'
>;

export const RiskArtifactVersionsFilterSelect = (props: ArtifactCustomRemoteFilterProps) => {
  const { artifacts } = useInject();
  const { artifactKey } = useParams<{ artifactKey: string }>();

  return (
    <RemoteGroupedFilterSelect
      {...props}
      searchParams={{ artifactKey }}
      searchMethod={artifacts.searchRiskArtifactVersionsFilter}
      initSelectedOptions={artifacts.initRiskArtifactVersionsFilterOptions}
    />
  );
};

export const DependencyArtifactVersionsFilterSelect = (props: ArtifactCustomRemoteFilterProps) => {
  const { artifacts } = useInject();
  const { artifactKey } = useParams<{ artifactKey: string }>();

  return (
    <RemoteGroupedFilterSelect
      {...props}
      searchParams={{ artifactKey }}
      searchMethod={artifacts.searchDependencyArtifactVersionsFilter}
      initSelectedOptions={artifacts.initDependencyArtifactVersionsFilterOptions}
    />
  );
};

export const ArtifactVersionsFilterSelect = (props: ArtifactCustomRemoteFilterProps) => {
  const { artifacts } = useInject();
  const { artifactKey } = useParams<{ artifactKey: string }>();

  return (
    <RemoteGroupedFilterSelect
      {...props}
      searchParams={{ artifactKey }}
      searchMethod={artifacts.searchArtifactVersionsFilter}
      initSelectedOptions={artifacts.initArtifactVersionsFilterOptions}
    />
  );
};
