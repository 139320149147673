import { useCallback } from 'react';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { Banner, BannerTypes } from '@src-v2/components/banner';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Field } from '@src-v2/components/forms/modal-form-layout';
import { VendorIcon } from '@src-v2/components/icons';
import { ContactUsLink } from '@src-v2/components/links';
import { Markdown } from '@src-v2/components/markdown';
import { Modal } from '@src-v2/components/modals';
import { ToastParagraph } from '@src-v2/components/toastify';
import { ExternalLink, Heading, Heading4, NavLink, Paragraph } from '@src-v2/components/typography';
import {
  AwsConnectorEditor,
  awsConnectorResolver,
} from '@src-v2/containers/connectors/server-modal/aws-connector-editor';
import { ConnectorEditor } from '@src-v2/containers/connectors/server-modal/connector-editor';
import { GithubConnectorEditor } from '@src-v2/containers/connectors/server-modal/github-connector-editor';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { ProviderGroup } from '@src-v2/types/enums/provider-group';
import { ProviderVisibilityStatus } from '@src-v2/types/enums/provider-visibility-status';
import { dataAttr } from '@src-v2/utils/dom-utils';

export function ServerModal({
  providerGroup,
  server: originalServer = undefined,
  onClose,
  onSubmit = undefined,
  isEditMode = undefined,
  ...props
}) {
  const { connectors, application, analytics, toaster } = useInject();

  const handleConnect = useCallback(
    async server => {
      const isTeamsBot = providerGroup.key === 'Teams' && server.type === 'bot';
      const isSaasAzureApi = providerGroup.key === 'AzureCloud' && application.isSaas;
      const shouldRedirectToAzureAuth =
        isSaasAzureApi && !application.isFeatureEnabled(FeatureFlag.ForceAzureCloudOnPremConnector);

      if (server?.providerConfigJson?.issueEntityTypesToPull) {
        server.providerConfigJson.issueEntityTypesToPull =
          server?.providerConfigJson?.issueEntityTypesToPull?.map(type => type.value);
      }

      // Remove once FeatureFlag.AkamaiApiSecurityActiveTesting is removed
      if (providerGroup.key === ProviderGroup.AkamaiApiSecurity) {
        if (!('pullAkamaiApiSecurity' in server.providerConfigJson)) {
          server.providerConfigJson.pullAkamaiApiSecurity = true;
        }
      }

      if (isTeamsBot || shouldRedirectToAzureAuth) {
        await connectors.redirectToOAuthConsentUrl(
          providerGroup.key,
          isSaasAzureApi
            ? {
                tenantId: server.additionalData,
                resourceId: '',
              }
            : null
        );
        return;
      }

      if (server.url && typeof server.url === 'object') {
        server.url = server.url.value;
      } else if (providerGroup.allowMultipleConnectorsUrl) {
        server.url = `${server.url}/?display_name=${crypto.randomUUID()}`;
      }

      try {
        const isDefaultRuleAdded = await (originalServer
          ? connectors.updateServer(server)
          : connectors.createServer({
              ...server,
              selectedProviderGroup: providerGroup.key,
            }));
        analytics.track('Connector Added', { Provider: providerGroup.key, Success: true });

        if (isDefaultRuleAdded) {
          toaster.success(
            <>
              <Heading>
                {isEditMode ? 'Connector configuration updated successfully' : 'New rule added'}
              </Heading>
              {!isEditMode && (
                <ToastParagraph>
                  We've added a new governance rule that enables vulnerabilities prioritization.
                </ToastParagraph>
              )}
              {!isEditMode && <NavLink to="/governance">View & edit rule</NavLink>}
            </>,
            { delay: 100 }
          );
        } else {
          toaster.success('Connection created successfully');
        }

        onSubmit?.();
        onClose();
      } catch (error) {
        toaster.error(
          <>
            <Heading>Something went wrong</Heading>
            <Markdown linkTarget="_blank">
              {error.response?.data?.serverMessage ?? 'We were unable to connect.'}
            </Markdown>
            <ToastParagraph>
              Please validate your input and try again or contact our support at <ContactUsLink />
            </ToastParagraph>
          </>
        );
      }
    },
    [originalServer, onClose]
  );

  return (
    <ModalContainer
      {...props}
      data-connect={dataAttr(!providerGroup.connected)}
      submitText={originalServer ? 'Update' : 'Connect'}
      title={
        <>
          <VendorIcon name={providerGroup.iconName ?? providerGroup.key} />{' '}
          {originalServer
            ? `Edit ${providerGroup.displayName ?? providerGroup.key} Connector`
            : `Connect ${providerGroup.displayName ?? providerGroup.key}`}
        </>
      }
      resolver={providerGroup.key === 'AWS' && awsConnectorResolver}
      defaultValues={
        originalServer ??
        (providerGroup.brokerHostUrl ? { url: `https://${providerGroup.brokerHostUrl}` } : null)
      }
      onSubmit={handleConnect}
      onClose={onClose}>
      {providerGroup.visibilityStatus === ProviderVisibilityStatus.Preview && (
        <PreviewInfoBanner displayName={providerGroup.displayName} />
      )}
      <AsyncBoundary>
        {providerGroup.key === 'Github' && !originalServer ? (
          <GithubConnectorEditor providerGroup={providerGroup} isEdit={Boolean(originalServer)} />
        ) : providerGroup.key === 'AWS' && !originalServer ? (
          <AwsConnectorEditor providerGroup={providerGroup} isEdit={Boolean(originalServer)} />
        ) : (
          <ConnectorEditor providerGroup={providerGroup} isEdit={Boolean(originalServer)} />
        )}

        {providerGroup.docsUrl && (
          <ExternalLink href={providerGroup.docsUrl}>
            Read our docs for detailed instructions
          </ExternalLink>
        )}
      </AsyncBoundary>
    </ModalContainer>
  );
}

const PreviewInfoBanner = ({ displayName }) => (
  <Banner type={BannerTypes.INFO}>
    <InfoBannerContent>
      <Heading4>Preview program</Heading4>
      <Paragraph>
        The {displayName} integration is part of Apiiro's Preview program. For more information
        reach out to your Apiiro Customer Success person.
      </Paragraph>
    </InfoBannerContent>
  </Banner>
);

const InfoBannerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalContainer = styled(ConfirmationModal)`
  position: relative;
  width: 160rem;

  &[data-connect] {
    width: 145rem;
  }

  ${Modal.Content} {
    ${Field} {
      font-size: var(--font-size-s);
      font-weight: 300;
      color: var(--color-blue-gray-70);
      gap: 1rem;
    }

    > ${ExternalLink} {
      font-size: var(--font-size-s);
    }
  }
`;
