import { makeUrl } from '@src-v2/utils/history-utils';

export enum DetailedTrendTiles {
  RepositoriesDetails = 'RepositoriesDetails',
  ApisDetails = 'ApisDetails',
  SensitiveDataDetails = 'SensitiveDataDetails',
  ContributorsDetails = 'ContributorsDetails',
}
export enum DetailedTrendNames {
  Hbi = 'Hbi',
  InActiveDevelopment = 'InActiveDevelopment',
  DeployedRepositories = 'DeployedRepositories',
  Exposed = 'Exposed',
  Sensitive = 'Sensitive',
  MissingSecurityControls = 'MissingSecurityControls',
  PiiFields = 'PiiFields',
  PhiData = 'PhiData',
  PaymentData = 'PaymentData',
  WrittenToLog = 'WrittenToLog',
  SecurityChampions = 'SecurityChampions',
  ActiveDevelopers = 'ActiveDevelopers',
  InactiveDevelopers = 'InactiveDevelopers',
}

export type DetailedTrendItemObject = {
  title?: string;
  menuItems: Array<{ name: string; to: string }>;
  startValue?: number;
  endValue?: number;
};
export const DetailedTrendListObject: {
  [key in DetailedTrendNames]: DetailedTrendItemObject;
} = {
  [DetailedTrendNames.Hbi]: {
    title: 'HBI repositories',
    menuItems: [
      {
        name: 'Show in Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'RepositoryProfile',
            query: { type: 'property', property: 'businessImpact', operator: '=', operand: 'High' },
            aggregation: null,
          },
        }),
      },
      {
        name: 'View in Repositories',
        to: makeUrl('/profiles/repositories', {
          fl: { BusinessImpact: { values: ['High'] } },
        }),
      },
    ],
  },
  [DetailedTrendNames.InActiveDevelopment]: {
    menuItems: [
      {
        name: 'Show in Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'RepositoryProfile',
            query: { type: 'property', property: 'isActive', operator: '=', operand: 'true' },
            aggregation: null,
          },
        }),
      },
      {
        name: 'View in Repositories',
        to: makeUrl('/profiles/repositories', {
          fl: { Behavior: { values: ['ConsumableIsActive'] } },
        }),
      },
    ],
  },
  [DetailedTrendNames.DeployedRepositories]: {
    menuItems: [
      {
        name: 'Show in Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'RepositoryProfile',
            query: { type: 'property', property: 'isDeployed', operator: '=', operand: 'true' },
            aggregation: null,
          },
        }),
      },
      {
        name: 'View in Repositories',
        to: makeUrl('/profiles/repositories', {
          fl: { ProfileInsights: { values: ['Deployed'] } },
        }),
      },
    ],
  },
  [DetailedTrendNames.Exposed]: {
    menuItems: [
      {
        name: 'Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'ApiElement',
            query: {
              type: 'boolean',
              combiner: 'and',
              subExpressions: [
                {
                  type: 'relationship',
                  relationshipQuery: {
                    type: 'property',
                    property: 'isInternetExposed',
                    operator: '=',
                    operand: 'true',
                  },
                  relationshipName: 'repository',
                },
                { type: 'property', property: 'isInternetFacing', operator: '=', operand: 'true' },
              ],
            },
            aggregation: null,
          },
        }),
      },
    ],
  },
  [DetailedTrendNames.Sensitive]: {
    menuItems: [
      {
        name: 'Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'ApiElement',
            query: { type: 'property', property: 'isSensitive', operator: '=', operand: 'true' },
            aggregation: null,
          },
        }),
      },
    ],
  },
  [DetailedTrendNames.MissingSecurityControls]: {
    menuItems: [
      {
        name: 'Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'ApiElement',
            query: {
              type: 'boolean',
              combiner: 'and',
              subExpressions: [
                {
                  type: 'boolean',
                  combiner: 'or',
                  subExpressions: [
                    {
                      type: 'property',
                      property: 'apiSecurityControls',
                      operator: 'ninc',
                      operand: 'Authentication',
                    },
                    {
                      type: 'property',
                      property: 'apiSecurityControls',
                      operator: 'ninc',
                      operand: 'Authorization',
                    },
                  ],
                },
                { type: 'property', property: 'isSensitive', operator: '=', operand: 'true' },
              ],
            },
            aggregation: null,
          },
        }),
      },
    ],
  },
  [DetailedTrendNames.PiiFields]: {
    title: 'PII fields',
    menuItems: [
      {
        name: 'Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'SensitiveData',
            query: {
              type: 'typeSwitch',
              typeQueries: [
                {
                  typeSelector: 'SensitiveDataField',
                  query: {
                    type: 'property',
                    property: 'sensitiveDataTypes',
                    operator: 'inc',
                    operand: 'PII',
                  },
                },
              ],
            },
            aggregation: null,
          },
        }),
      },
    ],
  },
  [DetailedTrendNames.PhiData]: {
    title: 'PHI data',
    menuItems: [
      {
        name: 'Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'SensitiveData',
            query: {
              type: 'typeSwitch',
              typeQueries: [
                {
                  typeSelector: 'SensitiveDataField',
                  query: {
                    type: 'property',
                    property: 'sensitiveDataTypes',
                    operator: 'inc',
                    operand: 'PHI',
                  },
                },
              ],
            },
            aggregation: null,
          },
        }),
      },
    ],
  },
  [DetailedTrendNames.PaymentData]: {
    menuItems: [
      {
        name: 'Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'SensitiveData',
            query: {
              type: 'typeSwitch',
              typeQueries: [
                {
                  typeSelector: 'SensitiveDataField',
                  query: {
                    type: 'property',
                    property: 'sensitiveDataTypes',
                    operator: 'inc',
                    operand: 'Payments',
                  },
                },
              ],
            },
          },
        }),
      },
    ],
  },
  [DetailedTrendNames.WrittenToLog]: {
    menuItems: [
      {
        name: 'Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'SensitiveData',
            query: {
              type: 'typeSwitch',
              typeQueries: [
                {
                  typeSelector: 'SensitiveDataField',
                  query: {
                    type: 'property',
                    property: 'writtenToLogs',
                    operator: '=',
                    operand: 'true',
                  },
                },
              ],
            },
            aggregation: null,
          },
        }),
      },
    ],
  },
  [DetailedTrendNames.SecurityChampions]: {
    menuItems: [
      {
        name: 'Show in Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'DeveloperProfile',
            query: { type: 'property', property: 'isActive', operator: '=', operand: 'true' },
            aggregation: null,
          },
        }),
      },
      {
        name: 'View in Contributors',
        to: makeUrl('/profiles/contributors', {
          fl: { Behavior: { values: ['IsActiveDeveloper'] } },
        }),
      },
    ],
  },
  [DetailedTrendNames.ActiveDevelopers]: {
    menuItems: [
      {
        name: 'Show in Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'DeveloperProfile',
            query: { type: 'property', property: 'isNew', operator: '=', operand: 'true' },
            aggregation: null,
          },
        }),
      },
      {
        name: 'View in Contributors',
        to: makeUrl('/users/contributors', {
          fl: { Behavior: { values: ['IsNew'] } },
        }),
      },
    ],
  },
  [DetailedTrendNames.InactiveDevelopers]: {
    menuItems: [
      {
        name: 'Show in Explorer',
        to: makeUrl('/explorer', {
          q: {
            $type: 'explorer-schema',
            $version: 1,
            rowset: 'DeveloperProfile',
            query: {
              type: 'property',
              property: 'isSecurityContributor',
              operator: '=',
              operand: 'true',
            },
            aggregation: null,
          },
        }),
      },
      {
        name: 'View in Contributors',
        to: makeUrl('/users/contributors', {
          fl: { Contributions: { values: ['ContributedSecurity'] } },
        }),
      },
    ],
  },
};
