import { observer } from 'mobx-react';
import { AnalyticsDataField, AnalyticsLayer } from '@src-v2/components/analytics-layer';
import { Page } from '@src-v2/components/layout/page';
import { GeneralSettingsForm } from '@src-v2/containers/pages/general-settings/general-settings-form';
import { ManagedSemgrepSettingsForm } from '@src-v2/containers/pages/general-settings/managed-semgrep/managed-semgrep-settings-form';

export const ManagedSemgrepPage = observer(() => (
  <AnalyticsLayer analyticsData={{ [AnalyticsDataField.Context]: 'Managed semgrep' }}>
    <Page title="Managed semgrep">
      <GeneralSettingsForm location="/settings/managed-semgrep">
        <ManagedSemgrepSettingsForm />
      </GeneralSettingsForm>
    </Page>
  </AnalyticsLayer>
));
