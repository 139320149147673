import { useMemo } from 'react';
import styled from 'styled-components';
import { TextButton } from '@src-v2/components/button-v2';
import { Card } from '@src-v2/components/cards';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { Size } from '@src-v2/components/types/enums/size';
import { Heading5, Paragraph } from '@src-v2/components/typography';
import {
  DetailedTrendListObject,
  DetailedTrendNames,
} from '@src-v2/containers/inventory-overview/detaild-trend-object';
import { TrendBadge } from '@src-v2/containers/inventory-overview/trend-badge';
import { calcChangePercentage } from '@src-v2/containers/inventory-overview/utils';
import { humanize } from '@src-v2/utils/string-utils';

export interface DetailedTrendListItemProps {
  category: DetailedTrendNames;
  startValue: number;
  endValue: number;
  timeFilterInsensitive?: boolean;
}

export const DetailedTrendItem = ({
  category,
  startValue,
  endValue,
  timeFilterInsensitive,
}: DetailedTrendListItemProps) => {
  const { title, menuItems } = useMemo(
    () => DetailedTrendListObject[category],
    [DetailedTrendListObject, category]
  );

  return (
    <TrendItem>
      <Paragraph>{title ?? humanize(category)}</Paragraph>
      <Heading5>{endValue}</Heading5>
      {!timeFilterInsensitive && (
        <TrendBadge percentage={calcChangePercentage(startValue, endValue)} />
      )}
      <DropdownMenu size={Size.MEDIUM}>
        {menuItems.map((item, index) => (
          <Dropdown.Item key={index}>
            <TextButton size={Size.XSMALL} to={item.to}>
              {item.name}
            </TextButton>
          </Dropdown.Item>
        ))}
      </DropdownMenu>
    </TrendItem>
  );
};

const TrendItem = styled(Card)`
  --card-padding: 3rem;
  display: flex;
  align-items: center;

  ${Paragraph} {
    margin-bottom: 0;
    font-weight: 400;
  }

  ${Heading5} {
    margin-left: auto;
    padding-right: 2rem;
  }

  ${TextButton} {
    display: block;
  }
`;
