import {
  ActionsMenuCell,
  ActionsTakenCell,
  ComponentCell,
  GroupCell,
  LocationCell,
  MainContributorCell,
  ModuleNameCell,
  RiskCategoryCell,
  RiskIconCell,
  RiskInsightsCell,
  RuleNameCell,
  ServerUrlCell,
  SourceCell,
  TagCell,
  TeamsCell,
} from '@src-v2/containers/risks/risks-common-cells';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { RiskTriggerSummaryResponse } from '@src-v2/types/risks/risk-trigger-summary-response';
import { Column } from '@src-v2/types/table';

export const pipelinesRisksTableColumns: Column<RiskTriggerSummaryResponse>[] = [
  {
    key: 'risk-column',
    label: 'Risk',
    width: '28rem',
    resizeable: false,
    fieldName: 'RiskLevel',
    sortable: true,
    Cell: RiskIconCell,
  },
  {
    key: 'rule-name',
    label: 'Risk policy name',
    fieldName: 'RuleName',
    sortable: true,
    Cell: RuleNameCell,
  },
  {
    key: 'component',
    label: 'Component',
    fieldName: 'RiskName',
    sortable: true,
    Cell: ComponentCell,
  },
  {
    key: 'risk-category',
    label: 'Risk category',
    minWidth: '35rem',
    fieldName: 'RiskCategory',
    sortable: true,
    Cell: RiskCategoryCell,
  },
  {
    key: 'insights',
    label: 'Insights',
    minWidth: '24rem',
    Cell: RiskInsightsCell,
  },
  {
    key: 'app-repo',
    label: 'Application/Repository',
    minWidth: '50rem',
    Cell: LocationCell,
  },
  {
    key: 'teams',
    label: 'Teams',
    minWidth: '50rem',
    Cell: TeamsCell,
    betaFeature: FeatureFlag.OrgTeams,
  },
  {
    key: 'main-contributor',
    label: 'Main contributor',
    minWidth: '39rem',
    width: '39rem',
    resizeable: false,
    Cell: MainContributorCell,
  },
  {
    key: 'source',
    label: 'Source',
    minWidth: '23rem',
    resizeable: false,
    Cell: SourceCell,
  },
  {
    key: 'actions-taken',
    label: 'Actions taken',
    hidden: true,
    minWidth: '34rem',
    Cell: ActionsTakenCell,
  },
  {
    key: 'actions-menu',
    label: '',
    width: '10rem',
    draggable: false,
    resizeable: false,
    Cell: ActionsMenuCell,
  },
  {
    key: 'server-url',
    label: 'Server URL',
    minWidth: '20rem',
    hidden: true,
    Cell: ServerUrlCell,
  },
  {
    key: 'repository-group',
    label: 'Repository Group',
    fieldName: 'RepositoryGroup',
    minWidth: '20rem',
    sortable: true,
    hidden: true,
    Cell: GroupCell,
  },
  {
    key: 'module-name',
    label: 'Code module',
    fieldName: 'ModuleName',
    minWidth: '35rem',
    sortable: false,
    hidden: true,
    Cell: ({ data }) => <ModuleNameCell moduleName={data.moduleName} />,
  },
  {
    key: 'repository-tag',
    label: 'Repository tag',
    width: '50rem',
    Cell: ({ data }) => <TagCell tags={data.repositoryTags} />,
    hidden: true,
  },
  {
    key: 'application-tag',
    label: 'Application tag',
    Cell: ({ data }) => <TagCell tags={data.applicationTags} />,
    hidden: true,
  },
];
