import { Badge } from '@src-v2/components/badges';
import { SvgIcon } from '@src-v2/components/icons';
import { useRiskScoreTrend } from '@src-v2/containers/overview/tiles/risky-score-trend-tile/use-risky-score-trend';

export const RiskyScoreTrendBadge = () => {
  const { percentageChange, style } = useRiskScoreTrend();

  return (
    <Badge color={style.trendColor}>
      <SvgIcon name={style.badgeIcon} />
      &nbsp;
      {parseFloat(percentageChange.toFixed(1))}%
    </Badge>
  );
};
