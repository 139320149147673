import { BackdropColors, TrendCardProps } from '@src-v2/containers/inventory-overview/trend-card';
import { makeUrl } from '@src-v2/utils/history-utils';
import { humanize } from '@src-v2/utils/string-utils';

export enum InventoryObjectType {
  RiskyTicket = 'RiskyTicket',
  Repository = 'Repository',
  User = 'User',
  Api = 'Api',
  SensitiveData = 'SensitiveData',
  Package = 'Package',
  Technology = 'Technology',
  Infrastructure = 'Infrastructure',
  Pipeline = 'Pipeline',
  ContainerImage = 'ContainerImage',
  Cluster = 'Cluster',
}

export const inventoryTrendCardData: {
  [key in InventoryObjectType]: Pick<
    TrendCardProps,
    'title' | 'to' | 'color' | 'timeFilterInsensitive' | 'connectUrl'
  >;
} = {
  [InventoryObjectType.RiskyTicket]: {
    title: 'Risky feature',
    to: makeUrl(`/risks`, {
      fl: { RiskStatus: { values: ['Open'] }, RiskCategory: { values: ['Design'] } },
    }),
    color: BackdropColors.Glue,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/TicketingSystems',
  },
  [InventoryObjectType.Repository]: {
    title: 'Repositories',
    to: '/profiles/repositories',
    color: BackdropColors.Green,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/SourceCode',
  },
  [InventoryObjectType.User]: {
    title: 'Contributors',
    to: '/users/contributors',
    color: BackdropColors.Glue,
    timeFilterInsensitive: true,
    connectUrl: '/connectors/connect/SourceCode',
  },
  [InventoryObjectType.Api]: {
    title: 'APIs',
    to: makeUrl(`/explorer`, {
      q: {
        $type: 'explorer-schema',
        $version: 1,
        rowset: 'ApiElement',
        query: {
          type: 'relationship',
          relationshipQuery: null,
          relationshipName: 'codeReferences',
        },
        aggregation: {
          groupByFields: ['repository', 'apiFramework', 'httpMethod', 'httpRoute', 'insights'],
          aggregationColumns: [
            { $type: 'count', inputColumn: 'Description', columnName: '#ofDescription' },
          ],
        },
      },
    }),
    color: BackdropColors.Blue,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/SourceCode',
  },
  [InventoryObjectType.SensitiveData]: {
    title: humanize(InventoryObjectType.SensitiveData),
    to: makeUrl(`/explorer`, {
      q: {
        $type: 'explorer-schema',
        $version: 1,
        rowset: 'SensitiveData',
        query: {
          type: 'relationship',
          relationshipQuery: null,
          relationshipName: 'codeReferences',
        },
        aggregation: {
          groupByFields: [
            'Description',
            'name',
            'sensitiveDataSource',
            'sensitiveDataTypes',
            'repository',
          ],
          aggregationColumns: [
            { $type: 'count', inputColumn: 'Description', columnName: '#ofDescription' },
          ],
        },
      },
    }),
    color: BackdropColors.Red,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/SourceCode',
  },
  [InventoryObjectType.Package]: {
    title: 'OSS packages',
    to: makeUrl(`/explorer`, {
      q: {
        $type: 'explorer-schema',
        $version: 1,
        rowset: 'DependencyDeclaration',
        query: {
          type: 'relationship',
          relationshipQuery: null,
          relationshipName: 'codeReferences',
        },
        aggregation: {
          groupByFields: ['Description', 'name', 'repository'],
          aggregationColumns: [
            { $type: 'count', inputColumn: 'Description', columnName: '#ofDescription' },
          ],
        },
      },
    }),
    color: BackdropColors.Purple,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/SourceCode',
  },
  [InventoryObjectType.Technology]: {
    title: 'Technologies',
    to: makeUrl(`/explorer`, {
      q: {
        $type: 'explorer-schema',
        $version: 1,
        rowset: 'FrameworkUsage',
        query: {
          type: 'relationship',
          relationshipQuery: null,
          relationshipName: 'codeReferences',
        },
        aggregation: {
          groupByFields: ['Description', 'frameworkType', 'name', 'repository'],
          aggregationColumns: [
            { $type: 'count', inputColumn: 'Description', columnName: '#ofDescription' },
          ],
        },
      },
    }),
    color: BackdropColors.Green,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/SourceCode',
  },
  [InventoryObjectType.Infrastructure]: {
    title: 'Infrastructure',
    to: makeUrl(`/explorer`, {
      q: {
        $type: 'explorer-schema',
        $version: 1,
        rowset: 'TerraformResourceSummary',
        query: {
          type: 'typeSwitch',
          typeQueries: [{ typeSelector: 'TerraformStorageBucket', query: null }],
        },
        aggregation: {
          groupByFields: ['repository', 'name', 'Description', 'type'],
          aggregationColumns: [
            { $type: 'count', inputColumn: 'Description', columnName: '#ofDescription' },
          ],
        },
      },
    }),
    color: BackdropColors.Green,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/SourceCode',
  },
  [InventoryObjectType.Pipeline]: {
    title: 'Pipelines',
    to: '/inventory/pipelines/cicd-pipelines',
    color: BackdropColors.Yellow,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/CiCd/BuildScans',
  },
  [InventoryObjectType.ContainerImage]: {
    title: 'Container images',
    to: '/inventory/artifacts',
    color: BackdropColors.Orange,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/Registries',
  },
  [InventoryObjectType.Cluster]: {
    title: 'Clusters',
    to: '/inventory/clusters',
    color: BackdropColors.Orange,
    timeFilterInsensitive: false,
    connectUrl: '/connectors/connect/Runtime/KubernetesClusters',
  },
};
