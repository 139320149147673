import { observer } from 'mobx-react';
import { StickyHeader } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { SwArchitectureGraph } from '@src-v2/containers/sw-architecture-graph/sw-architecture-graph';

export default observer(() => {
  return (
    <Page title="Software architecture graph">
      <StickyHeader />

      <SwArchitectureGraph />
    </Page>
  );
});
