import { useCallback, useMemo } from 'react';
import { useQueryParams } from '@src-v2/hooks/use-navigation';

type NamespaceParamsType = { page?: string; pageSize?: string };

const NAMESPACE_PAGE_PARAMS_SUFFIX = 'np';

export const useNamespacePageParams = (
  namespace: string = 'fl'
): {
  pageParams: { page?: string; pageSize?: string };
  updateNamespacePageParams: (params: NamespaceParamsType) => void;
} => {
  const { queryParams, updateQueryParams } = useQueryParams();
  const currentParams = useMemo(
    () => queryParams?.[namespace.concat(NAMESPACE_PAGE_PARAMS_SUFFIX)] ?? {},
    [namespace, queryParams]
  );

  const updateNamespacePageParams = useCallback(
    (params: NamespaceParamsType) => {
      updateQueryParams({
        // @ts-expect-error
        [namespace.concat(NAMESPACE_PAGE_PARAMS_SUFFIX)]: { ...currentParams, ...params },
      });
    },
    [namespace, updateQueryParams]
  );

  return {
    pageParams: currentParams,
    updateNamespacePageParams,
  };
};
