import styled from 'styled-components';
import { OverviewTile, OverviewTilesGrid } from '@src-v2/components/overview/overview-tiles';
import { OverviewTilesOrderProvider } from '@src-v2/components/overview/overview-tiles-order-context';
import { ApisDetailsTrendTile } from '@src-v2/containers/inventory-overview/tiles/apis-details-trend-tile';
import { ContributorsDetailsTrendTile } from '@src-v2/containers/inventory-overview/tiles/contributors-details-trend-tile';
import { RepositoriesDetailsTrendTile } from '@src-v2/containers/inventory-overview/tiles/repositories-details-trend-tile';
import { SensitiveDataDetailsTrendTile } from '@src-v2/containers/inventory-overview/tiles/sensitive-data-details-trend-tile';
import { PullRequestsVelocityTile } from '@src-v2/containers/overview/tiles/pull-requests-velocity-tile';
import { RiskyCommitsLineTile } from '@src-v2/containers/overview/tiles/risky-commits-line-tile';

export const InventoryOverviewTiles = () => (
  <OverviewTilesOrderProvider storagePrefix="inventory_overview">
    <OverviewTrendTilesGrid>
      <RepositoriesDetailsTrendTile />
      <ApisDetailsTrendTile />
      <SensitiveDataDetailsTrendTile />
      <ContributorsDetailsTrendTile />
      <PullRequestsVelocityTile />
      <RiskyCommitsLineTile />
      <OverviewTile key="matChangeTime" size={2} title="Material changes overtime" />
      <OverviewTile key="matChangeTime" size="max" title="Technologies" />
    </OverviewTrendTilesGrid>
  </OverviewTilesOrderProvider>
);

const OverviewTrendTilesGrid = styled(OverviewTilesGrid)`
  --card-padding: 4rem;
`;
